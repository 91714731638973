import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Footer, FooterGrid, PoliticaPrivacidade, LogoDiv, DivPoliticaPrivacidade,
} from '../PaginasEstilo'
import Ajuda, { RedesSociais, Funcionamento } from '../../contatos'
import { LINK_PORTAL_POLITICA_PRIVACIDADE } from '../../utils/Configuracoes'

const PaginasFooter = ({ landingPage }) => {
  const { t } = useTranslation()

  return (
    <Footer landingPage={landingPage} cor={landingPage ? null : '#005CAF'}>
      <FooterGrid>
        <Ajuda footer />
        <RedesSociais />
        <Funcionamento />
      </FooterGrid>
      <DivPoliticaPrivacidade>
        <PoliticaPrivacidade href={LINK_PORTAL_POLITICA_PRIVACIDADE} target="_blank" rel="noopener noreferrer">
          {t('paginas.POLITICA')}
        </PoliticaPrivacidade>
      </DivPoliticaPrivacidade>
      <LogoDiv>
        <img src="/images/logo_sesi_senai_iel_ciemg_fiemg.png" alt={t('paginas.ALT_IMAGEM_FIEMG')} height="21px" />
      </LogoDiv>
    </Footer>
  )
}

PaginasFooter.propTypes = {
  landingPage: PropTypes.bool,
}

PaginasFooter.defaultProps = {
  landingPage: false,
}

export default memo(PaginasFooter)

const Alerta = (snack, mensagem, tipo = 'error', duracao = 3000) => {
  snack(mensagem, {
    variant: tipo,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    preventDuplicate: true,
    autoHideDuration: duracao,
  })
}

export const AlertaEsquerda = (snack, mensagem, tipo = 'error', duracao = 3000) => {
  snack(mensagem, {
    variant: tipo,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    preventDuplicate: true,
    autoHideDuration: duracao,
  })
}

export const AlertaBottom = (snack, mensagem, tipo = 'error', duracao = 3000) => {
  snack(mensagem, {
    variant: tipo,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    preventDuplicate: true,
    autoHideDuration: duracao,
  })
}

export default Alerta

const PtBr = {
  COTACAO: 'Fazer Cotação',
  COTACAO_UNIDADE: 'Fazer Cotação nessa Unidade',
  COTACAO_AQUI: 'Fazer Cotação aqui',
  UNIDADES: ' unidades',
  SOLUCAO: 'Solução disponível ',
  EM: 'em ',
  INTERESSOU: 'Se interessou?',
  BENEFICIOS_SUA_EMP: 'Benefícios para sua ',
  EMPRESA: 'empresa',
  BENEFICIOS_COLAB: 'Benefícios para o ',
  COLABORADOR: 'colaborador',
  EMAIL: 'email',
  ENDERECO: 'endereco',
  UNIDADES_OFERTANTES: 'Unidades Ofertantes',
  SUCESSO: 'sucesso',
  SOLICITACAO_SUCESSO: 'Solicitação feita com sucesso!',
  CONTATO_FIEMG: 'Você receberá um contato da FIEMG com os próximos passos',
  CONCLUIDO: 'concluido',
  SELECIONE: 'Selecione Unidade de Ensino',
  PREENCHA: 'Preencha seus Dados',
  RESUMO: 'Resumo',
  SELECIONE_CIDADE: 'Selecione Cidade',
  SOLUTION: 'SOLUÇÃO',
  VALORES_PAGAMENTOS: 'VALORES E PAGAMENTOS',
  CONTATO: 'Nossa equipe entrará em contato para levantar detalhes da demanda e propor um valor personalizado para você.',
  DADOS_RESPONSAVEL: 'DADOS DO RESPONSÁVEL',
  DADOS_EMPRESA: 'DADOS DA EMPRESA',
  LOGRADOURO: 'Logradouro',
  INSIRA_LOGRADOURO: 'Insira logradouro',
  UF: 'UF',
  INSIRA_ESTADO: 'Insira Estado',
  ANTERIOR: 'Anterior',
  FINALIZAR: 'Finalizar',
  PROXIMO: 'Próximo',
  CEP_INVALIDO: 'CEP inválido',
  CEP: 'CEP da Empresa',
  NUMERO: 'Número',
  INSIRA_NUMERO: 'Insira número',
  COMPLEMENTO: 'Complemento',
  INSIRA_COMPLEMENTO: 'Insira complemento',
  BAIRRO: 'Bairro',
  INSIRA_BAIRRO: 'Insira bairro',
  CIDADE: 'Cidade',
  INSIRA_CIDADE: 'Insira cidade',
  VEJA_TAMBEM: 'veja também...',
  OUTRAS_SOLUCOES: 'Outras soluções que separamos para você',
  CARGA_HORARIA: 'Carga horária ',
  ERRO_CAMPOS_NAO_PREENCHIDOS: 'Existem campos não preenchidos!',
  ERRO_RECAPTCHA_NAO_CHECADO: 'ReCaptcha não foi checado!',
  ERRO_TERMOS_NAO_CHECADO: 'Termos de Aceite e Política de Privacidade não foram aceitos!',
  ERRO_LOGRADOURO_OBRIGATORIO: 'Logradouro é obrigatório!',
  ERRO_NUMERO_OBRIGATORIO: 'Número é obrigatório!',
  ERRO_NUMERO_INVALIDO: 'Número inválido!',
  ERRO_BAIRRO_OBRIGATORIO: 'Bairro é obrigatório!',
  ERRO_CIDADE_OBRIGATORIO: 'Cidade é obrigatório!',
  ERRO_UF_OBRIGATORIO: 'Uf é obrigatório!',
  ERRO_UF_INVALIDO: 'Uf inválida!',
  TITLE_PAGINA: 'FIEMG - Faça sua cotação',
  TITLE_PAGINA_SUCESSO: 'FIEMG - Solicitação feita com sucesso!',
  DESCRICAO_PAGINA: 'Faça sua cotação para uma solução, consultoria, treinamento e serviços técnicos no SENAI, SESI, IEL, CIEMG.',
  ALT_IMG_CATEGORIA: 'Imagem representativa da FIEMG para a categoria ',
  ALT_IMG__BENEFICIO_CATEGORIA: 'Imagem representativa da FIEMG para os beneficios da categoria ',
  OFERECIDO_POR: 'Oferecido por: ',
  ATENDEMOS_TODO_ESTADO: 'Atendemos todo o estado de ',
  MINAS_GERAIS: 'Minas Gerais! ',
  A_FIEMG_APOIA_SUA_INDUSTRIA: 'A FIEMG tem soluções para ajudar a sua empresa a ser mais competitiva, inovadora e sustentável. Nossos serviços estão disponíveis em todo o estado de Minas Gerais. ',
  ALT_MINAS: 'Imagem representativa da FIEMG para Minas Gerais',
  DUVIDAS: 'Dúvidas? ',
  ENTRE_EM_CONTATO: 'Entre em contato conosco! ',
  PALAVRAS_CHAVE: 'Palavras-Chave',
  CURSOS_INTERESSE: 'Cursos que também podem te interessar:',
  FRASE_CTA: 'Conte com a FIEMG para impulsionar o seu negócio!',
}

export default PtBr

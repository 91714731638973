import React from 'react'
import propTypes from 'prop-types'
import { BalaoEstilizado } from '../UtilsEstilo'

const Balao = ({
  children, aberto, altura, largura, posicaoSeta, setaFundo, top, right,
}) => (
  <BalaoEstilizado
    onClick={(evento) => evento.stopPropagation()}
    onKeyPress={(evento) => evento.stopPropagation()}
    aberto={aberto}
    altura={altura}
    largura={largura}
    posicaoSeta={posicaoSeta}
    setaFundo={setaFundo}
    top={top}
    right={right}
  >
    {children}
  </BalaoEstilizado>
)

Balao.propTypes = {
  children: propTypes.element,
  aberto: propTypes.bool,
  altura: propTypes.string,
  largura: propTypes.string,
  posicaoSeta: propTypes.string,
  setaFundo: propTypes.bool,
  top: propTypes.string,
  right: propTypes.string,
}
Balao.defaultProps = {
  children: undefined,
  aberto: false,
  altura: '190px',
  largura: '285px',
  posicaoSeta: '94%',
  setaFundo: false,
  top: '55px',
  right: '48px',
}

export default Balao

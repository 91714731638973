import React from 'react'
import propTypes from 'prop-types'

const AwardSVG = ({ cor }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 12.4999C13.2217 12.4999 15.8334 9.88825 15.8334 6.66659C15.8334 3.44492 13.2217 0.833252 10.0001 0.833252C6.77842 0.833252 4.16675 3.44492 4.16675 6.66659C4.16675 9.88825 6.77842 12.4999 10.0001 12.4999Z" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.84159 11.575L5.83325 19.1666L9.99992 16.6666L14.1666 19.1666L13.1583 11.5667" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

AwardSVG.propTypes = {
  cor: propTypes.string,
}

AwardSVG.defaultProps = {
  cor: undefined,
}

export default AwardSVG

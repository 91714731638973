import PropTypes from 'prop-types'
import React from 'react'
import { BotaoSairCorte } from '../../features/utils/UtilsEstilo'

const SairCrop = ({
  setArquivo, setExibirTrocarImagem, setEnderecoUrl, setExibirPopup, exibirPopup,
}) => {
  const cancelar = () => {
    setArquivo(null)
    setExibirTrocarImagem(false)
    setEnderecoUrl(null)
    setExibirPopup(!exibirPopup)
  }
  return (
    <BotaoSairCorte onClick={cancelar}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
          <path d="M18.7 17.3C19.1 17.7 19.1 18.3 18.7 18.7C18.5 18.9 18.3 19 18 19C17.7 19 17.5 18.9 17.3 18.7L12 13.4L6.7 18.7C6.5 18.9 6.3 19 6 19C5.7 19 5.5 18.9 5.3 18.7C4.9 18.3 4.9 17.7 5.3 17.3L10.6 12L5.3 6.7C4.9 6.3 4.9 5.7 5.3 5.3C5.7 4.9 6.3 4.9 6.7 5.3L12 10.6L17.3 5.3C17.7 4.9 18.3 4.9 18.7 5.3C19.1 5.7 19.1 6.3 18.7 6.7L13.4 12L18.7 17.3Z" fill="white" />
        </mask>
        <g mask="url(#mask0)">
          <rect width="24" height="24" fill="#2D2F32" />
        </g>
      </svg>
    </BotaoSairCorte>
  )
}

SairCrop.propTypes = {
  exibirPopup: PropTypes.bool,
  setArquivo: PropTypes.func,
  setEnderecoUrl: PropTypes.func,
  setExibirPopup: PropTypes.func,
  setExibirTrocarImagem: PropTypes.func,
}

SairCrop.defaultProps = {
  exibirPopup: false,
  setArquivo: undefined,
  setEnderecoUrl: undefined,
  setExibirPopup: undefined,
  setExibirTrocarImagem: undefined,
}

export default SairCrop

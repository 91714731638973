import React from 'react'

const YoutubeSVG = () => (
  <svg
    width="32px"
    height="24px"
    viewBox="0 0 32 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>AC36EB5B-4ABA-4365-BF7C-D384D050CF84</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.996078 0 0 0 0 0.996078 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g id="Precisa-de-Ajuda?-+-Footer" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Ajuda-1-(1)" transform="translate(-667.000000, -907.000000)">
        <g id="Navigation/-Footer-WEB" transform="translate(176.000000, 784.000000)">
          <g id="youtube-logo" transform="translate(487.000000, 115.000000)" filter="url(#filter-1)">
            <g transform="translate(4.000000, 8.000000)">
              <path d="M26.3733333,0.0320526316 L5.57699048,0.0320526316 C2.50179048,0.0320526316 0,2.62515789 0,5.81242105 L0,18.1361053 C0,21.3233684 2.50179048,23.9163158 5.57699048,23.9163158 L26.3734857,23.9163158 C29.4485333,23.9163158 31.9503238,21.3233684 31.9503238,18.1361053 L31.9503238,5.81242105 C31.9503238,2.62515789 29.448381,0.0320526316 26.3733333,0.0320526316 Z M29.6646095,18.1359474 C29.6646095,20.0172632 28.1880381,21.5477368 26.3733333,21.5477368 L5.57699048,21.5477368 C3.76213333,21.5477368 2.28571429,20.0172632 2.28571429,18.1359474 L2.28571429,5.81226316 C2.28571429,3.93094737 3.76213333,2.40047368 5.57699048,2.40047368 L26.3734857,2.40047368 C28.1881905,2.40047368 29.6646095,3.93094737 29.6646095,5.81226316 L29.6646095,18.1359474 L29.6646095,18.1359474 Z" id="Shape" fill="#000000" fillRule="nonzero" />
              <path d="M22.1097143,10.9847368 L13.6379429,5.19789474 C13.2870095,4.95805263 12.8373333,4.93752632 12.4670476,5.14389474 C12.0969143,5.35042105 11.8660571,5.751 11.8660571,6.18663158 L11.8660571,17.7617368 C11.8660571,18.1973684 12.0969143,18.5977895 12.4670476,18.8044737 C12.6366476,18.8992105 12.8230095,18.9459474 13.0087619,18.9459474 C13.2284952,18.9459474 13.4477714,18.8804211 13.6379429,18.7503158 L22.1097143,12.9620526 C22.4304762,12.7428947 22.6235429,12.3715263 22.6235429,11.9733158 C22.6235429,11.5752632 22.4304762,11.2038947 22.1097143,10.9847368 Z M14.1517714,15.5624211 L14.1517714,8.38563158 L19.4044952,11.9734737 L14.1517714,15.5624211 Z" id="Shape" fill="#000000" fillRule="nonzero" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default YoutubeSVG

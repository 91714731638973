import PropTypes from 'prop-types'
import React from 'react'

const SairSVG = ({ inverso }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 9.75V14.25C13.5 15.0784 12.8284 15.75 12 15.75H3.75C2.92157 15.75 2.25 15.0784 2.25 14.25V6C2.25 5.17157 2.92157 4.5 3.75 4.5H8.25"
      stroke={inverso ? '#FEFEFE' : '#005CAF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.25 2.25H15.75V6.75" stroke={inverso ? '#FEFEFE' : '#005CAF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 10.5L15.75 2.25" stroke={inverso ? '#FEFEFE' : '#005CAF'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

SairSVG.propTypes = {
  inverso: PropTypes.bool,
}

SairSVG.defaultProps = {
  inverso: false,
}

export default SairSVG

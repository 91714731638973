const PtBr = {
  PAINEL_ADMINISTRATIVO: 'Painel Administrativo',
  TERMOS_PESQUISADOS: 'Termos Pesquisados',
  FORMULARIO_DEMANDAS: 'Formulário de Demandas',
  TERMOS: 'Termos',
  NUM_OCORRENCIAS: 'Nº de Ocorrências',
  CRITERIOS_DE_BUSCA: 'Critérios de Busca',
  LIMPAR: 'Limpar',
  CATEGORIA: 'Categoria',
  PARA_VOCE: 'Para você',
  PARA_SUA_EMPRESA: 'Para sua empresa',
  DATA: 'Data',
  ULTIMO_DIA: 'Último dia',
  ULTIMA_SEMANA: 'Última semana',
  ULTIMO_MES: 'Último mês',
  DATA_INICIAL: 'Data inicial',
  DATA_FINAL: 'Data final',
  EXPORTAR_EXCEL: 'Exportar para Excel',
  PESQUISAR: 'Pesquisar',
  DATA_INVALIDA: 'Datas inválidas.',
  DATA_INICIAL_SUPERIOR: 'Data inicial maior que a data final.',
  EXIBICAO: 'Exibição',
  MOSTRAR_TODOS: 'Mostrar todos',
  NAO_RESPONDIDOS: 'Não respondidos',
  DEMANDA: 'Demanda',
  SUGESTAO: 'Sugestão',
  EMAIL: 'Email',
  RESPONDIDO: 'Respondido?',
  FRASE_DEMANDA: 'A FIEMG oferece serviços customizados. ',
  FRASE_DEMANDA_NEGRITO: 'Que tipo de solução você precisa?',
  FRASE_SUGESTAO: 'Estamos abertos a sugestões para novos cursos! ',
  FRASE_SUGESTAO_NEGRITO: 'O que você gostaria de aprender?',
  RESPOSTA: 'Resposta',
  INSIRA_SUA_RESPOSTA: 'Insira sua resposta...',
  RESPONDER_DEMANDA: 'Responder demanda',
  RESPONDER_SUGESTAO: 'Responder sugestão',
  RESPOSTA_ENVIADA: 'Resposta enviada com sucesso.',
  COTACOES_PJ: 'Cotações PJ',
  EMPRESA: 'Empresa',
  RESPONSAVEL: 'Responsável',
  COTACOES: 'Cotações: ',
  SOLUCAO: 'Solução',
  PESQUISAR_EMPRESA: 'Pesquisar por empresa (opcional)',
  PESQUISAR_ALUNO: 'Pesquisar por aluno (opcional)',
  PESQUISE_UMA_SOLUCAO: 'Pesquise uma solução...',
  PROCURE_UMA_EMPRESA: 'Procure por uma empresa...',
  PESQUISE_UM_CURSO: 'Pesquise um curso...',
  INSIRA_SOLUÇÃO: 'Selecione uma solução para continuar.',
  INSIRA_CURSO: 'Selecione um curso para continuar.',
  DADOS_RESPONSAVEL: 'Dados do responsável',
  DADOS_EMPRESA: 'Dados da empresa',
  NOME_RESPONSAVEL: 'Nome do responsável',
  EMAIL_RESPONSAVEL: 'Email do responsável',
  TELEFONE: 'Telefone',
  NOME_EMPRESA: 'Nome da empresa',
  CNPJ: 'CNPJ',
  CEP_EMPRESA: 'CEP da empresa',
  LOGRADOURO: 'Logradouro',
  COMPLEMENTO: 'Complemento',
  BAIRRO: 'Bairro',
  NUMERO: 'Número',
  CIDADE: 'Cidade',
  ESTADO: 'Estado',
  PAINEL_RELATORIOS: 'Painel de Relatórios',
  MATRICULAS_PF: 'Matrículas PF',
  ALUNO: 'Aluno',
  TURMA: 'Turma',
  FINALIZADA: 'Finalizada?',
  NAO_FINALIZADOS: 'Não finalizados',
  INSCRICOES_PORTAL: 'Inscrições no portal: ',
  INSCRICOES_FINALIZADAS: 'Inscrições finalizadas: ',
}

export default PtBr

import React, { useState } from 'react'
import PaginasContext from '../PaginasContext'
import propsProvider, { valueProvider } from '../PaginasPropTypes'

const PaginasProvider = (props) => {
  const [solucao, setSolucao] = useState({})
  const [solucaoCotada, setSolucaoCotada] = useState(null)
  const [cidadeSolucaoCotada, setCidadeSolucaoCotada] = useState(null)
  const [passoAtivo, setPassoAtivo] = useState(1)
  const [atualizarFiltro, setAtualizarFiltro] = useState(false)
  const [modalAberto, setModalAberto] = useState(false)
  const [dadosCotacaoEmpresa, setDadosCotacaoEmpresa] = useState({})
  const [novoUsuarioLogado, setNovoUsuarioLogado] = useState(false)
  const [areas, setAreas] = useState([])

  const { children } = props
  return (
    <PaginasContext.Provider value={{
      solucao,
      setSolucao,
      solucaoCotada,
      setSolucaoCotada,
      cidadeSolucaoCotada,
      setCidadeSolucaoCotada,
      passoAtivo,
      setPassoAtivo,
      atualizarFiltro,
      setAtualizarFiltro,
      modalAberto,
      setModalAberto,
      dadosCotacaoEmpresa,
      setDadosCotacaoEmpresa,
      novoUsuarioLogado,
      setNovoUsuarioLogado,
      areas,
      setAreas,
    }}
    >
      {children}
    </PaginasContext.Provider>
  )
}

PaginasProvider.propTypes = propsProvider
PaginasProvider.defaultProps = valueProvider

export default PaginasProvider

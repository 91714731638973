import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { RESOLUCAO_MAXIMA_MOBILE } from '../utils/Configuracoes'

const Pagina = styled.div`
  width: 100vw;
  height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: ${(props) => (props.alturaHeader ? `${props.alturaHeader} 1px 1fr` : '120px 1fr')};
  grid-template-rows: ${(props) => (props.alturaHeader ? `${props.alturaHeader} 1fr` : '120px 1fr')};
  gap: 1px 1px;
  grid-template-areas: "header" "body";
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    -ms-grid-rows: ${(props) => (props.alturaHeader ? `${props.alturaHeader} 1px 1fr` : '104px 1px 1fr')};
    grid-template-rows: ${(props) => (props.alturaHeader ? ` ${props.alturaHeader} 1fr` : '104px 1fr')};
    grid-template-rows: ${(props) => (props.alturaHeader ? ` ${props.alturaHeader} 1fr` : '104px 1fr')};
  }
`

export const Header = styled.header`
  min-height: ${(props) => (props.altura ? props.altura : '120px')};
  background-color: ${(props) => (props.headerBranco ? '#fff' : 'transparent')};
  position: relative;
  z-index: 6;
  display: flex;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    min-height: ${(props) => (props.altura ? props.altura : '104px')};
  }
`

export const Body = styled.main`
  -ms-grid-row: 3; 
  -ms-grid-column: 1; 
  grid-row: 3; 
  grid-column: 1; 
  grid-area: body;
      
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    overflow: ${(props) => (props.travarScroll ? 'hidden' : 'unset')};
  }
`

export const DivMenu = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: ${(props) => (props.$headerAlternativo ? '100%' : 'calc(100% - 16px)')};
  margin-left: 48px;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-top: ${(props) => (props.$headerAlternativo ? '5px' : '10px')};
    margin-left: 16px;
  }
`

export const MenuContainer = styled.div`
  display: none;
  position: absolute;
  background: #FEFEFE;
  border: 1px solid #EAEEF4;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 248px;
  padding: 8px;
  padding-right: 0;
  box-shadow: 0 0 11px 0 #cfd3d8;
  transform: ${(props) => (props.$submenu ? 'translate(calc(100% - 10px), calc(50% - 23px))' : '')};
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;

  h2, a {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => (props.cor)};
    margin: 4px 24px 4px 8px;
    text-decoration: none;
    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      font-size: 16px;
    }
  }

  svg {
    margin-left: auto;
    margin-right: 8px;
  }

  :hover, :focus-within {
    @media (min-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      >h2, >a {
        opacity: 0.6;
      }
    }
    >div {
      display: block;
    }
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-left: 16px;
    margin-bottom: 16px;
    >h2 {
      max-width: 68%;
    }
  }
`

export const BotaoMenu = styled.div`
  margin-right: 48px;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: ${(props) => (props.$headerAlternativo ? '16px' : 0)};
  height: 100%;

  >a, >span {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    color: ${(props) => (props.$headerAlternativo ? '#FFFFFF' : '#005CAF')};
  }

  :hover, :focus-within {
    >a, >span {
      opacity: 0.6;
    }
    >div {
      display: block;
    }
  }
`

export const Imagem = styled.div`
  -ms-grid-column-align: center;
  justify-self: center;
  padding-top: ${(props) => (props.logoGrande ? '10px' : '16px')};
  text-align: center;
  width: 100%;
  @media (max-width: 350px) {
   width: 65vw;
   text-align: end;
  }
`

export const Titulo = styled.div`
  -ms-grid-column-align: center;
  justify-self: center;
  font-size: 32px;
  color: #348DCF;
  font-weight: 600;
  height: 65%;
  padding-top: 32px;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 24px;
    padding-top: 24px;
  }
`

export const Hamburguer = styled.button`
  border: none;
  padding: 0px;
  width: 32px;
  height: 32px;
  background-color: transparent;

  &:hover{
    cursor: pointer;
    svg rect {
      fill: #EAEEF4;
    }
  }
`

export const MenuLateral = styled.aside`
  height: 100vh;
  width: ${(props) => (props.aberto ? '264px' : '0px')};
  position: fixed; 
  z-index: 3; 
  top: 0;
  left: 0;
  border-radius: 0 8px 8px 0;
  background-color: #FEFEFE;
  box-shadow: 0 0 11px 0 #CFD3D8;
  overflow-x: hidden; 
  padding-top: 32px; 
  transition: 0.3s; 
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
`

export const ConteudoMenuLateral = styled.div`
  width: 264px;
  opacity: ${(props) => (props.aberto ? 1 : 0)};
  transition: 1s;
`

export const BotaoVoltar = styled.button`
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: #FEFEFE;
  border: none;
  &:hover{
    cursor: pointer;
  }
`

export const LinkEstilizado = styled(Link)`
  height: 24px;
  width: 200px;
  color: #2D2F32;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-left: ${(props) => (props.$submenu ? '18px' : '16px')};
  text-align: left;
  padding-top: 8px;
  margin-bottom: ${(props) => (props.$submenu ? '32px' : '16px')};

  img {
    margin-right: 8px;
  }
`

export const BotaoEstilizado = styled.button`
  padding: 0;
  height: 24px;
  width: calc(100% - 16px);
  color: #2D2F32;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent;
  margin-left: 16px;
  margin-top: 24px;

  svg {
    margin-right: 8px;
  }
`

export const CabecalhoMenu = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "logo voltar";
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;   
  padding-bottom: 16px;
`

export const ImagemMenu = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 1; 
  grid-row: 1; 
  grid-column: 1; 
  grid-area: logo; 
  padding-left: 8px;
`

export const VoltarMenu = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 2; 
  grid-row: 1; 
  grid-column: 2; 
  grid-area: voltar; 
  justify-self: end;
  padding-right: 18px;
`

export const ImagemHeader = styled.img`
  pointer-events: none;
`

export const App = styled.div`
  background-color:  ${(props) => props.cor};
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 100vh;
  color: #636669;
  overflow-x: hidden;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    background-repeat: no-repeat;
    background-position: top 32px right 8px;
    overflow-x: unset;
  }
`

export const Ajuda = styled.div`
  z-index: 2;
  margin-top: 16px;
  margin-left: 24px;
  background: transparent;
  >div {
    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      top: 39px;
      right: 0;
    }
    &:hover {
      cursor: default;
    }
  }
  
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-left: 12px;
    margin-top: 0;
  }
`

export const AjudaMenu = styled.div`
  display: flex;
  position: absolute;
  bottom: 40px;
  margin-bottom: 10px;
  border-top: 2px solid #EAEEF4;
  width: 100%;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-bottom: 60px;
  }
`

export const Footer = styled.footer`
  width: ${(props) => (props.landingPage ? '1024px' : '100vw')};
  background-color: ${(props) => props.cor}; 
  padding-top: 48px;
  padding-bottom: 64px;  

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-bottom: 132px;
    width: 100vw;
  }
`

export const FooterGrid = styled.div`
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  flex-wrap: nowrap;
  
  div {
    flex: 1 1 0;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    flex-wrap: wrap;
  }
`

export const PoliticaPrivacidade = styled.a`
  opacity: 0.5;
  color: #FEFEFE;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-decoration:none;
  
  &:hover{
    cursor: pointer;
  }
`

export const LogoDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 8px;
  @media (max-width: 350px) {
    gap: 5px
  }
`

export const DivAutenticacao = styled.div`
  position: absolute;
  right: 48px;
  display: flex;
  margin-top: 5px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    top: 16px;
    right: 16px;
    margin-top: 0;
  }
`
export const DivSeparador = styled.div`
  display: flex;
  align-items: initial;
  padding-top: 0px;
`

export const SeparadorHeader = styled.span`
  border-bottom: 1px solid #EAEEF4;
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding-top: 4px;
`

export const DivPoliticaPrivacidade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`

export const BotaoVoltarMenu = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #2D2F32;
  margin-bottom: 32px;
  margin-left: 16px;

  span {
    margin-left: 10px;
    text-transform: capitalize;
  }
`

export const TituloCategoria = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 16px;
  
  h2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => (props.cor)};
  }

  img {
    margin-right: 8px;
  }
`

export const BotaoAdministrador = styled.div`
  background: ${(props) => (props.ativo ? '#5CB3E8' : 'transparent')};
  border: none;
  cursor: pointer;
  margin-top: 16px;
  margin-left: 24px;
  z-index: 2;
  user-select: none;
  text-align: left;
  width: 32px;
  height: 32px;
  border-radius: 4px;

  >img {
    position: relative;
  }

  >div {
    &:hover {
      cursor: default;
    }
  }

  @media (max-width: RESOLUCAO_MAXIMA_MOBILE) {
    margin-top: 0px;
  }
`
export const TextoPrincipalHeaderLandingPage = styled.span`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 68px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  white-space: pre;
`
export const TextoComplementarHeaderLandingPage = styled.span`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 68px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
`
export const DivFiemgHeaderLandingPage = styled.div`
  position: absolute;
  margin: 16px 0 0 30px; 
`
export const DivSeparadorHeaderLandingPage = styled.div`
  position: absolute;
  left: 0;
  top: 70px;
  background-color: #EAEEF4;
  opacity: 20%;
  width: 100%;
  height: 1px;
`
export const DivHeaderMobilelandingPage = styled.div`
  margin-bottom:16px;
`

export default Pagina

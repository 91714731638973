const PtBr = {
  EDITAR_INFORMACOES: 'Editar Informações',
  VOLTAR: 'Voltar',
  FOTOS: 'Fotos da unidade',
  DESCRICAO_FOTOS: 'As fotos estão na ordem como vão aparecer no carroussel de imagens.',
  TEXTO_SOBRE: 'Texto sobre a unidade',
  DESCRICAO_TEXTO_SOBRE: 'Informações sobre a unidade e detalhes da infraestrutura.',
  TEXTO_CAPA: 'FOTO DE CAPA',
  SALVAR_ALTERACOES: 'Salvar Alterações',
  INSIRA_TEXTO_AQUI: 'Insira texto aqui',
  EDITAR_FOTO: 'Editar Foto',
  SALVAR: 'Salvar',
  CANCELAR: 'Cancelar',
  DESCRICAO_ATUALIZADA: 'Descrição atualizada',
  FOTO_ATUALIZADA: 'Foto atualizada',
  PNG_JPEG: 'PNG ou JPEG',
  LIMITE_5MB: '(Limite de 5mb por imagem)',
}

export default PtBr

import PropTypes from 'prop-types'
import React from 'react'

const TextoSVG = ({ cor }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1667 15H2.5" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.5 11.6666H2.5" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1667 8.33337H2.5" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.5 5H2.5" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

TextoSVG.propTypes = {
  cor: PropTypes.string,
}

TextoSVG.defaultProps = {
  cor: undefined,
}

export default TextoSVG

const PtBr = {
  CADASTRO_SOLUCOES_PJ: 'Cadastro de Soluções - PJ',
  PESQUISAR: 'Pesquisar',
  ADICIONAR_SOLUCAO: 'Adicionar nova Solução',
  PESQUISE_SOLUCAO: 'Pesquise uma solução',
  VISUALIZAR_EDITAR: 'Visualizar/Editar',
  FORMULARIO_CADASTRO: 'Formulário de Cadastro de Solução PJ',
  TEXTO_ADICIONAR: 'Ao inserir uma nova solução no Portal, recomendamos a leitura do material auxiliar para a criação de textos. Para acessar o guia basta clicar ',
  AQUI: 'AQUI.',
  NOME: 'Nome da solução',
  NOME_FANTASIA: 'Nome Fantasia da solução',
  TEXTO: 'Texto descritivo do produto',
  BENEFICIOS_EMPRESA: 'Benefícios para a Empresa',
  BENEFICIOS_COLABORADOR: 'Benefícios para o Colaborador',
  CTA: 'CTA',
  PALAVRAS_CHAVE: 'Palavras-chave',
  CARACTERISTICAS: 'Característica do produto',
  MODALIDADE: 'Modalidade',
  AREA: 'Área',
  CATEGORIA: 'Categoria',
  ENTIDADE: 'Entidade',
  CARGA_HORARIA: 'Carga Horária',
  ADD_SOLUCAO: 'Adicionar Solução',
  PRE_VISUALIZAR: 'Pré-visualizar Solução',
  EXIBIR_PORTAL: 'Exibir no Portal',
  EXCLUIR_SOLUCAO: 'Excluir Solução',
  PLACEHOLDER_SOLUCAO: 'Digite o nome da solucão... (max ',
  CARACTERES: ' caracteres)',
  PLACEHOLDER_DESCRICAO: 'Digite o descritivo do produto... (min ',
  PLACEHOLDER_BENEFICIOS_EMPRESAS: 'Digite os benefícios para a empresa... (min ',
  PLACEHOLDER_BENEFICIOS_COLABORADOR: 'Digite os benefícios para o colaborador... (min ',
  PLACEHOLDER_PALAVRAS_CHAVE: 'Digite a palavra-chave e aperte enter...',
  PLACEHOLDER_CTA: 'Digite uma frase curta e convidativa... (max ',
  SELECIONE: 'Selecione',
  OBRIGATORIO_MENOS: 'Campo obrigatório. Devem ser informados no máximo ',
  OBRIGATORIO_MAIS: 'Campo obrigatório. Devem ser informados ao menos ',
  PALAVRAS: ' palavras chaves',
  APENAS_EXIBIDOS: 'Apenas cursos exibidos',
  SALVAR_ALTERACAO: 'Salvar Alterações',
  CAMPOS_OBRIGATORIOS: 'Necessário preencher todos os campos obrigatórios',
  ATENCAO: 'Atenção!',
  CONFIRMAR_EXCLUSAO: 'Para confirmar a exclusão da solução digite seu usuário e senha.',
  DELETAR: 'Deletar Solução',
  SALVO_COM_SUCESSO: 'Alteração salva com sucesso!',
  VOLTAR: 'Voltar',
  CHARS: ' caracteres',
  OBRIGATORIO: 'Obrigatório.',
  EXCLUSAO_COM_SUCESSO: 'Solução excluida com sucesso!',
  COD_CRM: 'Código CRM',
  SAIR_DO_FORMULARIO_CABECALHO_MODAL: 'Sair do Formulário de Cadastro',
  SAIR_DO_FORMULARIO_CONTEUDO_MODAL: 'Ao sair do formulário de cadastro você perderá todo o trabalho salvo. Tem certeza que deseja sair?',
  SAIR_DO_FORMULARIO_BOTAO_PERMANECER: 'Permanecer',
  SAIR_DO_FORMULARIO_BOTAO_SAIR: 'Sair',
  ALT_BOTAO_PERMANECER: 'Icone de uma seta indicando para permanecer na tela',
  ALT_BOTAO_SAIR: 'Icone de alerta indicando para sair da tela',
  CARACTERES_RESTANTES: ' caracteres restantes',
  TOOLTIP_NOME: 'O nome da solução deve ser atraente e explicativo para o cliente. Evite nomes ambíguos ou pouco descritivos.',
  TOOLTIP_NOME_FANTASIA: 'O nome fantasia da solução deve ser atraente e explicativo para o cliente. Evite nomes ambíguos ou pouco descritivos.',
  TOOLTIP_DESCRICAO: 'Deve ser um texto detalhado e esclarecedor sobre a proposta da solução. Use uma linguagem de fácil compreensão.',
  TOOLTIP_BENEFICIOS_EMPRESA: 'São as vantagens competitivas que a empresa vai adquirir ao comprar esta solução. Evite usar frases genéricas e procure convencer o cliente de que ele precisa desse produto.',
  TOOLTIP_BENEFICIOS_COLABORADOR: 'Descrição das habilidades que o colaborador vai adquirir a partir desta solução. Evite usar frases genéricas e procure convencer o cliente de que ele precisa desse produto.',
  TOOLTIP_CTA: 'Call To Action - Termos de marketing ou palavras utilizadas no imperativo que orienta e leva o usuário a realizar ações. Ex. de frase padrão: "Conte com a FIEMG para impulsionar o seu negócio!”.',
  TOOLTIP_PALAVRAS_CHAVE: 'A Plataforma de Negócios utiliza as palavras chave para recomendar soluções semelhantes ao usuário. Escreva pelo menos três para prosseguir.',
  TOOLTIP_CARACTERISTICAS: 'Tipo de serviço oferecido pela FIEMG em relação à esta solução.',
  TOOLTIP_MODALIDADE: 'Formato em que o conteúdo da solução será disponibilizado ao cliente.',
  TOOLTIP_AREA: 'São os grandes grupos na qual organizamos as soluções na plataforma de negócios. Exemplos: “Saúde e Qualidade de Vida”, ”Gestão de Negócios” e etc.',
  TOOLTIP_CATEGORIA: 'São os agrupamentos menores dentro das Áreas. Exemplos: “Alimentação” e “Odontologia” que ficam dentro de "Saúde e Qualidade de Vida”.',
  TOOLTIP_ENTIDADE: 'Instituição responsável pela realização desta solução.',
  TOOLTIP_CARGA_HORARIA: 'Quantidade total de horas de conteúdo disponibilizado nesta solução.',
  TOOLTIP_COD_CRM: 'Código de identificação do produto.',
}

export default PtBr

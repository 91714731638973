import React from 'react'
import PropTypes from 'prop-types'
import { LightBox } from '../UtilsEstilo'

const ExibirLightBox = ({
  aberto, ajudaAberta, autenticacaoMobileAberta, fecharPopUps,
}) => {
  if (aberto || ajudaAberta || autenticacaoMobileAberta) {
    return (
      <LightBox
        ajuda={ajudaAberta || autenticacaoMobileAberta}
        onClick={() => fecharPopUps()}
      />
    )
  }
  return null
}

ExibirLightBox.propTypes = {
  aberto: PropTypes.bool,
  ajudaAberta: PropTypes.bool,
  autenticacaoMobileAberta: PropTypes.bool,
  fecharPopUps: PropTypes.func,
}

ExibirLightBox.defaultProps = {
  aberto: false,
  ajudaAberta: false,
  autenticacaoMobileAberta: false,
  fecharPopUps: undefined,
}

export default ExibirLightBox

import utils from '../utils/locales/En'

const En = {
  translation: {
    utils,
    geral: {
      meses: {
        jan: 'January',
        fev: 'February',
        mar: 'March',
        abr: 'April',
        mai: 'May',
        jun: 'June',
        jul: 'July',
        ago: 'August',
        set: 'September',
        out: 'October',
        nov: 'November',
        dez: 'December',
      },
      routes: {
        URL_HOME: '',
        URL_LOGIN: 'login',
      },
    },
  },
}

export default En

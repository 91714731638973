import PropTypes from 'prop-types'
import React, {
  useContext, useEffect, useState, lazy, Suspense,
} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Header, DivMenu, Imagem, Titulo, Hamburguer, Ajuda, DivAutenticacao, DivSeparador,
  SeparadorHeader,
  BotaoAdministrador,
  TextoPrincipalHeaderLandingPage,
  TextoComplementarHeaderLandingPage,
  DivFiemgHeaderLandingPage,
  DivSeparadorHeaderLandingPage,
  DivHeaderMobilelandingPage,
} from '../PaginasEstilo'
import { propsHeader, valueHeader } from '../PaginasPropTypes'
import Balao from '../../utils/views/Balao'
import useWidthResize from '../../utils/useWidthResize'
import PaginasContext from '../PaginasContext'
import { obterInformacoesToken } from '../../utils/Funcoes'
import MenuAreasService from '../../paraSuaEmpresa/ParaSuaEmpresaService'
import ExibirLightBox from '../../utils/views/ExibirLightBox'

const CadastroUsuariosInicio = lazy(() => import('../../cadastroUsuarios/views/CadastroUsuariosInicio'))
const ComponenteAjuda = lazy(() => import('../../contatos'))
const MenuLateral = lazy(() => import('./PaginasMenuLateral'))
const HeadPhoneSVG = lazy(() => import('../../../assets/images/HeadPhoneSVG'))
const HamburguerMenu = lazy(() => import('../../../assets/images/HamburguerSVG'))
const UsuarioHeaderSVG = lazy(() => import('../../../assets/images/UsuarioHeaderSVG'))
const LogoSVG = lazy(() => import('../../../assets/images/LogoSVG'))
const MenuCascata = lazy(() => import('./PaginaMenuCascata'))

const PaginasHeader = ({
  titulo, headerPequeno, sucesso, empresa, headerTransparente, landingPage, paraVoce, fundoBranco,
}) => {
  const [aberto, abrir] = useState(false)
  const [ajudaAberta, setAjudaAberta] = useState(false)
  const [administradorAberto, setAdministradorAberto] = useState(false)
  const [autenticacaoMobileAberta, setAutenticacaoMobileAberta] = useState(false)
  const telaGrande = useWidthResize()
  const tamanhoHeaderSucesso = telaGrande ? '81px' : '58px'
  const [usuarioLogado, setUsuarioLogado] = useState(obterInformacoesToken())
  const contexto = useContext(PaginasContext)
  const { novoUsuarioLogado, areas, setAreas } = contexto
  const { t } = useTranslation()

  function existeUsuarioLogado() {
    setUsuarioLogado(obterInformacoesToken().nome)
  }

  useEffect(() => {
    existeUsuarioLogado()
  }, [novoUsuarioLogado, usuarioLogado])

  useEffect(() => {
    async function conectar() {
      const dadosAreas = await MenuAreasService(true)
      setAreas(dadosAreas)
    }
    if (!areas || areas.length === 0) {
      try {
        conectar()
      } catch (err) {
        console.log(err)
      }
    }
  }, [areas])

  function obterAlturaHeader() {
    if (fundoBranco) {
      return 'auto'
    }
    if (headerPequeno) {
      return '64px'
    }
    if (empresa || paraVoce) {
      return '50px'
    }
    if (sucesso || headerTransparente) {
      return tamanhoHeaderSucesso
    }
    return null
  }

  function abrirFecharAjuda() {
    setAjudaAberta(!ajudaAberta)
    setAutenticacaoMobileAberta(false)
    setAdministradorAberto(false)
  }

  function abrirFecharAdministrador() {
    setAdministradorAberto(!administradorAberto)
    setAjudaAberta(false)
    setAutenticacaoMobileAberta(false)
  }

  function abrirFecharUsuario() {
    setAutenticacaoMobileAberta(!autenticacaoMobileAberta)
    setAjudaAberta(false)
    setAdministradorAberto(false)
  }

  function fecharPopUps() {
    setAutenticacaoMobileAberta(false)
    setAjudaAberta(false)
    setAdministradorAberto(false)
    abrir(false)
  }

  if (landingPage) {
    if (telaGrande) {
      return (
        <>
          <Header altura="50px">
            <DivFiemgHeaderLandingPage>
              <img src="/images/FIEMG-federacao-das-industrias-do-estado-de-minas-gerais-grande.png" alt={t('landingPage.IMAGEM_FIEMG')} height="43px" />
            </DivFiemgHeaderLandingPage>
            <DivAutenticacao>
              <ExibirUsuario
                sucesso={sucesso}
                abrirFecharUsuario={abrirFecharUsuario}
                abrirFecharAdministrador={abrirFecharAdministrador}
                headerTransparente={headerTransparente}
                autenticacaoMobileAberta={autenticacaoMobileAberta}
                landingPage={landingPage}
              />
              {obterInformacoesToken() && obterInformacoesToken().admin && (
                <BotaoAdministrador
                  onClick={abrirFecharAdministrador}
                  onKeyPress={abrirFecharAdministrador}
                  ativo={administradorAberto}
                >
                  {
                    administradorAberto
                      ? <img src="/images/engrenagem-branca.svg" alt="" />
                      : <img src="/images/engrenagem-header.svg" alt="" />
                  }
                  <Balao altura="150px" top="55px" right="56px" aberto={administradorAberto}>
                    <CadastroUsuariosInicio admin />
                  </Balao>
                </BotaoAdministrador>
              )}
              {obterInformacoesToken()
                && (
                  <Ajuda
                    tabIndex="0"
                    onClick={abrirFecharAjuda}
                    onKeyPress={abrirFecharAjuda}
                    role="button"
                  >
                    <HeadPhoneSVG selecionado={ajudaAberta} />
                    <Suspense fallback={<div />}>
                      <Balao altura="220px" right="0px" aberto={ajudaAberta}>
                        <ComponenteAjuda />
                      </Balao>
                    </Suspense>
                  </Ajuda>
                )}
            </DivAutenticacao>
            {titulo ? <Titulo>{titulo}</Titulo> : null}
            <ExibirLightBox
              ajudaAberta={ajudaAberta || administradorAberto}
              autenticacaoMobileAberta={autenticacaoMobileAberta}
              fecharPopUps={fecharPopUps}
            />
          </Header>
          <DivSeparadorHeaderLandingPage />
        </>
      )
    }
    return (
      <DivHeaderMobilelandingPage>
        <ExibirImagemHeader
          empresa
          landingPage={!usuarioLogado && landingPage}
        />
        <DivAutenticacao>
          <ExibirUsuario
            sucesso={sucesso}
            abrirFecharUsuario={abrirFecharUsuario}
            abrirFecharAdministrador={abrirFecharAdministrador}
            headerTransparente={headerTransparente}
            autenticacaoMobileAberta={autenticacaoMobileAberta}
            landingPage={landingPage}
          />
          <Ajuda
            tabIndex="0"
            onClick={abrirFecharAjuda}
            onKeyPress={abrirFecharAjuda}
            role="button"
          >
            <HeadPhoneSVG selecionado={ajudaAberta} />
            <Suspense fallback={<div />}>
              <Balao altura="220px" right="0px" aberto={ajudaAberta}>
                <ComponenteAjuda />
              </Balao>
            </Suspense>
          </Ajuda>
          <ExibirLightBox
            ajudaAberta={ajudaAberta || administradorAberto}
            autenticacaoMobileAberta={autenticacaoMobileAberta}
            fecharPopUps={fecharPopUps}
          />
        </DivAutenticacao>
        {titulo ? <Titulo>{titulo}</Titulo> : null}
      </DivHeaderMobilelandingPage>
    )
  }

  return (
    <Header headerBranco={fundoBranco} altura={obterAlturaHeader()}>
      {telaGrande ? (
        <DivMenu $headerAlternativo={empresa || paraVoce}>
          {!paraVoce && <MenuCascata paraVoce headerAlternativo={empresa} />}
          {!empresa && <MenuCascata areas={areas} headerAlternativo={paraVoce} />}
        </DivMenu>
      ) : (
        <DivMenu $headerAlternativo={paraVoce || empresa}>
          <Hamburguer onClick={() => abrir(true)}>
            <HamburguerMenu />
          </Hamburguer>
        </DivMenu>
      )}
      <ExibirImagemHeader
        empresa={empresa || paraVoce}
        headerPequeno={headerPequeno}
      />
      <DivAutenticacao>
        <ExibirUsuario
          sucesso={sucesso}
          abrirFecharUsuario={abrirFecharUsuario}
          headerTransparente={headerTransparente}
          autenticacaoMobileAberta={autenticacaoMobileAberta}
        />
        {obterInformacoesToken() && obterInformacoesToken().admin && telaGrande && (
          <BotaoAdministrador
            type="button"
            onClick={abrirFecharAdministrador}
            ativo={administradorAberto}
          >
            {
              administradorAberto
                ? <img src="/images/engrenagem-branca.svg" alt="" width="32" height="32" />
                : <img src="/images/engrenagem-header.svg" alt="" width="32" height="32" />
            }
            <Balao altura="150px" top="auto" right="56px" aberto={administradorAberto}>
              <CadastroUsuariosInicio admin />
            </Balao>
          </BotaoAdministrador>
        )}
        <Ajuda role="button" tabIndex="0" onClick={abrirFecharAjuda} onKeyPress={abrirFecharAjuda}>
          <HeadPhoneSVG selecionado={ajudaAberta} />
          <Suspense fallback={<div />}>
            <Balao altura="220px" right="0px" aberto={ajudaAberta}>
              <ComponenteAjuda />
            </Balao>
          </Suspense>
        </Ajuda>
      </DivAutenticacao>
      <Suspense fallback={<div />}>
        <MenuLateral aberto={aberto} abrir={abrir} areas={areas} lightBox={ExibirLightBox} />
      </Suspense>
      {titulo ? <Titulo>{titulo}</Titulo> : null}
      <ExibirLightBox
        ajudaAberta={ajudaAberta || administradorAberto}
        autenticacaoMobileAberta={autenticacaoMobileAberta}
        fecharPopUps={fecharPopUps}
      />
      {headerTransparente || sucesso
        ? (
          <DivSeparador>
            <SeparadorHeader />
          </DivSeparador>
        )
        : null}
    </Header>
  )
}

PaginasHeader.propTypes = propsHeader
PaginasHeader.defaultProps = valueHeader

export default PaginasHeader

function ObterImagemHeader({ headerPequeno, empresa, landingPage }) {
  const telaGrande = useWidthResize()
  const { t } = useTranslation()
  if (landingPage) {
    return <img src="/images/FIEMG-federacao-das-industrias-do-estado-de-minas-gerais-w160.svg" alt={t('paginas.ALT_IMAGEM_FIEMG')} width="160px" height="41px" />
  }
  if (headerPequeno || empresa) {
    return <LogoSVG />
  }
  if (telaGrande) {
    return <LogoSVG grande />
  }
  return <LogoSVG pequeno />
}

ObterImagemHeader.propTypes = {
  empresa: PropTypes.bool,
  headerPequeno: PropTypes.bool,
  landingPage: PropTypes.bool,
}

ObterImagemHeader.defaultProps = {
  empresa: false,
  headerPequeno: false,
  landingPage: false,
}

export const ExibirImagemHeader = ({
  empresa, headerPequeno, landingPage,
}) => {
  const telaGrande = useWidthResize()

  if (empresa && telaGrande) {
    return null
  }

  return (
    <Imagem logoGrande={telaGrande && !empresa && !headerPequeno}>
      <Link to="/">
        <ObterImagemHeader
          landingPage={landingPage}
          empresa={empresa}
          headerPequeno={headerPequeno}
        />
      </Link>
    </Imagem>
  )
}

ExibirImagemHeader.propTypes = {
  empresa: PropTypes.bool,
  headerPequeno: PropTypes.bool,
  landingPage: PropTypes.bool,
}

ExibirImagemHeader.defaultProps = {
  empresa: false,
  headerPequeno: false,
  landingPage: false,
}

const ExibirUsuario = ({
  sucesso, abrirFecharUsuario, headerTransparente, autenticacaoMobileAberta, landingPage,
}) => {
  const telaGrande = useWidthResize()
  const { t } = useTranslation()

  if (telaGrande) {
    return (
      <>
        <Suspense fallback={<div />}>
          {landingPage && !obterInformacoesToken() && (
            <>
              <TextoPrincipalHeaderLandingPage>{t('paginas.JA_POSSUI')}</TextoPrincipalHeaderLandingPage>
              <TextoComplementarHeaderLandingPage>{t('paginas.CADASTRO')}</TextoComplementarHeaderLandingPage>
              <TextoPrincipalHeaderLandingPage>{t('paginas.INTERROGACAO')}</TextoPrincipalHeaderLandingPage>
            </>
          )}
          <CadastroUsuariosInicio
            headerWeb
            headerTransparente={headerTransparente || sucesso}
            cotacao={sucesso}
            ids
          />
        </Suspense>
        {obterInformacoesToken() && (
          <Ajuda
            onClick={abrirFecharUsuario}
            tabIndex="0"
            onKeyPress={abrirFecharUsuario}
            role="button"
            usuario
          >
            <UsuarioHeaderSVG selecionado={autenticacaoMobileAberta} />
            <BalaoUsuario
              sucesso={sucesso}
              autenticacaoMobileAberta={autenticacaoMobileAberta}
              abrirFecharUsuario={abrirFecharUsuario}
              admin={obterInformacoesToken().admin}
            />
          </Ajuda>
        )}
      </>
    )
  }
  return (
    <Ajuda
      role="button"
      tabIndex="0"
      onClick={abrirFecharUsuario}
      onKeyPress={abrirFecharUsuario}
    >
      <UsuarioHeaderSVG selecionado={autenticacaoMobileAberta} />
      <BalaoUsuario
        sucesso={sucesso}
        autenticacaoMobileAberta={autenticacaoMobileAberta}
        abrirFecharUsuario={abrirFecharUsuario}
        admin={obterInformacoesToken().admin}
      />
    </Ajuda>
  )
}

ExibirUsuario.propTypes = {
  abrirFecharUsuario: PropTypes.func,
  sucesso: PropTypes.bool,
  headerTransparente: PropTypes.bool,
  autenticacaoMobileAberta: PropTypes.bool,
  landingPage: PropTypes.bool,
}

ExibirUsuario.defaultProps = {
  abrirFecharUsuario: undefined,
  sucesso: false,
  headerTransparente: false,
  autenticacaoMobileAberta: false,
  landingPage: false,
}

const BalaoUsuario = ({
  autenticacaoMobileAberta, abrirFecharUsuario, sucesso, admin,
}) => (
  <Suspense fallback={<div />}>
    <Balao
      altura="auto"
      posicaoSeta="79%"
      right={admin ? '68px' : '12px'}
      aberto={autenticacaoMobileAberta}
    >
      <CadastroUsuariosInicio
        headerMobile
        cotacao={sucesso}
        fecharBalao={abrirFecharUsuario}
      />
    </Balao>
  </Suspense>
)

BalaoUsuario.propTypes = {
  abrirFecharUsuario: PropTypes.func,
  autenticacaoMobileAberta: PropTypes.bool,
  sucesso: PropTypes.bool,
  admin: PropTypes.bool,
}

BalaoUsuario.defaultProps = {
  abrirFecharUsuario: undefined,
  autenticacaoMobileAberta: false,
  sucesso: false,
  admin: false,
}

import React from 'react'

const TimeoutSVG = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z" fill="#F5F6F9" />
    <path d="M25.469 60.6681C25.4669 56.0145 26.4329 51.4113 28.3056 47.1511C30.1783 42.8909 32.9168 39.0669 36.347 35.9221" stroke="#F5F6F9" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <circle cx="60" cy="60" r="44" fill="#FEFEFE" />
    <path d="M82.5 67.35C83.6046 67.35 84.5 66.4546 84.5 65.35C84.5 64.2454 83.6046 63.35 82.5 63.35L82.5 67.35ZM62 35.5C62 34.3954 61.1046 33.5 60 33.5C58.8954 33.5 58 34.3954 58 35.5L62 35.5ZM60 65.35L58 65.35C58 66.4546 58.8954 67.35 60 67.35L60 65.35ZM82.5 63.35L60 63.35L60 67.35L82.5 67.35L82.5 63.35ZM62 65.35L62 35.5L58 35.5L58 65.35L62 65.35Z" fill="#CFD3D8" />
    <path d="M38 99.2648C44.5047 102.917 52.0091 105 60 105C84.8528 105 105 84.8528 105 60C105 35.1472 84.8528 15 60 15C35.1472 15 15 35.1472 15 60C15 71.7682 19.5174 82.4814 26.9127 90.5M38 99.2648L46 95M38 99.2648L40.5 108.5" stroke="#CFD3D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default TimeoutSVG

import PropTypes from 'prop-types'
import React from 'react'

const RelogioSVG = ({ cor, altura, largura }) => (
  <svg width={altura} height={largura} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6025 1.66675 10.0001 1.66675C5.39771 1.66675 1.66675 5.39771 1.66675 10.0001C1.66675 14.6025 5.39771 18.3334 10.0001 18.3334Z" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 5V10L12.5 12.5" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

RelogioSVG.propTypes = {
  cor: PropTypes.string,
  altura: PropTypes.number,
  largura: PropTypes.number,
}

RelogioSVG.defaultProps = {
  cor: undefined,
  altura: 20,
  largura: 20,
}

export default RelogioSVG

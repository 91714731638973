import React from 'react'

const CirculoAjudaToolTipSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.08984 8.99987C9.57528 7.61991 10.9852 6.79128 12.427 7.03858C13.8688 7.28589 14.922 8.53702 14.9198 9.99987C14.9198 11.9999 11.9198 12.9999 11.9198 12.9999" stroke="#005CAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#005CAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="12" cy="17" r="1" fill="#005CAF" />
  </svg>

)

export default CirculoAjudaToolTipSVG

import React from 'react'

const TwitterSVG = () => (
  <svg alt="Twitter logo" width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Twitter Logo</title>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.6654 2.00001C29.3885 2.90065 27.9748 3.58949 26.4787 4.04001C24.8338 2.14873 22.1832 1.4855 19.8415 2.37928C17.4998 3.27307 15.9651 5.53375 15.9987 8.04001V9.37335C11.2396 9.49675 6.73473 7.22931 3.9987 3.33334C3.9987 3.33334 -1.33464 15.3333 10.6654 20.6667C7.9194 22.5306 4.64824 23.4653 1.33203 23.3333C13.332 30 27.9987 23.3333 27.9987 8.00001C27.9975 7.62862 27.9618 7.25814 27.892 6.89335C29.2528 5.55134 30.2131 3.85696 30.6654 2.00001Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default TwitterSVG

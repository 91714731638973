import propTypes from 'prop-types'

export const propsButton = {
  nome: propTypes.oneOfType([
    propTypes.string,
    propTypes.object]),
  tamanho: propTypes.string,
  variant: propTypes.oneOfType([
    propTypes.string,
    propTypes.bool]),
  action: propTypes.func,
}

export const valueButton = {
  nome: '',
  tamanho: '',
  variant: null,
  action: null,
}

export const propsCard = {
  tituloCard: propTypes.string,
  textoCard: propTypes.string,
}

export const valueCard = {
  tituloCard: '',
  textoCard: '',
}

export const propsCardContainer = {
  children: propTypes.oneOfType([propTypes.element, propTypes.arrayOf(propTypes.element)]),
}

export const valueCardContainer = {
  children: null,
}

export const propsBuscar = {
  placeholder: propTypes.string,
  valor: propTypes.string,
  alterarValor: propTypes.func,
}

export const valueBuscar = {
  placeholder: '',
  valor: '',
}

export const propsAccordion = {
  children: propTypes.oneOfType([propTypes.array, propTypes.object]),
  titulo: propTypes.string,
  icone: propTypes.string,
}

export const valueAccordion = {

}

export const propsLoading = {
  mensagem: propTypes.string,
}

export const valueLoading = {
  mensagem: '',
}

export const propsTooltip = {
  texto: propTypes.string,
  tamanho: propTypes.string,
}

export const valueTooltip = {
  texto: '',
  tamanho: '',
}

export const propsAutocomplete = {
  lista: propTypes.array,
  valor: propTypes.string,
  setValor: propTypes.func,
}

export const propsUnidades = {
  aberto: propTypes.bool,
  abrir: propTypes.func,
  unidades: propTypes.array,
  pessoaFisica: propTypes.bool,
}

export const valueUnidades = {
  aberto: false,
  pessoaFisica: false,
}

export default propsButton

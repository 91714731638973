import React from 'react'

const IconeSetaBaixoPreenchidaSVG = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 1.75L6 6.25L10.5 1.75" fill="#FEFEFE" />
    <path d="M1.5 1.75L6 6.25L10.5 1.75H1.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default IconeSetaBaixoPreenchidaSVG

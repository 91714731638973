const PtBr = {
  FRASE_INICIO: 'O seu ',
  FRASE_COMPLEMENTO: 'passaporte ',
  FRASE_FIM: 'para o conhecimento e a competitividade.',
  FRASE_SECUNDARIA: 'Aprenda novas habilidades, se destaque como profissional, qualifique sua empresa e seja reconhecido pelo mercado.',
  PARA: 'para ',
  VOCE: 'Você',
  SUA_EMPRESA: 'sua Empresa',
  PROCURANDO: 'Procurando nosso',
  CONTEUDO: ' conteúdo institucional?',
  ACESSE_AQUI: 'Acesse aqui',
  ALT_LISTRAS_FIEMG: 'Padrao de listras da FIEMG - Federacao das Industrias do estado de Minas Gerais',
  PORTAL: 'Veja o portal institucional da FIEMG - Federacao das Industrias do estado de Minas Gerais',
  LISTRAS: 'Listras',
  IMAGEM_FIEMG: 'FIEMG - Federacao das Industrias do estado de Minas Gerais',
  TITLE_PAGINA: 'FIEMG - Plataforma de Negócios',
  DESCRICAO_PAGINA: 'Plataforma para venda de cursos e soluções FIEMG, SENAI, SESI, IEL, CIEMG. Aprenda novas habilidades, se destaque como profissional, qualifique sua empresa e seja reconhecido pelo mercado.',
  ALT_IMAGEM_FIEMG: 'Imagem ilustrativa FIEMG - Federacao das Industrias do estado de Minas Gerais',
  FRASE_PRIMARIA_SECAO_PRINCIPAL: 'Mais conhecimento ',
  FRASE_PRA_VOCE_SECAO_PRINCIPAL: 'pra você',
  FRASE_SECUNDARIA_SECAO_PRINCIPAL: 'mais soluções pro ',
  FRASE_SUA_EMPRESA_SECAO_PRINCIPAL: 'seu negócio',
  VIRGULA_SECAO_PRINCIPAL: ',',
  PONTO_SECAO_PRINCIPAL: '.',
  PARA_TITULO_BOTAO_SECAO_PRINCIPAL: 'Para ',
  VOCE_TITULO_BOTAO_SECAO_PRINCIPAL: 'Você',
  FRASE_SECUNDARIA_MOBILE_SECAO_PRINCIPAL: 'Mais soluções pra ',
  SUA_EMPRESA_TITULO_BOTAO_SECAO_PRINCIPAL: 'sua Empresa',
  TEXTO_BOTAO_PARA_VOCE_SECAO_PRINCIPAL: 'Conquiste o mercado de trabalho e impulsione sua carreira com cursos, especializações e treinamentos desenvolvidos para você.',
  TEXTO_BOTAO_PARA_SUA_EMPRESA_SECAO_PRINCIPAL: 'Otimize processos, aprimore resultados e fortaleça seu negócio com treinamentos, ensaios técnicos, cursos e capacitações.',
  SUA_TITULO_BOTAO_SECAO_PRINCIPAL: 'sua',
  EMPRESA_TITULO_BOTAO_SECAO_PRINCIPAL: 'Empresa',
  DESCRICAO_MOBILE_INICIO_SECAO_PRINCIPAL: 'Aqui você encontra soluções do ',
  DESCRICAO_MOBILE_MEIO_SECAO_PRINCIPAL: 'SENAI, SESI e IEL',
  DESCRICAO_MOBILE_FIM_SECAO_PRINCIPAL: '! Venha aprender novas habilidades, se destacar como profissional, qualificar sua empresa e ser reconhecido pelo mercado.',
  MELHORES_SOLUCOES: 'As melhores soluções do',
  AO_SEU_ALCANCE: 'Sistema FIEMG estão ao seu alcance.',
  MAIS_CONHECIMENTO: 'Mais conhecimento',
  MAIS_PERTO_DE_VOCE: 'Mais perto de você',
  MAIS_CURSOS: 'Mais cursos',
  MAIS_CONHECIMENTO_TEXTO: 'Conteúdos relevantes e atualizados ensinados pelos melhores profissionais da indústria, para tornar o seu currículo mais competitivo.',
  MAIS_PERTO_DE_VOCE_TEXTO: 'Tudo de forma simples e eficiente, para você aprender no seu próprio ritmo. ',
  MAIS_CURSOS_TEXTO: 'Aprenda habilidades práticas para seu dia a dia de trabalho com as melhores soluções para profissionais e para empresas.',
  ATUAMOS: 'Diariamente, atuamos para ',
  DESENVOLVER: 'desenvolver ',
  NEGOCIOS: 'negócios, ',
  CAPACITAR: 'capacitar ',
  PESSOAS_E: 'pessoas e ',
  TRANSFORMAR: 'transformar ',
  A_SOCIEDADE: 'a sociedade.',
  INSTITUICAO_SENAI: 'SENAI',
  CATEGORIA_EDUCACAO: 'Eduçação',
  UNIDADE_ALUNOS: ' alunos',
  UNIDADE_TREINAMENTO: ' treinamentos para funcionários de empresas',
  INSTITUICAO_SESI: 'SESI',
  TIPO_PARA_EMPRESAS: 'Para Empresas',
  UNIDADE_INDUSTRIAS_SAUDE: ' indústrias atendidas pela área da saúde',
  UNIDADE_INDUSTRIAS_RESP_SOCIAL: ' indústrias atendidas pela área de Responsabilidade Social',
  INSTITUICAO_IEL: 'IEL',
  TIPO_NEGOCIOS_INVESTIMENTOS: 'Atração de Negócios e Investimentos',
  UNIDADE_EMPRESAS_PARTICIPANTES: ' empresas participantes',
  UNIDADE_REUNIOES_NEGOCIOS: ' reuniões de negócios',
  INSTITUICAO_SENAI_CIT: 'SENAI CIT',
  TIPO_INOVACAO_TECNOLOGIA: 'Inovação e Tecnologia',
  UNIDADE_CLIENTES_ATENDIDOS: ' clientes atendidos',
  UNIDADE_SERVIÇOS_TECNICOS: ' serviços técnicos especializados',
  UNIDADE_PROJETOS_INOVACAO: ' projetos de inovação',
  INSTUICAO_FIEMG: 'FIEMG',
  TIPO_NEGOCIOS_INTERNACIONAIS: 'Negócios Internacionais',
  UNIDADE_CERTIFICADOS_EMITIDOS: ' certificados emitidos',
  UNIDADE_EMPRESAS_ATENDIDAS: ' empresas atendidas',
  INSTITUICAO_CIEMG: 'CIEMG',
  TIPO_TREINAMENTOS: 'Treinamentos',
  UNIDADE_EVENTOS_REALIZADOS: ' eventos realizados',
  UNIDADE_PESSOAS_CAPACITADAS: ' pessoas capacitadas',
  UNIDADE_ALUNOS_EM: ' alunos em',
  UNIDADE_CIDADES: ' cidades',
  UNIDADE_COLABORADORES_EDUCACAO: ' colaboradores na educação',
  PREFIXO_MAIS_DE: 'Mais de ',
  PERGUNTAS_FREQUENTES: 'Perguntas Frequentes:',
  PRIMEIRA_PERGUNTA: 'O que é e como posso me inscrever para os cursos de Aprendizagem Industrial? ',
  PRIMEIRA_RESPOSTA_PARAGRAFO_A: 'Os cursos de Aprendizagem Industrial são voltados para a faixa etária de 14 a 24, qualificando os jovens para atuarem na'
  + 'indústria, que podem ser contratados durante a realização do curso pleiteado. O SENAI divulga as vagas abertas à '
  + 'comunidade através da mídia ou do site <a>www.senaimg.com.br<a>',
  PRIMEIRA_RESPOSTA_PARAGRAFO_B: 'Para ingressar em qualquer um dos cursos de Aprendizagem Industrial oferecidos pelo SENAI, é necessário realizar a inscrição e se submeter a um processo seletivo com '
  + 'aplicação de provas, além disto, é imprescindível a apresentação da carteira de trabalho.',
  PRIMEIRA_RESPOSTA_PARAGRAFO_C: 'No edital de seleção, divulgado através do site: <a>www.senaimg.com.br/aprendizagem<a> ficam disponíveis as regras e informações sobre o processo e os '
  + 'cursos que serão ofertados. Geralmente, as inscrições ocorrem nos meses de abril e setembro.',
  SEGUNDA_PERGUNTA: 'O que é e como posso me inscrever para os Cursos Técnicos do SENAI? ',
  SEGUNDA_RESPOSTA_PARAGRAFO_A: 'Os cursos do SENAI na modalidade técnica são pagos, e as inscrições são realizadas no site <a>www.senaimg.com.br<a> quando '
  + 'houver a abertura do edital. Os candidatos serão matriculados, por ordem cronológica de efetivação da inscrição, '
  + 'pagamento do boleto, e entrega de documentação na Unidade observando dentro do limite de vagas oferecidas para o '
  + 'curso escolhido.',
  SEGUNDA_RESPOSTA_PARAGRAFO_B: 'O candidato deve estar matriculado ou ser concluinte do Ensino Médio, no ensino regular ou EJA – '
  + 'Educação de Jovens e Adultos (Nível Médio) ou ter sido aprovado parcialmente no Exame de Massa de Nível Médio.',
  TERCEIRA_PERGUNTA: 'O que são os cursos de Qualificação? Como posso me matricular?',
  TERCEIRA_RESPOSTA_PARAGRAFO_A: 'São cursos de capacitação para profissionais que desejam ingressar no mercado de trabalho ou visam a recolocação '
  + 'profissional. Os cursos podem tanto qualificar as pessoas que ainda não têm competência na área profissional desejada, '
  + 'como oferecer a oportunidade de requalificação profissional.',
  TERCEIRA_RESPOSTA_PARAGRAFO_B: 'A duração mínima prevista é de 160 horas, conforme § 1º '
  + 'do Art. 3º do Decreto nº 5.154/2004, alterado pelo Decreto nº 8.268/2014 Duração média: De 2 a 4 meses. Para ingressar '
  + 'nos cursos, é necessário entrar em contato com a unidade responsável por ministrar o curso e realizar a matrícula.',
  QUARTA_PERGUNTA: 'Como faço para participar de um processo seletivo na FIEMG?',
  QUARTA_RESPOSTA_PARAGRAFO_A: 'Os interessados em compor o quadro de colaboradores da FIEMG (FIEMG, CIEMG, SESI, SENAI e IEL) deverão acessar no '
  + 'site da FIEMG o link “Trabalhe Conosco” localizado na parte inferior do site, ou acessar diretamente o endereço: '
  + '<a>www7.fiemg.com.br/publicacoes-internas/Trabalhe%20Conosco<a>',
  QUARTA_RESPOSTA_PARAGRAFO_B: 'É necessário inserir os dados para cadastro na opção “cadastre seu currículo”, em seguida o candidato poderá encontrar na opção “encontre sua vaga” as oportunidades '
  + 'disponíveis e realizar a sua candidatura.',
  QUARTA_RESPOSTA_PARAGRAFO_C: 'Havendo vagas disponíveis e condizentes com o perfil cadastrado, a área de RH entrará em contato por telefone ou e-mail.',
  QUINTA_PERGUNTA: 'Quero matricular meu filho nas Escolas SESI, como faço?',
  QUINTA_RESPOSTA_PARAGRAFO_A: 'A rede das Escolas SESI oferece: Educação Infantil para crianças de 1 a 5 anos; Ensino Fundamental para crianças e '
  + 'adolescentes de 6 a 14 anos; Ensino Médio para adolescentes de 15 a 17 anos. Para ingressar é necessário participar de processo seletivo.',
  QUINTA_RESPOSTA_PARAGRAFO_B: 'Todas as informações sobre as vagas e as inscrições para a Escola SESI são divulgadas no site: '
  + '<a>sesiescolas.fiemg.com.br/<a> por meio de edital. De acordo com a modalidade de ensino, o processo pode ocorrer '
  + 'por prova ou ordem de inscrição.',
  QUINTA_RESPOSTA_PARAGRAFO_C: 'Dependentes de industriários possuem 10% de desconto nas mensalidades.',
  SEXTA_PERGUNTA: 'Onde consigo informações sobre as convenções coletivas de trabalho do setor metalúrgico? ',
  SEXTA_RESPOSTA_PARAGRAFO_A: 'A FIEMG possui uma equipe de advogados trabalhistas preparada para orientar os sindicatos e indústrias sobre a aplicação'
  + 'da legislação. A área tem como principal atribuição, a realização de negociações coletivas de trabalho em nome da FIEMG '
  + 'e dos sindicatos patronais filiados.',
  SEXTA_RESPOSTA_PARAGRAFO_B: 'Para visualizar a convenção acordada é necessário acessar o site: '
  + '<a>www7.fiemg.com.br/fiemg/produto/relacoes-trabalhistas<a>',
  SEXTA_RESPOSTA_PARAGRAFO_C: 'Mais informações podem ser obtidas pelo e-mail: grt@fiemg.com.br ou pelo telefone (31) 3263-4395.',
  SETIMA_PERGUNTA: 'Gostaria de saber qual o sindicato que minha empresa pertence.',
  SETIMA_RESPOSTA_PARAGRAFO_A: 'Para verificar de qual sindicato sua empresa está filiada, é necessário realizar o enquadramento sindical. '
  + 'Para isto, é preciso enviar um e-mail para cadastro@fiemg.com.br com as seguintes informações: Razão Social, CNPJ, Contrato social '
  + 'ou última alteração contratual, dois números de contato (fixo e celular) e e-mail (da empresa).',
  SETIMA_RESPOSTA_PARAGRAFO_B: 'A área tem um prazo de até 48 horas úteis para lhe dar um retorno sobre a solicitação. Além deste método, há um telefone de contato para '
  + 'verificar a informação.',
}

export default PtBr

const PtBr = {
  SESI: 'Sesi',
  SENAI: 'Senai',
  DUVIDA: 'dúvida?',
  VOCE_TEM: 'Você tem alguma ',
  TEXTO: 'Se ainda tem alguma dúvida sobre a FIEMG e nossas soluções, entre em contato com a gente pelo:',
  WHATS: 'Pelo Whatsapp',
  TEL: '4020-9030',
  PORTAL_TRANSPARENCIA: 'Portal da Transparência',
  AJUDA: 'AJUDA',
  CONTATO: 'CONTATO',
  SIGA: 'Siga nossas Redes Sociais',
  CENTRAL: 'Central de Relacionamento com o Cliente: ',
  GERAL: 'Geral: ',
  OUVIDORIA: 'Ouvidoria: ',
  EMAIL: 'EMAIL',
  HORARIO: 'Horário de funcionamento: ',
  FUNCIONAMENTO: 'Segunda a sexta-feira das 8h às 17h',
  EXCETO: '(Exceto feriados locais e nacionais)',
  LOCALIZACAO: 'LOCALIZACAO',
  ENDERECO: 'Av. do Contorno, 4456 - Funcionários, Belo Horizonte/MG - CEP: 30110-028',
  POLITICA: 'Política de Privacidade',
  SUBTITULO_POLITICA: 'Compromisso com as informações que coletamos dos usuários do portal FIEMG',
  TEXTO_POLITICA: 'A Política de Privacidade On-line foi elaborada para reafirmar nosso compromisso com as informações que coletamos dos usuários do portal fiemg.com.br, serviço este oferecido pelo Sistema FIEMG.'

    + '\n A presente política abrange o tratamento que o portal do Sistema FIEMG dá às informações capazes de identificar os usuários, especialmente as coletadas diretamente em seu portal e/ou APP na Internet.'

    + '\n O portal contém acesso a links para outros sites externos, cujos conteúdos e políticas de privacidade não são de responsabilidade do portal do Sistema FIEMG. Assim, recomendamos que, ao ser redirecionado para sites externos, os usuários consultem sempre as respectivas políticas de privacidade antes de fornecer seus dados ou informações.'

    + '\n Esta política está sujeita a alterações. Desse modo, recomendamos sua periódica consulta.'

    + '\n Contudo, caso o portal do Sistema FIEMG, em algum momento, promova mudança substancial na maneira de usar as informações pessoais coletadas, as novas condições de privacidade e segurança da informação serão informadas ao público em geral, mediante conteúdo em destaque em nossa página principal.'

    + '\n PARA FINS DESTA POLÍTICA, DEVEM SER OBSERVADAS AS SEGUINTES CONSIDERAÇÕES: '

    + '\n O portal do Sistema FIEMG coleta informações pessoais, capazes de identificar os usuários, quando: a) cadastram-se em nosso portal; cadastram-se em nosso APP; assinam as Newsletters.'

    + '\n Para cada uma das modalidades de coleta de dados poderão ser solicitadas diferentes informações, de acordo com a finalidade da sua coleta. Assim, os usuários serão sempre informados sobre os dados que estão sendo coletados, ficando a seu critério fornecê-los ou não. '

    + '\n O portal do Sistema FIEMG também recebe e armazena automaticamente, através de cookies, informações em seus servidores sobre as atividades advindas do navegador, incluindo endereço IP e a página acessada. '

    + '\n Um cookie é uma pequena quantidade de informação que geralmente inclui um identificador anônimo único. Alguns sites da Internet enviam para seu navegador para que seja guardado no disco rígido do computador, permitindo sua identificação na próxima vez que retornar ao portal.'

    + '\n A entrada de dados pessoais do usuário no portal do Sistema FIEMG, bem como a aceitação de cookies de navegação, não constituem requisito para navegar pelo mesmo, sendo facultativo o seu fornecimento. O usuário que decide dar entrada a seus dados pessoais declara conhecer e aceitar os termos da presente.'

    + '\n Todas as informações coletadas dos usuários trafegam de forma segura, utilizando processo de criptografia padrão de Internet.'

    + '\n Qualquer informação fornecida pelos usuários será armazenada e envidaremos nossos melhores esforços na manutenção da confidencialidade, integridade e disponibilidade das informações que nos forem fornecidas.'

    + '\n Todos os dados pessoais coletados serão incorporados ao banco de dados do Sistema FIEMG, com sede na Avenida do Contorno, 4456, bairro Funcionários, Belo Horizonte, Minas Gerais, CEP: 30110-028. '

    + '\n O portal do Sistema FIEMG utiliza as informações coletadas para os seguintes propósitos gerais: (i) enviar notícias e informar a respeito de produtos, serviços, eventos das Entidades que compõem o Sistema FIEMG: FIEMG, IEL, CIEMG, SESI, SENAI; (ii) manter atualizados os cadastros dos usuários para fins de contato via correio eletrônico, SMS e/ou outros meios de comunicação; (iii) otimizar a usabilidade e a experiência interativa durante a navegação do usuário no portal; (iv) elaborar estatísticas gerais, sem que haja identificação dos usuários; (v) responder às dúvidas e solicitações de seus usuários; (vi) realizar campanhas de comunicação e marketing de relacionamento; e (vii) comunicar-se com os usuários, a fim de lhes dar informações sobre o portal.'

    + '\n  O acesso às informações coletadas é restrito aos funcionários que trabalham diretamente com o portal e pessoas autorizadas pelo Sistema FIEMG.'

    + '\n A não ser por determinação judicial, as informações dos usuários cadastrados no ambiente deste portal do Sistema FIEMG jamais serão transferidas a terceiros.'

    + '\n O usuário garante a veracidade e exatidão dos dados pessoais que fornecer neste portal, ou seja, o portal do Sistema FIEMG não tem qualquer responsabilidade no caso de inserção de dados falsos ou inexatidão dos dados pessoais introduzidos pelo usuário neste portal.'

    + '\n O usuário que introduzir seus dados pessoais identificáveis poderá, a qualquer momento alterar, corrigir e remover como for conveniente. Para isso, deverá solicitar o descadastramento no link de remoção enviado nos comunicados e/ou no próprio APP.'

    + '\n As newsletters e mensagens publicitárias enviadas por e-mail trarão, obrigatoriamente, opção de cancelamento do envio daquele tipo de mensagem por parte do portal do Sistema FIEMG. A solicitação será atendida com a maior presteza possível.'

    + '\n O usuário, em hipótese alguma, deverá fornecer seus dados por e-mail, por se tratar de um ambiente sem os requisitos adequados de segurança. Desta forma o portal do Sistema FIEMG exime-se de quaisquer responsabilidades quanto à correção, alteração ou remoção de dados. '

    + '\n Os serviços de envio de e-mails são realizados por empresa contratada pelo portal do Sistema FIEMG, que utiliza seus próprios servidores para realizar o envio. A empresa contratada não armazena nem utiliza de nenhuma forma e sob nenhuma hipótese, os e-mails do cadastro do portal do Sistema FIEMG para qualquer outro fim que não o envio das mensagens, de acordo com as preferências de cada usuário registradas no portal do Sistema FIEMG.'

    + '\n É de responsabilidade do usuário a guarda de sua senha e de seu login de acesso, quando houver. Não é adequada a utilização de senhas óbvias, como datas especiais, nomes ou sequências numéricas. Caso o usuário tome conhecimento ou apenas suspeite que sua senha foi descoberta, ele deverá alterá-la em sua página de cadastro imediatamente.'

    + '\n Ainda que coletivamente, os dados só podem ser utilizados para os fins previstos nesta Política de Privacidade.'

    + '\n Para mais informações, fale conosco:https://www7.fiemg.com.br/fale-conosco',
  FALE_CONOSCO: 'Fale Conosco',
  EXPANDIR: 'EXPANDIR',
  TITULO_PAGINA: 'FIEMG - Fale Conosco',
  ALT_IMG_HEADPHONE: 'Icone representativo de head phone da FIEMG',
  ALT_IMG_TELEFONE: 'Icone representativo de telefone da FIEMG',
  ALT_IMG_ADD_CONTATO: 'Icone representativo de adicionar contato da FIEMG',
  ALT_IMG_LOCALIZACAO: 'Icone representativo de localização da FIEMG',
  DESCRICAO_PAGINA: 'Entre em contato com a FIEMG para tirar dúvidas e sugestões.',
}

export default PtBr

import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import Routes from '../Routes'
import AlertaSnack from '../../alerta'
import { App } from '../PaginasEstilo'

function PaginaInicio() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS,
      {
        debug: false,
        titleCase: false,
        gaOptions: {
          userId: 'PortalFiemg',
        },
      })
  })
  return (
    <App>
      <Routes />
      <AlertaSnack />
    </App>
  )
}

export default PaginaInicio

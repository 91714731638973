import React from 'react'

const LinkedinSVG = () => (
  <svg alt="Linkedin logo" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Linkedin Logo</title>
    <path d="M4.54987 9.13667C7.05863 9.13667 9.09973 7.08728 9.09973 4.56834C9.09973 2.04905 7.05863 0 4.54987 0C2.04077 0 0 2.04939 0 4.56834C0 7.08728 2.04077 9.13667 4.54987 9.13667ZM4.54987 1.73207C6.10748 1.73207 7.37466 3.0044 7.37466 4.56834C7.37466 6.13227 6.10748 7.4046 4.54987 7.4046C2.99225 7.4046 1.72507 6.13227 1.72507 4.56834C1.72507 3.0044 2.99225 1.73207 4.54987 1.73207Z" fill="#FEFEFE" />
    <path d="M23.6119 9.56958C21.7056 9.56958 19.8656 10.2353 18.3936 11.4119V10.8686C18.3936 10.3903 18.0071 10.0026 17.5311 10.0026H11.5149C11.0385 10.0026 10.6523 10.3903 10.6523 10.8686V31.1339C10.6523 31.6122 11.0385 31.9999 11.5149 31.9999H17.8545C18.3306 31.9999 18.717 31.6122 18.717 31.1339V19.437C18.717 17.9924 19.8875 16.8172 21.3262 16.8172C22.7649 16.8172 23.9354 17.9924 23.9354 19.437V31.1339C23.9354 31.6122 24.3215 31.9999 24.7979 31.9999H31.1375C31.6136 31.9999 32.0001 31.6122 32.0001 31.1339V17.9918C32.0001 13.3477 28.2369 9.56958 23.6119 9.56958ZM30.275 30.2678H25.6604V19.437C25.6604 17.0374 23.716 15.0851 21.3262 15.0851C18.936 15.0851 16.992 17.0374 16.992 19.437V30.2678H12.3774V11.7347H16.6685V13.5544C16.6685 13.9292 16.9084 14.2614 17.2635 14.3778C17.618 14.4938 18.0075 14.3676 18.2275 14.0648C19.4856 12.3348 21.4984 11.3017 23.6119 11.3017C27.2858 11.3017 30.275 14.3027 30.275 17.9918V30.2678Z" fill="#FEFEFE" />
    <path d="M1.37816 10.0027C0.901744 10.0027 0.515625 10.3904 0.515625 10.8687V31.1339C0.515625 31.6123 0.901744 32 1.37816 32H7.71778C8.19386 32 8.58031 31.6123 8.58031 31.1339V10.8687C8.58031 10.3904 8.19386 10.0027 7.71778 10.0027H1.37816ZM6.85525 30.2679H2.24069V11.7348H6.85525V30.2679Z" fill="#FEFEFE" />
  </svg>

)

export default LinkedinSVG

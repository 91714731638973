import React from 'react'
import propTypes from 'prop-types'

const LocalizacaoSVG = ({ cor, altura, largura }) => (
  <svg width={altura} height={largura} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5 9.33325C17.5 15.1666 10 20.1666 10 20.1666C10 20.1666 2.5 15.1666 2.5 9.33325C2.5 5.19112 5.85786 1.83325 10 1.83325C14.1421 1.83325 17.5 5.19112 17.5 9.33325V9.33325Z" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 11.8333C11.3807 11.8333 12.5 10.714 12.5 9.33325C12.5 7.95254 11.3807 6.83325 10 6.83325C8.61929 6.83325 7.5 7.95254 7.5 9.33325C7.5 10.714 8.61929 11.8333 10 11.8333Z" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

LocalizacaoSVG.propTypes = {
  cor: propTypes.string,
  altura: propTypes.number,
  largura: propTypes.number,
}

LocalizacaoSVG.defaultProps = {
  cor: undefined,
  altura: 20,
  largura: 20,
}

export default LocalizacaoSVG

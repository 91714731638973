const COR_PRIMARIA = '#004070'
export const COR_FUNDO = '#FEFEFE'

export const YOUTUBE_FIEMG = 'https://www.youtube.com/user/SISTEMAFIEMG'
export const FACEBOOK_FIEMG = 'https://www.facebook.com/FIEMG'
export const INSTAGRAM_FIEMG = 'https://www.instagram.com/fiemgoficial'
export const TWITTER_FIEMG = 'https://twitter.com/FIEMGoficial'
export const LINKEDIN_FIEMG = 'https://www.linkedin.com/company/fiemg/'

export const TELEFONE_AJUDA = '4020-9030'
export const WHATSAPP_AJUDA = '553140427898'
export const TELEFONE_GERAL = '(31) 3263-4200'
export const TELEFONE_OUVIDORIA = '0800 8880123'
export const EMAIL_CONTATO = 'crc@fiemg.com.br'
export const LINK_PORTAL_TRANSPARENCIA_SESI = 'http://www.fiemg.com.br/transparenciasesi'
export const LINK_PORTAL_TRANSPARENCIA_SENAI = 'http://www.fiemg.com.br/transparenciasenai'
export const LINK_PORTAL_POLITICA_PRIVACIDADE = 'https://www7.fiemg.com.br/publicacoes-internas/politicaprivacidade'

export const LINK_MANUAL_TEXTOS_SOLUCOES = '/FIEMG_orientacoes_de_conteudo.pdf'

export const PREFIXO_EXCECAO = '[Exceção] Erro:'
export const NOME_SITE = 'FIEMG'

export const SITE_URL = process.env.REACT_APP_SITE_URL
export const API_PREFIX_URL = process.env.REACT_APP_API_PREFIX_URL
export const API_URL = process.env.REACT_APP_ENV === 'local' ? SITE_URL + API_PREFIX_URL : API_PREFIX_URL

export const ENDPOINT_MENU_AREAS = 'menuAreas'
export const ENDPOINT_BUSCAR_SOLUCOES = 'solucoes/buscar'
export const ENDPOINT_BUSCAR_SOLUCOES_CATEGORIA = 'solucoes/buscarPorCategoria'
export const ENDPOINT_BUSCAR_SOLUCOES_SLUG = 'solucoes/buscarPorSlug'
export const ENDPOINT_BUSCAR_SOLUCOES_TODAS = 'solucoes'
export const ENDPOINT_BUSCAR_ENDERECO = 'cep'
export const ENDPOINT_COTACAO_PESSOA_FISICA = 'cotacao/pessoaFisica'
export const ENDPOINT_COTACAO_EMPRESAS = 'cotacao/empresas'
export const ENDPOINT_SUGESTAO_SOLUCOES = 'solucoes/buscarSugestoes'
export const ENDPOINT_USUARIOS = 'usuarios'
export const ENDPOINT_DADOS_USUARIO = 'usuarios/obterUsuarioPorEmail'
export const ENDPOINT_USUARIOS_LOGIN = 'usuarios/login'
export const ENDPOINT_USUARIOS_LOGOUT = 'usuarios/logout'
export const ENDPOINT_USUARIOS_ALTERAR_SENHA = 'usuarios/alterarSenha'
export const ENDPOINT_USUARIOS_ALTERAR_EMAIL = 'usuarios/alterarEmail'
export const ENDPOINT_USUARIOS_REENVIAR_EMAIL = 'usuarios/reenviarEmail'
export const ENDPOINT_EMAIL_PENDENTE = 'usuarios/emailPendente'
export const ENDPOINT_HASH_VALIDAR_EMAIL = 'usuarios/hashValidarEmail'
export const ENDPOINT_NOTICIAS_ULTIMAS = 'noticias/ultimas'
export const ENDPOINT_NOTICIAS = 'noticias'
export const ENDPOINT_IMAGENS = 'imagens'
export const ENDPOINT_NOTICIAS_PAGINA = 'noticias/pagina'
export const ENDPOINT_NOTICIAS_AGENDADAS = 'noticias/agendadas'
export const ENDPOINT_NOTICIAS_TAGS = 'noticias/tags'
export const ENDPOINT_TEMA = 'temas'
export const ENDPOINT_BANNERS = 'banners'
export const ENDPOINT_BANNERS_AGENDADOS = 'banners/agendados'
export const ENDPOINT_COTACAO_SOLICITACAO_POR_EMAIL = 'cotacao/solicitacoesPorEmail'
export const ENDPOINT_COTACAO_CURSOS_POR_EMAIL = 'cotacao/cursosPorEmail'
export const ENDPOINT_CONTATOS = 'contatos'
export const ENDPOINT_DESTAQUES = 'destaques'
export const ENDPOINT_DESTAQUES_PF = 'destaquesPessoaFisica'
export const ENDPOINT_CATEGORIAS = 'categorias'
export const ENDPOINT_EMAIL_REDEFINIR_SENHA = 'usuarios/emailRedefinirSenha'
export const ENDPOINT_HASH_REDEFINIR_SENHA = 'usuarios/hashRedefinicaoSenha'
export const ENDPOINT_REDEFINIR_SENHA = 'usuarios/redefinirSenha'
export const ENDPOINT_NIVEIS_CAPACITACAO = 'niveisCapacitacao'
export const ENDPOINT_TODAS_SOLUCOES_PF = 'solucoesPessoaFisica/buscarTodos'
export const ENDPOINT_SOLUCOES_PF = 'solucoesPessoaFisica'
export const ENDPOINT_CIDADES_PF = 'solucoesPessoaFisica/cidades'
export const ENDPOINT_BUSCAR_SOLUCOES_PF = 'solucoesPessoaFisica/buscar'
export const ENDPOINT_SOLUCOES_PF_BUSCAR_POR_SLUG = 'solucoesPessoaFisica/buscarPorSlug'
export const ENDPOINT_CURSO_INDISPONIVEL = 'contatos/cursoDisponivel'
export const ENDPOINT_OBTER_UNIDADES = 'unidades'
export const ENDPOINT_OBTER_UNIDADES_POR_SLUG = 'unidades/buscarPorSlug'
export const ENDPOINT_OBTER_SOLUCOES_PF_POR_UNIDADE = 'solucoesPessoaFisica/buscarPorUnidade'
export const ENDPOINT_OBTER_SOLUCOES_PJ_POR_UNIDADE = 'solucoes/buscarPorUnidade'
export const ENDPOINT_EDITAR_UNIDADE = 'unidades'
export const ENDPOINT_SALVAR_SUGESTAO_BUSCA = '/sugestaoBusca/salvarSugestao'
export const ENDPOINT_CONTATO_CURSO_DISPONIVEL = 'contatos/cursoDisponivel'
export const ENDPOINT_OBTER_HISTORICO_PESQUISA = 'historicoPesquisa/buscar'
export const ENDPOINT_OBTER_SUGESTOES_DEMANDAS = 'sugestaoBusca/buscar'
export const ENDPOINT_OBTER_COTACOES_PJ = 'cotacao/empresas/buscar'
export const ENDPOINT_OBTER_COTACOES_PF = 'cotacao/pessoaFisica/buscar'
export const ENDPOINT_RESPONDER_SUGESTAO_DEMANDA = 'sugestaoBusca/responderUsuario'
export const ENDPOINT_SOLUCOES_COM_COTACAO_PJ = 'solucoes/buscarSolucoesPorCotacao'
export const ENDPOINT_EMPRESAS_COM_COTACAO_PJ = 'cotacao/empresas/buscarEmpresasComCotacao'
export const ENDPOINT_SOLUCOES_COM_COTACAO_PF = 'solucoesPessoaFisica/buscarSolucoesPFPorInscricao'
export const ENDOINT_ALUNOS_COM_COTACAO_PF = 'cotacao/pessoaFisica/buscarAlunosComInscricao'
export const ENDPOINT_SUGESTAO_SOLUCOES_PF = 'solucoesPessoaFisica/buscarSugestoes'
export const ENDPOINT_OBTER_TODAS_SOLUCOES = 'solucoes/buscarTodas'
export const ENDPOINT_OBTER_POR_EXIBIR_PORTAL = 'solucoes/buscarPorExibirPortal'
export const ENDPOINT_ALTERAR_EXIBIR_PORTAL = 'solucoes/alterarExibirPortal'
export const ENDPOINT_MODALIDADES = 'modalidades'
export const ENDPOINT_CARACTERISTICA_PRODUTOS = 'caracteristicasProduto'
export const ENDPOINT_ENTIDADES = 'entidades'
export const ENDPOINT_DESATIVAR_SOLUCAO = 'solucoes/desativarSolucao'

export const VALOR_RESOLUCAO_MAXIMA_MOBILE = 1023
export const RESOLUCAO_MAXIMA_MOBILE = `${VALOR_RESOLUCAO_MAXIMA_MOBILE}px`

export const CODIGO_EDUCACAO = 2
export const CODIGO_SAUDE = 4
export const CODIGO_INOVACAO = 6
export const CODIGO_GESTAO = 8
export const COR_EDUCACAO = '#5E2876'
export const COR_INOVACAO = '#E3662A'
export const COR_SAUDE = '#009B8C'
export const COR_GESTAO = '#0047B6'
export const COR_FUNDO_EDUCACAO = '#CCA5F0'
export const COR_FUNDO_INOVACAO = '#F6B47E'
export const COR_FUNDO_SAUDE = '#5AE1BB'
export const COR_GRADIENTE_EDUCACAO = 'linear-gradient(90deg, #5E2876 43.75%, #D898F4 109.09%);'
export const COR_GRADIENTE_INOVACAO = 'linear-gradient(90deg, #E3662A 48.86%, #F4AF8D 109.09%);'
export const COR_GRADIENTE_SAUDE = 'linear-gradient(90deg, #009B8C 43.75%, #10E9D4 109.09%);'
export const COR_GRADIENTE_GESTAO = 'linear-gradient(90deg, #0047B6 6.96%, #5187DB 78.34%);'
export const COR_GRADIENTE_MOBILE_EDUCACAO = 'linear-gradient(77.29deg, #5E2876 22.15%, #955AAF 67.19%, #D898F4 100%);'
export const COR_GRADIENTE_MOBILE_INOVACAO = 'linear-gradient(77.53deg, #E3662A 40.77%, #F4AF8D 101.66%);'
export const COR_GRADIENTE_MOBILE_SAUDE = 'linear-gradient(77.29deg, #009B8C 36.69%, #07BEAC 74.88%, #10E9D4 100%);;'
export const COR_GRADIENTE_MOBILE_GESTAO = 'linear-gradient(77.53deg, #0047B6 31.11%, #5187DB 101.66%);'
export const COR_PRIMARIA_EDUCACAO_PROFISSIONAL = '#9053AA'
export const COR_GRADIENTE_EDUCACAO_PROFISSIONAL = 'linear-gradient(90deg, #9053AA 0%, #C394D7 100%);'

export const LINK_API_RECAPTCHA = 'https://www.google.com/recaptcha/api.js'
export const SITE_KEY_RECAPTCHA = '6LcVRAoaAAAAAOvvPU_ESPyZmk8WH-ys29Eeo91E'

export const COOKIE_DADOS = 'npData'

export default COR_PRIMARIA

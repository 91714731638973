import React from 'react'
import BotaoEstilizado, {
  DivBotaoCifrao, DivCifrao, DivCheck, DivProximo, DivAnterior, DivBotaoPersonalizado,
  DivBotaoIconeDireita, DivDocumento, DivBotaoDocumento, DivDocumentoMobile,
} from '../UtilsEstilo'
import { valueButton, propsButton } from '../UtilsPropTypes'

const Botao = ({
  nome, action, id, tipo, inverse, esconder, altura, largura, cifrao, desabilitar, check, proximo,
  voltar, personalizado, icone, senha, empresa, iconeFim, cor, corHover, documento, documentoMobile,
  textoMinusculo, sombra,
}) => {
  if (personalizado) {
    return (
      <BotaoEstilizado
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        personalizado={personalizado}
        sombra={sombra}
      >
        <DivBotaoPersonalizado>
          <img src={icone} alt={nome} />
          <p>{nome}</p>
        </DivBotaoPersonalizado>
      </BotaoEstilizado>
    )
  }
  if (iconeFim) {
    return (
      <BotaoEstilizado
        senha={senha}
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        sombra={sombra}
      >
        <DivBotaoIconeDireita>
          <p>{nome}</p>
          <img src={iconeFim} alt={nome} width="20px" height="20px" />
        </DivBotaoIconeDireita>
      </BotaoEstilizado>
    )
  }
  if (cifrao) {
    return (
      <BotaoEstilizado
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        sombra
      >
        <DivBotaoCifrao>
          {nome}
          <DivCifrao>$</DivCifrao>
        </DivBotaoCifrao>
      </BotaoEstilizado>
    )
  }
  if (documento) {
    return (
      <BotaoEstilizado
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        sombra
      >
        <DivBotaoDocumento>
          {nome}
          <DivDocumento>
            <img src="/images/documentoPessoaFisicaBranco.svg" alt="documento" />
          </DivDocumento>
        </DivBotaoDocumento>
      </BotaoEstilizado>
    )
  }
  if (documentoMobile) {
    return (
      <BotaoEstilizado
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        sombra
      >
        <DivBotaoDocumento>
          {nome}
          <DivDocumentoMobile>
            <img src="/images/documentoPessoaFisicaBranco.svg" alt="documento" />
          </DivDocumentoMobile>
        </DivBotaoDocumento>
      </BotaoEstilizado>
    )
  }
  if (check) {
    return (
      <BotaoEstilizado
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        cor={cor}
        corHover={corHover}
        sombra={sombra}
      >
        <DivBotaoCifrao>
          {nome}
          <DivCheck senha={senha}>
            <img src={desabilitar ? '/images/confirmaPreto.svg' : '/images/confirma.svg'} alt={nome} />
          </DivCheck>
        </DivBotaoCifrao>
      </BotaoEstilizado>
    )
  }
  if (proximo) {
    return (
      <BotaoEstilizado
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        sombra={sombra}
      >
        <DivBotaoCifrao>
          {nome}
          <DivProximo>
            <img width="14px" height="12px" src={desabilitar ? '/images/setaProximoPreta.svg' : '/images/setaProximo.svg'} alt={nome} />
          </DivProximo>
        </DivBotaoCifrao>
      </BotaoEstilizado>
    )
  }
  if (voltar) {
    return (
      <BotaoEstilizado
        senha={senha}
        inverse={inverse ? 1 : 0}
        disabled={desabilitar}
        hidden={esconder}
        type={tipo}
        id={id}
        tabIndex="0"
        onClick={action}
        altura={altura}
        largura={largura}
        sombra={sombra}
      >
        <DivBotaoCifrao>
          <DivAnterior>
            <img src="/images/setaVoltar.svg" alt={nome} width="14px" height="12px" />
          </DivAnterior>
          {nome}
        </DivBotaoCifrao>
      </BotaoEstilizado>
    )
  }
  return (
    <BotaoEstilizado
      empresa={empresa}
      inverse={inverse ? 1 : 0}
      hidden={esconder}
      disabled={desabilitar}
      type={tipo}
      id={id}
      tabIndex="0"
      onClick={action}
      altura={altura}
      largura={largura}
      senha={senha}
      textoMinusculo={textoMinusculo}
      cor={cor}
      corHover={corHover}
      sombra={sombra}
    >
      {nome}
    </BotaoEstilizado>
  )
}
Botao.propTypes = propsButton
Botao.defaultProps = valueButton

export default Botao

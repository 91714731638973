import { memo } from 'react'
import Ajuda from './views/ContatosAjuda'
import ContatosRedesSociais from './views/ContatosRedesSociais'
import ContatosFuncionamento from './views/ContatosFuncionamento'
import ContatosPoliticaPrivacidade from './views/ContatosPoliticaPrivacidade'

export const RedesSociais = memo(ContatosRedesSociais)
export const Funcionamento = memo(ContatosFuncionamento)
export const PoliticaPrivacidadeModal = memo(ContatosPoliticaPrivacidade)

export default memo(Ajuda)

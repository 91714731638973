const PtBr = {
  MEUS_DADOS_CADASTRO: 'Meus dados de Cadastro',
  USUARIO_EMAIL_RESPONSAVEL: 'Usuário / Email do Responsável',
  USUARIO_EMAIL: 'Usuário / Email',
  LOGIN: 'Login',
  DADOS_RESPONSAVEL: 'Dados do Responsável',
  DADOS_EMPRESA: 'Dados da Empresa',
  SALVAR_ALTERACOES: 'Salvar Alterações',
  NAO_SALVAR: 'Não salvar',
  SALVO_COM_SUCESSO: 'Alterações salvas com sucesso!',
  ALTERAR_SENHA: 'Alterar Senha',
  SENHA_ATUAL: 'Senha atual',
  NOVA_SENHA: 'Nova Senha',
  CONFIRMAR_NOVA_SENHA: 'Confirmar Nova Senha',
  CANCELAR: 'Cancelar',
  SAIR_SEM_SALVAR: 'Sair sem salvar',
  CONFIRMAR_SAIR_SEM_SALVAR: 'Deseja sair sem salvar alterações?',
  ERRO_SENHA_NOVA_ANTIGA: 'A senha nova informada é igual a senha antiga.',
  ERRO_SENHA_COMFIRMACAO: 'As senhas novas não são iguais',
  ALTERAR_EMAIL: 'Alterar Email',
  EMAIL_ATUAL: 'Email atual',
  VERIFIQUE: 'Verifique seu email',
  PARA_FINALIZAR: 'Para finalizar a alteração, confirme o email que mandamos para ',
  AGUARDANDO_CONFIRMACAO: 'Aguardando confirmação. Clique para reenviar.',
  REENVIAR_EMAIL: 'Reenviar email',
  EMAIL_NAO_CHEGOU: 'O email não chegou?',
  REENVIAR_EM: 'Reenviar em ',
  REENVIADO_SUCESSO: 'Email reenviado com sucesso!',
  TITULO_PAGINA: 'FIEMG - Meus Dados de Cadastro',
  DESCRICAO_PAGINA: 'Altere seus dados do cadastro da FIEMG',
}

export default PtBr

const PtBr = {
  OLA: 'Olá!',
  QUAL_CADASTRO: 'Qual tipo de cadastro deseja fazer?',
  PARA_VOCE: 'Para Você',
  PARA_EMPRESA: 'Para sua Empresa',
  CADASTRO: 'Já possui cadastro?',
  ENTRAR: 'Entrar',
  CADASTRAR: 'Fazer Cadastro',
  FACA_CADASTRO: 'Faça seu cadastro',
  CADASTRO_EMPRESA: 'Cadastre sua Empresa',
  PREENCHA: 'Preencha seus dados',
  PREENCHA_EMPRESA: 'Preencha dados da empresa',
  USUARIO_SENHA: 'Usuário e senha',
  CONCLUIDO: 'Concluído',
  OK: 'OK',
  VERIFIQUE_EMAIL: 'Verifique seu email',
  AGUARDE: 'Aguarde...',
  ANTERIOR: 'Anterior',
  PROXIMO: 'Próximo',
  FINALIZAR: 'Finalizar',
  FAZER_LOGIN: 'Fazer Login',
  LISTRAS: 'listras',
  INDUSTRIA: 'industria',
  OLA_USUARIO: 'Olá, ',
  SAIR: 'Sair',
  COMPLETE_SEU: 'Complete seu ',
  CADASTRO_NA: 'Cadastro ',
  NA_FIEMG: 'na FIEMG',
  ACOMPANHE: 'E acompanhe todas as soluções que você solicitou com a gente.',
  VER_MINHAS_SOLICITACOES: 'Ver minhas solicitações',
  ENTRE_NO: 'Entre no ',
  MINHAS_SOLICITACOES: 'Minhas Solicitações',
  ACOMPANHE_MINHAS_SOLICITACOES: 'E acompanhe todas as soluções que você solicitou com a gente.',
  TERMOS_DE_ACEITE: 'Termos de Aceite',
  PROTECAO_DE_DADOS: 'Proteção de Dados',
  MEUS_DADOS_CADASTRO: 'Meus dados de cadastro',
  PARA_COMECAR: 'Para começar a usar sua conta, confirme o email que mandamos para ',
  EMAIL_CONFIRMADO: 'Email confirmado',
  VER_MEUS_CURSOS: 'Ver meus cursos',
  PARA_VOCE_EM_BREVE: 'Para Você (Em Breve)',
  CADASTRO_SOLUCOES: 'Cadastro de Soluções',
}

export default PtBr

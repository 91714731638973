import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RESOLUCAO_MAXIMA_MOBILE } from '../utils/Configuracoes'
import { LinkWhatsApp } from '../utils/UtilsEstilo'

const TituloAjudaDiv = styled.h1`
  margin: 0;
  padding: 22px 25px 16px 25px;
  color: ${(props) => (props.footer ? '#FEFEFE' : '#2D2F32')};
  font-size: ${(props) => (props.footer ? '18px' : '16px')};
  line-height: 20px;
  text-align: center;
  font-weight: ${(props) => (props.footer ? 'bold' : 'normal')};

  img {
    margin-right: 8px;
  }
`

export const TextoAjudaDiv = styled.span`
  margin: 0;
  font-weight: 500;
  display: flex;
  padding-right: ${(props) => (props.menu ? '32px' : '24px')};
  padding-left: ${(props) => (props.menu ? '0px' : '24px')};
  padding-bottom: 16px;
  color: ${(props) => (props.footer ? '#FEFEFE' : '#999CA1')};
  font-size: 14px;
  line-height: 19px;
  text-align: ${(props) => (props.menu ? 'left' : 'center')}; 
`
export const DivBotao = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  img {
    position: relative;
    top: 1px;
  }
`

export const DivLinks = styled.div`
  display: flex;
  padding-left: 34px;
  height: 32px;
  align-items: center;

  p {
    margin-top: 2px;
    margin-bottom: 2px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #FEFEFE;
  }
`

export const Imagem = styled.div`
   display: flex;
   margin-left: -8px;
  svg {
    padding-left: 28px;
    padding-top: 6px;
  }
`

export const AnchorTelefone = styled.a`
  outline-offset: -2px;
`

export const LinkTelefone = styled(LinkWhatsApp)`
  background-color: ${(props) => (props.footer ? '#FEFEFE' : '#005CAF')};
  color: ${(props) => (props.footer ? '#005CAF' : '#FEFEFE')};
  margin-top: 16px;
  margin-right: 0;
  /* width: 202px; */
  &:hover{
    background-color: ${(props) => (props.footer ? '#C8EEFB' : '#348DCF')};
  }
`

export const DivPortalTransparencia = styled.div`
  width: ${(props) => (props.menu ? '208px' : '224px')};
  height: 32px;
  border-radius: 8px;
  border: 1px solid #FEFEFE;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-top: 16px;
  color: '#FEFEFE';
  margin-right: ${(props) => (props.menu ? '16px' : '0px')};
  &:hover{
    cursor: pointer;
  }
  svg { 
    position: relative;
    left: -12%;
    top: 2px;
  }
  
`

export const ImgAjuda = styled.img`
  position: relative;
  left: -15%;
  top: 3px;
  width: 20px;
  height: 20px;
`

export const GridBotoes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-bottom: ${(props) => (props.footer ? '62px' : 'unset')};
  }
`

export const GridImg = styled.div`
  display: flex;
  justify-content: center;
`

export const ImgRedesSociais = styled.div`
  padding-left: 90px;
`

export const TituloRedesSociais = styled.p`
  color: #FEFEFE;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-top: 20px;
`

export const FuncionamentoDiv = styled.div`
  border-left: 1px solid rgba(254,254,254,0.3);
  padding-left: 36px;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    border-left: none;
    border-top: 1px solid rgba(254,254,254,0.3);
    padding: 0 16px;
  }
`

export const RedesGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  
  div {
    flex: initial;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    justify-content: center;
  }
`

export const IconeRedeSocial = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: rgba(254,254,254,0.1);
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    background-color: ${(props) => (props.cor)};
    cursor: pointer;
  }

  @media (max-width: 350px) {
    margin-right: 10px;
  }
`

export const ImgRedeSocial = styled.img`
  position: relative;
  top:  ${(props) => (props.top)};
  left:  ${(props) => (props.left)};
`

export const DivGeral = styled.a`
  color: #FEFEFE;
  font-size: 16px;
  line-height: 20px;
  margin-top: 41px;
  display: flex;
  justify-content: flex-start;
  text-decoration: underline !important;

  img {
    margin-right: 8px;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-left: 36px
  }
`

export const DivOuvidoria = styled(DivGeral)`
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none !important;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-left: 36px
  }
`

export const RedesSociaisDiv = styled.div`
  padding-left: 100px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-left: 0px;
  }
  @media (max-width: 350px) {
    width: 100%;
  }
`

export const CentralRelacionamentoDiv = styled.div`
  color: #FEFEFE;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin-right: 8px;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-top: 32px;
  }
`

export const EmailContatoGrid = styled(CentralRelacionamentoDiv)`
  margin-top: 22px;
`

export const HorarioFuncionamentoDiv = styled(CentralRelacionamentoDiv)`
  margin-top: 26px;
  line-height: 24px;
`

export const PoliticaPrivacidadeTela = styled.div`
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: ${(props) => (props.aberto ? '100vw' : '0px')};
  height: ${(props) => (props.aberto ? '100vh' : '0px')};
  top: 0;
  left: 0;
  display: flex;
`

export const PoliticaPrivacidade = styled.div`
  height: 560px;
  position: fixed;
  width: ${(props) => (props.aberto ? '500px' : '0px')};
  z-index: 3; 
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 0 2px 54px 0 #BDC1C7;
  overflow: auto;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    bottom: 0;
    left: 0;
    width: 100vw;   
    box-shadow: 0 0 11px 0 #CFD3D8;
    border-radius: 8px 8px 0 0;
    height: ${(props) => (props.aberto ? 'calc(100% - 60px)' : '0px')};
    visibility: ${(props) => (props.aberto ? 'visible' : 'hidden')};
  }
`

export const DivPrivacidadeTitulo = styled.div`
  display: ${(props) => (props.aberto ? 'flex' : 'none')};
  padding: 12px 10px 8px 16px;
  border-bottom: 2px solid #EAEEF4;
  justify-content: space-between;
  color: #348DCF;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`

export const DivPrivacidadeSubTitulo = styled.div`
  color: #2D2F32;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 24px 8px 16px;
  display: ${(props) => (props.aberto ? 'visible' : 'none')};
`

export const DivPrivacidadeTexto = styled.div`
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  padding: 0px 24px 16px 16px;
  display: ${(props) => (props.aberto ? 'visible' : 'none')};

  p {
    margin: 12px 0px;
  }
`

export const BotaoFechar = styled.button`
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: #FEFEFE;
  border: none;
  outline: none;
  &:hover{
    cursor: pointer;
  }
`

export const TituloRedesMobileDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 8px;
  }
`

export const DivBalao = styled.div`  
  z-index: 2;
  display: flex;
`

export const BalaoPortalTransparencia = styled.div`
  z-index: 3;
  display: ${(props) => (props.aberto ? 'default' : 'none')};
  width: 84px !important;
  height:  52px !important;
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 0 0 10px 0 rgba(69,103,140,0.22);
  padding: 5px 0;
  z-index: 3;
  margin-top: -8px;
  margin-left: 178px;
  padding-right: 10px;
  padding-left: 10px;

  a:link, a:visited {
    text-decoration: none
  }

  p {
    padding: 0px;
    margin: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #2D2F32;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    right: 16px;
  }
`

export const FaleConoscoLink = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  color: #FEFEFE;
  text-decoration: underline !important;
`

export const DivIcone = styled.div`
  margin-right: 8px;
  flex: 0 !important;
`

export const DivFaleConosco = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-bottom: 32px;
    padding-left: 36px
  }
`

export default TituloAjudaDiv

import Axios from 'axios'
import { Cookies } from 'react-cookie'
import { API_URL } from './Configuracoes'
import { dispararErro, removerToken } from './Funcoes'
import { exibirAlerta } from './FilaDeMensagens'

export function realizarChamada(metodo, path, body) {
  if (!path) {
    return Promise.reject(dispararErro('É necessário fornecer um valor de "path" válido para o método Conecta.chamada.'))
  }

  const cookie = new Cookies()
  const options = {
    headers: { 'XSRF-TOKEN': cookie.get('XSRF-TOKEN') },
  }

  let resposta
  const url = API_URL + path
  switch (metodo) {
    case 'GET':
      resposta = Axios.get(url, options)
      break
    case 'POST':
      resposta = Axios.post(url, body, options)
      break
    case 'PUT':
      resposta = Axios.put(url, body, options)
      break
    case 'DELETE':
      resposta = Axios.delete(url, options)
      break
    default:
      resposta = Promise.reject(dispararErro('Método HTTP usado para conectar a API é inválido.'))
  }

  return resposta
}

export async function tratarChamada(metodo, path, body) {
  const resposta = realizarChamada(metodo, path, body)

  return resposta.catch((err) => {
    if (err.response) {
      const { data } = err.response
      if (data.codigo !== 0) {
        if (data.mensagem === 'Acesso negado' || data.message === 'Acesso inválido.'
        || data.mensagem === 'Email não cadastrado.'
        || (data.message === '' && data.error === 'Internal Server Error' && data.status === 500)) {
          removerToken()
          exibirAlerta({ text: 'utils.respostaServidor.ACESSO_NEGADO', type: 'error', translate: true })
        } else {
          exibirAlerta({ text: `utils.respostaServidor.${data.mensagem}`, type: 'error', translate: true })
        }
      }
      console.log(data)
      console.log(err.response.status)
      console.log(err.response.headers)
    } else if (err.request) {
      exibirAlerta({ text: 'utils.respostaServidor.ERRO_ACESSO_BANCO', type: 'error', translate: true })
      console.log(err.request)
    } else {
      console.log('Error', err.message)
    }
    console.log(err.config)
    return err
  })
}

export default async function ConectarApiPost(path, body) {
  return tratarChamada('POST', path, body)
}

export async function ConectarApiGet(path) {
  return tratarChamada('GET', path)
}

export async function ConectarApiPut(path, body) {
  return tratarChamada('PUT', path, body)
}

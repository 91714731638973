const PtBr = {
  MINHAS_SOLICITACOES: 'Minhas Solicitações',
  SOLICITADO_EM: 'Solicitado em ',
  AS: ' às ',
  SOLICITACAO_VAZIO: 'Nenhuma solicitação aqui',
  SOLICITACAO_VAZIO_MENSAGEM: 'Quando você solicitar uma solução FIEMG, elas vão aparecer aqui.',
  TEXTO_EXPLICATIVO: 'Aqui estão todas as soluções que você solicitou na FIEMG, aguarde nosso contato.',
  SOLUCAO_INDISPONIVEL: 'Solução Indisponível',
  FALE_CONOSCO: 'Fale Conosco',
  TITLE_PAGINA: 'FIEMG - Minhas Solicitações',
  DESCRICAO_PAGINA: 'Solicitações realizadas pelo usuário',
}

export default PtBr

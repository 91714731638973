import React from 'react'
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import {
  PoliticaPrivacidade, PoliticaPrivacidadeTela, DivPrivacidadeTitulo, DivPrivacidadeSubTitulo,
  BotaoFechar, DivPrivacidadeTexto,
} from '../ContatosEstilo'
import { LightBox } from '../../utils/UtilsEstilo'

const ContatosPoliticaPrivacidade = ({ aberto, abrir }) => {
  const { t } = useTranslation()
  return (
    <PoliticaPrivacidadeTela aberto={aberto}>
      <PoliticaPrivacidade aberto={aberto}>
        <DivPrivacidadeTitulo aberto={aberto}>
          {t('contatos.POLITICA')}
          <BotaoFechar type="button" onClick={() => abrir(false)}>
            <img src="/images/fechar.svg" alt={t('utils.VOLTAR')} width="14px" height="14px" />
          </BotaoFechar>
        </DivPrivacidadeTitulo>
        <DivPrivacidadeSubTitulo aberto={aberto}>
          {t('contatos.SUBTITULO_POLITICA')}
        </DivPrivacidadeSubTitulo>
        <DivPrivacidadeTexto aberto={aberto}>
          {t('contatos.TEXTO_POLITICA').split('\n').map((paragrafo) => (
            <p key={Math.random()}>
              {paragrafo}
            </p>
          ))}
          <p />
        </DivPrivacidadeTexto>
      </PoliticaPrivacidade>
      {aberto ? <LightBox onClick={() => abrir(false)} /> : null}
    </PoliticaPrivacidadeTela>
  )
}

ContatosPoliticaPrivacidade.propTypes = {
  aberto: propTypes.bool,
  abrir: propTypes.func,
}
ContatosPoliticaPrivacidade.defaultProps = {
  aberto: false,
  abrir: undefined,
}

export default ContatosPoliticaPrivacidade

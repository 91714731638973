import propTypes from 'prop-types'

const propsProvider = {
  children: propTypes.any,
}

export const valueProvider = {

}

export const propsHeader = {
  titulo: propTypes.string,
  headerTransparente: propTypes.bool,
}

export const valueHeader = {
  headerTransparente: false,
}

export const propsMenu = {
  aberto: propTypes.bool,
  abrir: propTypes.func,
}

export const valueMenu = {

}

export const propsPadrao = {
  titulo: propTypes.string,
  children: propTypes.object,
}

export const valuePadrao = {

}

export default propsProvider

const PtBr = {
  ESQUECEU_SENHA_PERGUNTA: 'Esqueceu sua senha?',
  INSIRA_EMAIL_TEXTO: 'Insira o e-mail que você usou para se cadastrar e enviaremos instruções de como redefinir sua senha.',
  ENVIAR: 'Enviar',
  LEMBREI: 'Ah! Lembrei',
  EMAIL_ENVIADO: 'E-mail Enviado',
  TEXTO_EMAIL_ENVIADO_PRIMEIRA: 'Vá no seu email e siga as instruções para redefinir sua senha. E atenção, o email só será enviado caso ele esteja cadastrado nos seus dados de usuário.',
  TEXTO_EMAIL_ENVIADO: 'Já foi enviado um email para redefinição de senha, mas podemos reenviar novamente dentro de instantes.',
  OK_ENTENDI: 'Ok, entendi',
  REDEFINIR_SENHA: 'Redefinir Senha',
  INSIRA_NOVA_SENHA: 'Insira abaixo sua nova senha',
  SALVAR_NOVA_SENHA: 'Salvar nova senha',
  NOVA_SENHA_CRIADA: 'Nova senha criada com sucesso!',
  AGORA_ENTRE: 'Agora entre no seu perfil com a sua nova senha.',
  ENTRAR: 'Entrar',
  TENTATIVA_EXPIROU: 'Sua tentativa expirou',
  CLIQUE: 'Clique no botão abaixo para tentar resetar sua senha novamente.',
  REENVIAR_EM: 'Reenviar em ',
  REENVIAR_EMAIL: 'Reenviar email',
  TITLE_PAGINA: 'FIEMG - Redefinição de Senha',
  DESCRICAO_PAGINA: 'Redefina sua senha da FIEMG',
}

export default PtBr

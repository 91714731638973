import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FuncionamentoDiv, CentralRelacionamentoDiv, EmailContatoGrid, HorarioFuncionamentoDiv,
} from '../ContatosEstilo'
import { TELEFONE_AJUDA, EMAIL_CONTATO } from '../../utils/Configuracoes'

const ContatosFuncionamento = () => {
  const { t } = useTranslation()
  return (
    <FuncionamentoDiv>
      <CentralRelacionamentoDiv>
        <img src="/images/icone-telefone-branco-fiemg.svg" alt={t('contatos.ALT_IMG_TELEFONE')} width="20px" height="20px" />
        {t('contatos.CENTRAL')}
        {TELEFONE_AJUDA}
      </CentralRelacionamentoDiv>
      <EmailContatoGrid>
        <img src="/images/icone-email-fiemg.svg" alt={t('utils.ALT_IMG_EMAIL')} width="18px" height="14px" />
        {EMAIL_CONTATO}
      </EmailContatoGrid>
      <HorarioFuncionamentoDiv>
        <img src="/images/icone-relogio-fiemg.svg" alt={t('utils.ALT_IMG_RELOGIO')} width="18px" height="18px" />
        {t('contatos.HORARIO')}
        <br />
        {t('contatos.FUNCIONAMENTO')}
        <br />
        {t('contatos.EXCETO')}
      </HorarioFuncionamentoDiv>
      <EmailContatoGrid>
        <img src="/images/icone-localizacao-fiemg.svg" alt={t('contatos.ALT_IMG_LOCALIZACAO')} width="16px" height="18px" />
        {t('contatos.ENDERECO')}
      </EmailContatoGrid>
    </FuncionamentoDiv>
  )
}

export default ContatosFuncionamento

const PtBr = {
  LABEL_TITULO: 'Erro, não encontrado.',
  LABEL_MENSAGEM: 'A página pela qual você procura não existe. Por favor, insira uma informação válida e tente novamente.',
  AJUDA: 'ajuda',
  DUVIDA: 'Você tem alguma dúvida?',
  POLITICA: 'POLÍTICA DE PRIVACIDADE',
  USUARIO: 'usuario',
  CARREGANDO: 'Carregando...',
  ALT_IMAGEM_FIEMG: 'FIEMG - Federacao das Industrias do estado de Minas Gerais',
  ALT_IMAGEM_SESI: 'Logo representativa da FIEMG - Federacao das Industrias do estado de Minas Gerais, para o SESI',
  ALT_IMAGEM_SENAI: 'Logo representativa da FIEMG - Federacao das Industrias do estado de Minas Gerais, para o SENAI',
  ALT_IMAGEM_IEL: 'Logo representativa da FIEMG - Federacao das Industrias do estado de Minas Gerais, para o IEL',
  JA_POSSUI: 'Já possui ',
  CADASTRO: 'cadastro',
  INTERROGACAO: '?',
}

export default PtBr

import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Slider from '@mui/material/Slider'
import { Link } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import {
  AppBar, Checkbox, Tab, TableCell, Tabs, TableRow, Tooltip, Switch,
} from '@mui/material'
import { RESOLUCAO_MAXIMA_MOBILE } from './Configuracoes'

const BotaoEstilizado = styled.button`
  font-size:  ${(props) => (props.empresa ? '24px' : '16px')};
  font-weight: 600;
  color: ${(props) => (props.inverse ? '#005CAF' : '#FEFEFE')};
  background-color: ${(props) => (props.inverse ? '#FEFEFE' : props.empresa ? '#53b0d7' : props.cor ? props.cor : '#005CAF')};
  text-transform: ${(props) => (props.textoMinusculo ? 'none' : 'capitalize')};
  width: ${(props) => (props.largura ? props.largura : '96px')};
  height: ${(props) => (props.altura ? props.altura : 'unset')};
  margin: ${(props) => (props.senha ? '0' : '0px 0px 0px 15px')};
  border-radius: ${(props) => (props.empresa ? '0 7px 7px 0' : '8px')};
  border:  ${(props) => (props.inverse ? '1px solid  #005CAF' : 'none')};
  box-shadow: ${(props) => (props.sombra && 'rgb(69 103 140 / 63%) 0px 0px 10px')};
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.inverse ? '#C8EEFB' : props.empresa ? '#009cdd' : props.corHover ? props.corHover : '#348DCF')};
  }

  &:active {
    background-color: ${(props) => (props.inverse ? '#FEFEFE' : props.empresa ? '#CF5D26' : props.cor ? props.cor : '#004796')};
    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      background-color: ${(props) => (props.inverse ? '#FEFEFE' : props.empresa ? '#CF5D26' : '#004796')};
    }
  }

  &:disabled {
    background-color: ${(props) => (props.personalizado ? '#FEFEFE' : '#CFD3D8')};
    color:  ${(props) => (props.personalizado ? '#BDC1C7' : '#636669')};
    border: ${(props) => (props.personalizado ? '1px solid #BDC1C7' : 'none')};
    cursor: default;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: ${(props) => (props.largura ? props.largura : '96px')};
    height: ${(props) => (props.altura ? props.altura : 'unset')};
  }

  @supports (-webkit-touch-callout: none) {
    padding: 0 4px;
  }

  @media (max-width: 300px) {
    font-size: 14px;
  }
`

export const DivBotaoCifrao = styled.div`
  display: flex;
  justify-content: center;
`

export const DivBotaoDocumento = styled.div`
  display: flex;
  justify-content: center;
`

export const DivCifrao = styled.div`
  position: absolute;
  right: 16px;
`

export const DivDocumento = styled.div`
  position: absolute;
  right: 20px;
  top: 12px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    right: 24px;
    top: 22px
  }  
`

export const DivDocumentoMobile = styled.div`
  position: absolute;
  right: 23px;
  bottom: 18px;
`

export const DivCheck = styled.div`
  position: relative;
  right: ${(props) => (props.senha ? '-15%' : '-35%')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    right: -16px;
  }  
`

export const DivProximo = styled.div`
  position: relative;
  right: -24px;
`

export const DivAnterior = styled.div`
  position: relative;
  left: -24px;
`

export const CardContainer = styled.div`
  a:link, a:visited {
    text-decoration: none
  }
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100vw;
  }
  box-shadow: 0 0 4px 0 #CFD3D8;
  background-color: #FEFEFE;
  height: 100%;

  > div {
    padding: 31px 25px;
  }
`

export const Paper = styled(CardContainer)`
  
  > div {
      padding: unset;
  }
`

export const CardContainerCotacao = styled(Paper)`
  box-shadow: none;
`

export const PaperSucesso = styled(Paper)` 
  box-shadow: none;

  > div {
    padding: 16px 16px 0 16px;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-bottom: unset;
  }
`

export const PaperSimples = styled.div`
  a:link, a:visited {
    text-decoration: none
  }

  display: flex;
  justify-content: center;
  height: 100%;
  box-shadow: 0px 0px 2px rgba(40, 67, 91, 0.45);
  background-color: #FEFEFE;
  width: 100vw;
  flex: auto;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
    padding: 0;
    display: unset;
  }
`

export const CardSimples = styled(CardContainer)`
  margin-right: 16px;
  box-shadow: 0px 0px 5px rgba(40, 67, 91, 0.35);
  border-radius: 8px;
  height: 70px;
  width: 260px;
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.margemDireita)};

  > div {
    padding: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
    max-width: 382px;
    margin-right: 0;
    height: 50px;
  }
`

export const Card = styled(CardContainer)`
  a:link, a:visited {
    text-decoration: none
  }
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: unset;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  box-shadow: unset;
  transition: 0.3s;
  border: 1px solid #EAEEF4;
  box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 90% 1px 10%;
  grid-template-columns: 90% 10%;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: "texto icone";

  > div {
    padding: 8px;
    text-align: left;
  }

  &:hover {
    box-shadow: 0 0 11px 0 #CFD3D8;
    cursor: pointer;
  }
`

export const Titulo = styled.h4`
  margin: 0px;
  font-weight: 600;
  color: #348DCF;
  font-size: 18px;
`

export const Texto = styled.p`
  margin: 0px;
  color: #999CA1;
  font-size: 14px;
`
export const CardTexto = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 1; 
  grid-row: 1; 
  grid-column: 1; 
  grid-area: texto; 
`

export const CardIcone = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 3; 
  grid-row: 1; 
  grid-column: 3; 
  grid-area: icone; 
  align-self: center;
`

export const CaixaTexto = styled.input`
  font-size: 16px;
  width: ${(props) => (props.cidade ? '184px' : '258px')};
  outline: none;
  height: 40px;
  border: none;
  margin-left: 8px;
  padding-right: 16px;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: calc(100vw - 90px);
    margin-left: 0px;
    padding-right: 0;
  }

  ::-webkit-search-cancel-button {
    display: none;
  }

  ::placeholder {
    color: #999CA1;
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: #999CA1;
  }

  &:disabled {
    opacity: 1;
    color: #90949A;
    -webkit-text-fill-color: #90949A;
  }
`

export const CaixaTextoBusca = styled(CaixaTexto)`
  margin-left: 16px;
  width: ${(props) => (props.empresa ? '415px' : '316px')};
  height: 68px;
  font-size: 22px;
  line-height: 22px;
  color: #2D2F32;

  ::placeholder {
    color: #999CA1;
    opacity: 1;
  }

  ::-webkit-search-cancel-button {
    display: none;
  }

  :-ms-input-placeholder {
    color: #999CA1;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-left: 8px;
    height: 40px;
    font-size: 16px;
    line-height: 14px;
    width: calc(100vw - 100px);
  }
`

export const Busca = styled.div`
  width: 304px;
  border-radius: 8px;
  border: 1px solid #CFD3D8;
  background-color: white;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${(props) => (props.cotacao ? 'calc(100vw - 46px)' : '100vw')};
    padding-left: 8px;
    padding-right: 16px;
    margin-left: 0px;
  }

  ${this}:focus-within {
    border-color: #348DCF;
  }
`

export const BuscaEmpresa = styled(Busca)`
  background: #FFFFFF;
  width: 630px;
  box-shadow: 0px 4px 23px 2px rgba(0, 52, 110, 0.25);
  border: none;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    box-shadow: 0px 0px 16px 2px rgba(0, 52, 110, 0.25);
    width: calc(100vw - 32px);
    padding-right: 30px;
  }
`

export const BuscaVoce = styled(Busca)`
  background: #FFFFFF;
  width: 899px;
  box-shadow: 0px 4px 23px 2px rgba(0, 52, 110, 0.25);
  border: none;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    box-shadow: 0px 0px 16px 2px rgba(0, 52, 110, 0.25);
    width: calc(100vw - 32px);
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 30px;
  }
`

export const BuscaNiveisCapacitacao = styled(Busca)`
  display: flex;
  align-items: center;
  width: 536px;
`

export const ImgBusca = styled.img`
  border: none;
  height: 16px;
  width: 16px;
  top: 4px;
  position: relative;
  left: ${(props) => (props.campoBusca ? '0px' : '8px')};
  pointer-events: none;
`

export const ImgBuscaEmpresa = styled(ImgBusca)`
  top: 10px;
  height: 32px;
  width: 32px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    height: 16px;
    width: 16px;
    top: 4px;
  }
`

export const ImgBuscaNiveisCapacitacao = styled.img`
  padding-left: 8px;
  height: 24px;
  width: 24px;
`

export const DivTooltipSenha = styled.div`
  display: inline;
  position: relative;
  left: -8px;
  top: -10px;
`

export const BotaoImagem = styled.span`
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 4px;
  position: relative;
  top: ${(props) => (props.senha ? '-6px' : '0')};
  left: ${(props) => (props.ajustar ? '-12px' : '8px')};

  &:hover{
    cursor: pointer;
  }
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-left: 0px;
  }
`

export const Loading = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas: ". . ." ". spinner ." ". label ." ". . .";
  height: 70%;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    grid-template-areas: ". . ." ". spinner ." "label label label" ". . .";
    font-size: 20px;
  }
`

export const SpinnerLoading = styled.div`
  -ms-grid-row: 2; 
  grid-row: 2; 
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: spinner; 
  -ms-grid-row-align: center; 
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
`

export const LabelLoading = styled.div`
  -ms-grid-row: 3; 
  grid-row: 3;
  -ms-grid-column: 2; 
  grid-column: 2; 
  grid-area: label; 
  color: #5CB3E8;
  font-size: 24px;
  -ms-grid-column-align: center;
  justify-self: center;
  align-self: flex-start;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 20px;
  }
`

export const BordaAccordion = styled.div`
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  margin-left: 16px;
  margin-top:  16px;
  height: fit-content;
  width: calc(100% - 32px);
  -webkit-tap-highlight-color: transparent;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 16px;
  }
`

export const BordaAccordionEmpresa = styled(BordaAccordion)`
  margin-left: 0;
  margin-top: -28px;
  width: 100%;
  height: fit-content;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(40, 67, 91, 0.35);
  z-index: 1;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-top: 16px;
    margin-bottom: 0;
  }
`

export const BordaAccordionAjuda = styled(BordaAccordion)`
  border: none;
  border-radius: unset;
  margin-left: 0;
  width: 100%;
  padding: 0 12px;
  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;
  }
`

export const BordaAcordionPortalTransparencia = styled.div`
 border: none;
  border-radius: unset;
  width: 100%;
  color: #FEFEFE;
  margin-right: ${(props) => (props.menu ? '16px' : '0px')};
  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 0;
  }
`

export const TituloAccordion = styled.div`
  background-color: #FEFEFE;
  height: 50px;
  cursor: pointer;
  box-sizing: border-box;
  margin-left: 8px;
  width: calc(100% - 32px);
  border: none;
  text-align: left;
  transition: 0.4s;
  padding-left: 8px;
  padding-top: 8.5px;

  h6 {
    margin: 0px;
    display: inline;
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => (props.corTitulo)};
    position: relative;
    top: -8.5px;
    left: 8px;
  }
`

export const TitutloAccordionEmpresa = styled(TituloAccordion)`
  background-color: unset;
  height: 80px;
  padding-top: 0;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: 1fr;
  align-items: center; 
  width: calc(100% - 16px);

  h6 {
    width: 90%;
    font-size: 18px;
    top: 0;
  }
`

export const TituloAccordionAjuda = styled(TituloAccordion)`
  height: unset;
  box-sizing: unset;
  margin-left: 0px;
  width: 100%;
  transition: 0.4s;
  padding-left: 0px;
  padding-top: 0px;

  h6 {
    margin: 0px;
    display: inline;
    font-weight: normal;
    color: #636669;
    position: relative;
    top: -4px;
    left: 8px;
    font-size: 14px;
    line-height: 20px;
  }
`

export const SetaAccordion = styled.img`
    font-size: 20px;
    color: #636669;
    float: right;
    position: relative;
    top: 12px;
`

export const SetaAccordionEmpresa = styled(SetaAccordion)`
  top: 0;
`

export const SetaAccordionAjuda = styled(SetaAccordion)`
  top: 6px;
`

export const SetaAccordionPortalTransparencia = styled(SetaAccordion)`
  top: 6px;
  right: 70px;
`

export const ConteudoAccordion = styled.div`
  padding: 0 18px;
  background-color: white;
  display: ${(props) => (props.abrir ? 'default' : 'none')};
  overflow: hidden;
  border-radius: 8px;
`

export const ConteudoAccordionAjuda = styled(ConteudoAccordion)`
  padding: 0px;
  display: ${(props) => (props.abrir ? 'default' : 'none')};
`

export const NenhumRegistroErro = styled.div`
  display: flex;
  margin: auto;
  width: ${(props) => (props.alinhado ? '100%' : 'auto')};
  min-height: 55vh;
  max-height: 65vh;
  align-content: ${(props) => (props.alinhado ? 'unset' : 'center')};;
  justify-content: ${(props) => (props.filtro ? 'flex-start' : 'center')};
  margin-left: ${(props) => (props.filtro ? '35%' : 'auto')};
  align-items: ${(props) => (props.alinhado ? 'unset' : 'center')};
  justify-items: ${(props) => (props.filtro ? 'flex-start' : 'center')};
  position: relative;

  a:link, a:visited {
    text-decoration: none
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: ${(props) => (props.empresa ? '100%' : 'auto')};
    min-height: ${(props) => (props.empresa && '100%')};
  }
`

export const DivNenhumRegistro = styled.div`
  display: flex;
  width: 788px;
  margin: auto;
  padding-top: ${(props) => (props.empresa && '20px')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    flex-direction: column;
    width: 100%;
  }
`

export const FormularioNenhumRegistro = styled.div`
  width: 518px;
  margin-left: 60px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: auto;
    margin: 0;
  }
`

export const DivisorNenhumRegistro = styled.div`
  width: 2px;
  height: 255px;
  background: #EAEEF4;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
    height: 2px;
    margin: 8px 0;
  }
`

export const DadosNenhumRegistroErro = styled.div`
  grid-row: 3; 
  grid-column: 3; 
  -ms-grid-row: 3; 
  -ms-grid-column: 3; 
  grid-area: dados;
  svg {
    display: flex;  
    margin-left: auto;
    margin-right: auto;
  }
`

export const ImagemNenhumRegistroErro = styled.img`
  display: flex;  
  margin-left: auto;
  margin-right: auto;
`

export const TituloNenhumRegistroErro = styled.p`
  color: #636669;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin-top: 31px;
  margin-bottom: 8px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 20px;
  }
`

export const TextoNenhumRegistroErro = styled.p`
  margin: 0;
  color: #999CA1;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
`

export const DivBotaoErro = styled.div`
  margin: 16px 0 0 -15px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LightBox = styled.div`
  position: fixed;
  background-color: ${(props) => (props.ajuda ? 'transparent' : '#FEFEFE;')};
  opacity: 45%;
  z-index: ${(props) => (props.ajuda ? '0' : '2')};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`

export const GridFiltroSolucoes = styled.div`
  display: block;
  width: 1024px;
  opacity: 0.99999;
`

export const SolucoesGrid = styled.div`
  display: block;
  padding-bottom: 16px;
  float: left;
  width: 665px;
`

export const DivLoadingFiltro = styled.div`
  position: static;
  margin-left: 0;
  width: auto;
`

export const FiltroGrid = styled.div`
  display: ${(props) => (props.esconderFiltro ? 'none' : 'block')};
  z-index: 1;
  padding-left: 16px;
  float: right;
  width: 343px;
`

export const FiltroDiv = styled.div`
  margin-top: ${(props) => (props.categoria ? '8px' : '26px')};
  margin-right: 48px;
  border-radius: 8px;
  min-height: ${(props) => (props.unidades ? '0' : '300px')};
  background-color: #FEFEFE;
  box-shadow: 0 0 4px 0 #CFD3D8;
  padding-bottom: 2px;
`

export const AutocompleteDiv = styled.div`
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  background-color: #F5F6F9;
  color: #005CAF;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 8px;
  width: ${(props) => (props.tamanho ? props.tamanho : '')};

  input {
    color: #2D2F32;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 20px;
  }
`

export const DivCidades = styled.div`
  color: #636669;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 18px;

  img {
    margin-right: 8px;
    position: relative;
    top: 3px;
  }
`
export const BalaoEstilizado = styled.div`
  visibility: ${(props) => (props.aberto ? 'visible' : 'hidden')};
  width: 285px;
  height:  ${(props) => (props.altura ? props.altura : '230px')};
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 0 0 22px 6px rgba(69,103,140,0.4);
  padding: 5px 0;
  position: absolute;
  z-index: 3;
  top: ${(props) => (props.top)};
  right: ${(props) => (props.right)};
  transform: ${(props) => (props.setaFundo && 'translateY(-103%)')};
  
  &:after{
    content: "";
    position: absolute;
    bottom: ${(props) => (props.setaFundo ? '-10px' : '100%')};
    left: ${(props) => (props.posicaoSeta)};
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) => (props.setaFundo
    ? '#FEFEFE transparent transparent transparent'
    : 'transparent transparent #FEFEFE transparent')};
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    right: 16px;
  }
  @media (max-width: 300px) {
    width: 255px;
    &:after{
      left: ${(props) => (props.posicaoSeta) === '79%' && '76%'};
    }
  }
`

export const ModalDiv = styled.div`
  height: ${(props) => (props.altura ? `${props.altura}px` : '530px')};
  position: fixed;
  width: ${(props) => (props.aberto ? (props.fullWidth ? 'calc(100vw + 4px)' : props.largura ? `${props.largura}px` : '700px') : '0px')};
  z-index: 3; 
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 0 2px 54px 0 #BDC1C7;
  visibility: ${(props) => (props.aberto ? 'visible' : 'hidden')};
  overflow: ${(props) => (props.modalSolucao ? 'auto' : 'unset')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    overflow: auto;
    bottom: 0;
    left: 0;
    width: 100vw;   
    box-shadow: 0 0 11px 0 #CFD3D8;
    border-radius: 8px 8px 0 0;
    transition: 0.3s; 
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    height: ${(props) => (props.aberto ? (props.alturaMobile ? `${props.alturaMobile}` : 'calc(100% - 80px)') : '0px')};
    overflow-x: hidden;
  }
`

export const FecharImgDiv = styled.div`
  outline: none;
  
  &:hover{
    cursor: pointer;
  }
`

export const DivHeaderModal = styled.div`
  border-bottom: 1px solid #EAEEF4;
  padding: 21px 21px 12px 21px;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  justify-content: space-between;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    justify-content: unset;
  }  
`

export const DivTituloModal = styled.div`
  margin: unset;
  color: ${(props) => (props.corTitulo ? props.corTitulo : '#348DCF')};  
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin: auto;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
`

export const DivChildrenModal = styled.div`
  padding: 0px;
  pointer-events: ${(props) => (props.modalSolucao ? 'none' : 'auto')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding: ${(props) => (props.filtro ? '16px 0 0 0' : '16px')};    
    height: ${(props) => (props.alturaMobile ? 'auto' : '80%')};
  }
`

export const DivBotaoPersonalizado = styled.div`
  display: flex;
  padding: 0px;
  height: 100%;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  img {
    position: absolute;
    left: 406px;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    img {
      left: 24px;
    }
  }
`

export const DivBotaoIconeDireita = styled.div`
  padding: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 20px;
    width: 20px;
  }

  p {
    flex: 1;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    img {
      left: 24px;
    }
  }
`

export const TelaInteira = styled.div`
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: ${(props) => (props.aberto ? '100vw' : '0px')};
  height: ${(props) => (props.aberto ? '100vh' : '0px')};
  top: 0;
  left: 0;
  display: flex;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    position: unset;
    justify-content: unset;
    align-items: unset;
    z-index: unset;
    width: unset;
    height: unset;
    top: unset;
    left: unset;
    display: unset;
  }
`

export const Steps = styled.div`
  height: 59px;
  border-bottom: 1px solid #EAEEF4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    border-bottom: unset;
    height: 50px;
  }
`

export const StepsSemBorda = styled(Steps)`
  border-bottom: unset;
  margin-bottom: 20px;
`

export const Passo = styled.div` 
  text-align: center;
  position: relative;
  display: flex;
  width: fit-content;

  &:hover{
    cursor: ${(props) => (props.ativo ? 'pointer' : 'default')};;
  }
`

export const SimboloPasso = styled.div`
  border-radius: 50%;
  background-color: ${(props) => (props.ativo ? '#348DCF' : props.concluido ? '#C8EEFB' : '#EAEEF4')};
  color: ${(props) => (props.ativo ? '#FEFEFE' : '#999CA1')};
  width: 20px;
  height: 20px;
`

export const TituloPasso = styled.p`
  margin: 0px;
  margin-left: 8px;
  color: ${(props) => (props.ativo ? '#333333' : '#999CA1')};
  font-size: 16px;
  line-height: 22px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    color: #2D2F32;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding-top: 22px;
  }
`

export const Separador = styled.span`
  border-bottom: 1px solid #EAEEF4;
  height: 12px;
  width: 50px;
  margin: 0px 8px;
`

export const TituloPrenchimento = styled(Steps)`
  justify-content: center;
  align-items: center;
  border-bottom: unset;
  margin-bottom: 20px;

  h1 {
    color: #333333;
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    justify-content: flex-start;
    border-bottom: 1px solid #EAEEF4;
    margin-bottom: unset;
  }
`

export const TextoFormulario = styled.p`
  color: ${(props) => (props.erro ? '#DC2828' : props.disabled ? '#90949A' : '#005CAF')};
  font-size: 14px;
  line-height: 16px;
  margin-top: 4px;
  margin-left: 10px;
  margin-bottom: 2px !important;
  text-align: left;
`

export const InputTexto = styled(InputMask)`
  padding: 1px 0;
  width: ${(props) => (props.senha ? 'calc(100% - 56px)' : 'calc(100% - 20px)')};
  border: none;
  outline: none;
  margin-left: ${(props) => (props.$semMargem ? '0' : '10px')};
  background-color: ${(props) => (props.disabled ? 'transparent' : (
    props.cor ? props.cor : '#F5F6F9'))};
  color: ${(props) => (props.disabled ? '#90949A' : '#2D2F32')};
  font-size: 16px;
  line-height: 20px;
  position: ${(props) => (props.senha ? 'relative' : 'unset')};
  top: -8px;
  text-align: start;

  @supports (-webkit-touch-callout: none) {
    padding: 0;
  }

  &:disabled {
    opacity: 1;
    -webkit-text-fill-color: #90949A;
  }

  ::placeholder {
    color: ${(props) => (props.disabled ? '#90949A' : '#999CA1')};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${(props) => (props.disabled ? '#90949A' : '#999CA1')};;
  }

  ::-ms-input-placeholder {
    color: ${(props) => (props.disabled ? '#90949A' : '#999CA1')};;
  }

  ::-ms-reveal {
    display: none;
  }
 
  ::-ms-clear {
    display: none;
  }

  &:hover{
    cursor: ${(props) => (props.clicavel ? 'pointer' : 'default')};
  }
`

export const ErroValidacao = styled.p`
  color: #DC2828;
  font-size: 12px;
  line-height: 12px;
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: left;  

  @supports (-webkit-touch-callout: none) { 
    position: relative;
    top: -3px;
  }
`

export const ErroValidacaoSenha = styled(ErroValidacao)`
  font-size: 14px;
  line-height: 14px;
  margin-left: 32px;
`

export const NomeFormulario = styled.div`
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: nome; 
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  background-color: #F5F6F9;
  margin-bottom: 14px;
  height: 45px;
  border: ${(props) => (props.erro ? '1px solid #DC2828' : '1px solid #EAEEF4')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: ${(props) => (props.cadastro ? '0px 0px 14px 0px' : '16px 16px 16px 16px')};
  }
`
export const NomeEmpresaFormulario = styled(NomeFormulario)`
  -ms-grid-column-span: 7;
  width: ${(props) => (props.largura)}; 
  border: ${(props) => (props.erro ? '1px solid #DC2828' : '1px solid #EAEEF4')};
  margin-bottom: 18px;
  margin-left: ${(props) => (props.margem ? '16px' : '0')};
  background-color: ${(props) => (props.desabilitado ? 'transparent' : '#F5F6F9')};
  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: ${(props) => (props.cadastro ? '0px 0px 14px 0px' : '16px')};
  }
`

export const EmailFormulario = styled.div`
  -ms-grid-row: 2; 
  -ms-grid-column: 1; 
  grid-row: 2; 
  grid-column: 1; 
  grid-area: email; 
  border: ${(props) => (props.erro ? '1px solid #DC2828' : '1px solid #EAEEF4')};
  border-radius: 8px;
  background-color: ${(props) => (props.cor ? props.cor : '#F5F6F9')};
  height: 45px;
  margin-bottom: ${(props) => (props.cadastro ? '14px' : '0px')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: ${(props) => (props.cadastro || props.fullWidth ? '0px 0px 14px 0px' : '16px 16px 16px 16px')};
  }
`

export const TelefoneFormulario = styled.div`
  width: ${(props) => (props.tamanho ? props.tamanho : 'auto')};
  -ms-grid-row: 2; 
  -ms-grid-column: 2; 
  grid-row: 2; 
  grid-column: 2; 
  grid-area: telefone; 
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  background-color: #F5F6F9;
  height: 45px;
  margin-left: ${(props) => (props.cadastro ? '0px' : '16px')};
  margin-right: ${(props) => (props.cadastro ? '8px' : '0px')};
  border: ${(props) => (props.erro ? '1px solid #DC2828' : '1px solid #EAEEF4')};
  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: ${(props) => (props.cadastro ? '0px 8px 0px 0px' : '16px 16px 38px 16px')};
    width: ${(props) => (props.tamanho ? props.tamanho : '50%')};
  }
`

export const CNPJFormulario = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 9; 
  grid-row: 1; 
  grid-column: 9; 
  -ms-grid-column-span: 5; 
  grid-area: cnpj; 
  border: ${(props) => (props.erro ? '1px solid #DC2828' : '1px solid #EAEEF4')};
  border-radius: 8px;
  background-color: #F5F6F9;
  margin-left: ${(props) => (props.cadastro ? '0px' : '16px')};
  margin-bottom: 14px;
  height: 45px;
  width: ${(props) => (props.tamanho ? props.tamanho : 'auto')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: ${(props) => (props.cadastro ? '0px 0px 14px 0px' : '0px 8px 16px 16px')};
  }
`

export const CPFFormulario = styled.div`
  border: ${(props) => (props.erro ? '1px solid #DC2828' : '1px solid #EAEEF4')};
  border-radius: 8px;
  background-color: #F5F6F9;
  /* margin-left: 8px; */
  margin-bottom: 14px;
  height: 45px;
`

export const ItemFormularioGenerico = styled.div`
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? '#FEFEFE' : '#F5F6F9')};
  margin-bottom: 18px;
  height: 45px;
  border: ${(props) => (props.erro ? '1px solid #DC2828' : '1px solid #EAEEF4')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    position: relative;
    bottom: 1px;
  }
`

export const DivUsuarioSenha = styled.div`
  display: flex;
  width: ${(props) => (props.tamanho ? props.tamanho : 'auto')};
  align-items: center;

  div {
    flex-grow: 1;
  }
`

export const DivUsuarioClicavel = styled.div`
  &:hover{
    cursor: pointer;
  }
`

export const ImgUsuario = styled.img`
  margin-right: 10px;
  padding-bottom: 14px;
  margin-right: ${(props) => (props.login ? '8px' : '10px')};
`

export const ImgUsuarioSenha = styled.img`
  margin-right: 9px;
  padding-bottom: 14px;
`

export const ImgSenha = styled.img`
  border: none;
  position: relative;
  top: 6px;

  &:hover{
    cursor: pointer;
  }
`

const dragActive = css`
  border-color: #3aa317;
`
const dragReject = css`
  border-color: #DC2828;
`

export const DivDrop = styled.div`
  width: ${(props) => (props.largura ? props.largura : '479px')};
  height: ${(props) => (props.tamanho ? props.tamanho : '253px')};
`

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  width: 100%;
  height: 100%;
  background: #F5F6F9;
  border: 2px dashed #BDC1C7;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  
  ${(props) => props.isDragActive && dragActive}
  ${(props) => props.isDragReject && dragReject}
`

export const ImgContainer = styled.img`
  cursor: pointer;
  width: 479px;
  height: 253px;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${(props) => (props.largura ? props.largura : '479px')};
  height: ${(props) => (props.tamanho ? props.tamanho : '253px')};
`

const messageColors = {
  default: '#999',
  erro: '#DC2828',
  sucesso: '#3aa317',
}

export const UploadMessage = styled.p`
  display: flex;
  position: absolute;
  margin-left: 16px;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 20px;

  img{
    margin-right: 8px;
  }
`

export const Botao = styled.button`
  position: relative;
  align-self: center;
  top: -146.5px;
  height: 40px;
  width: 200px;
  background: #FEFEFE;
  color: #005CAF;
  border: 1px solid #005CAF;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  display: ${(props) => (props.exibir ? 'block' : 'none')};
  margin: auto;
  justify-content: center;
  align-items: center;
  z-index:0;
`

export const InserirIMG = styled.div`
  height: 40px;
  width: 200px;
  background: #FEFEFE;
  color: #005CAF;
  border: 1px solid #005CAF;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  margin-top: 95px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  img{
    position: relative;
    left: 24px;
  }
`

export const DimensaoIMG = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: #90949A;
  margin-top: 8px;
`

export const LabelSecundaria = styled(DimensaoIMG)`
  margin: 0;
  line-height: 0;
`

export const SelectTema = styled(Select)`
  position: absolute;
  width: ${(props) => (props.senha ? 'calc(100% - 56px)' : 'calc(100% - 20px)')} !important;
  outline: none ;
  border: 0px !important;
  margin-left: 10px;
  background-color: #F5F6F9 !important;
  color: ${(props) => (props.disabled ? '#CFD3D8' : '#2D2F32')} !important;
  font-size: 14px !important;
  line-height: 20px;
  text-align: start; 
  border-bottom: 0px !important;
  &:hover{
    border: none !important;
    background-color: #F5F6F9 !important;
    border-bottom: 0px !important;
  }
  input  {
    border-bottom: 0px !important;
    background-color: #F5F6F9 !important;
  }
  
`

export const MenuItemTema = styled(MenuItem)`
  font-size: 14px; 
`

export const TagsFormulario = styled.div`
  -ms-grid-row: 1; 
  grid-row: 1; 
  -ms-grid-column: 1; 
  grid-column: 1; 
  grid-area: nome; 
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  background-color: #F5F6F9;
  margin-bottom: 14px;
  min-height: 45px;
  height: 88px;
`

export const InputListFormulario = styled.div`
  grid-row: 1; 
  -ms-grid-row: 1; 
  grid-column: 1; 
  -ms-grid-column: 1; 
  grid-area: nome; 
  border: 1px solid #EAEEF4;
  background-color: #F5F6F9;
  border-radius: 8px;
  margin-bottom: 14px;
  min-height: 45px;
`

export const DivInputTags = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  height: 70px;
  width: 462px;
  padding: 0 4px;
  overflow-x: scroll;
  overflow-y: hidden;

  input {
      margin-left: 4px;
      background: transparent;
      flex: 1;
      border: none;
      height: 23px;
      font-size: 12px;
      padding: 0;
      &:focus {
          outline: transparent;
      }
  }
`

export const ImgInformacao = styled.img`
  margin: 2px;
`

export const DivTituloInput = styled.div`
  margin: 0px;
  padding: 0px;
  display: flex;
`

export const UlInputTags = styled.ul`
  width: auto;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  font-size: 12px;
  list-style: none;
  margin: 0px;
`

export const ImgFecharTag = styled.img`
  height: 10px;
  text-align: center;
  margin-left: 8px;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  padding-top: 2px;
  padding-right: 8px;
`

export const TextoTag = styled.p`
  margin: 4px;
`

export const DivTag = styled.div`
  display: flex;
  margin: 4px 4px 0 4px;
  background: #DBDEE4;
  border-radius: 4px;
`

export const DataFormulario = styled.div`
  -ms-grid-column: 2; 
  -ms-grid-row: 2; 
  border: 1px solid #EAEEF4;
  background-color: #F5F6F9;
  grid-row: 2; 
  grid-column: 2; 
  grid-area: telefone; 
  border-radius: 8px;
  width: 67%;
  height: 45px;
  margin-right: ${(props) => (props.cadastro ? '8px' : '0px')};
  margin-left: ${(props) => (props.cadastro ? '0px' : '16px')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: ${(props) => (props.cadastro ? '0px 8px 0px 0px' : '16px 16px 38px 16px')};
    width: 50%;
  }
`

export const ImgUrl = styled.img`
  width: 479px;
  height: 253px;
  left: 33px;
  top: 88px;
  background: url(.jpg), #F5F6F9;
  border-radius: 8px; 
  object-fit: cover;
  object-position: center;
  width: ${(props) => (props.largura ? props.largura : '479px')};
  height: ${(props) => (props.tamanho ? props.tamanho : '253px')};
`

export const AcesseDivLink = styled.a`
  width: 300px;
  border: ${(props) => (props.inverso ? '1px solid #FEFEFE' : '1px solid #005CAF')};
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color:  ${(props) => (props.inverso ? '#FEFEFE' : '#005CAF')};
  height: 40px;
  padding-top: 8px;
  align-self: center;
  text-decoration: none;
  position: relative;

  &:hover {
    background: #C8EEFB;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: calc(100vw - 32px);    
  }
`

export const Icone = styled.span`
  position: absolute;
  right: 12px;
`

export const TelaCorte = styled.div`
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(40, 67, 91, 0.3);
  border-radius: 8px;
  height: 500px;
  width: 626px;
  display: ${(props) => (props.exibir ? 'block' : 'none')};
  z-index:3;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const DivisorCorte = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 54px;
  background: #EAEEF4;
  z-index:2;
  border-bottom: 1px solid #EAEEF4;
`
export const TituloCorte = styled.h1`
  position: absolute;
  width: 155px;
  height: 32px;
  left: 16px;
  top: 0px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #348DCF;
`

export const DescricaoCorte = styled.h1`
  position: absolute;
  width: 233px;
  height: 16px;
  left: 16px;
  top: 76px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: #636669;
`

export const DescricaoZoom = styled.h1`
  position: absolute;
  width: 40px;
  height: 16px;
  right: 175px;
  top: 88px;
  margin: 0px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  text-align: right;
  text-transform: uppercase;

  color: #005CAF;
`

export const DivAvatarEditor = styled.div`
  position: absolute;
  width: 502px;
  height: 271px;
  left: 5px;
  top: 108px;
`

export const BotaoCancelarCorte = styled.div`
  position: absolute;
  width: 144px;
  height: 32px;
  right: 168px;
  bottom: 16px;
`

export const BotaoCortar = styled.div`
  position: absolute;
  width: 124px;
  height: 32px;
  right: 32px;
  bottom: 16px;
`

export const BotaoSairCorte = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`

export const DivInputRange = styled.input`
  font-size: 16px;
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 92px;
  background: #DBDEE4;
  width: 151px;
  height: 4px;

  ::-webkit-slider-runnable-track {
    width: 151px;
    height: 4px;
    cursor: pointer;
    background: #DBDEE4;
    border-radius: 8px;
    border: 0px solid #000000;
  }
  
  ::-moz-slider-runnable-track {
    width: 151px;
    height: 4px;
    cursor: pointer;
    background: #DBDEE4;
    border-radius: 8px;
    border: 0px solid #000000;
  }

  ::-ms-track {
    width: 151px;
    height: 4px;
    cursor: pointer;
    background: #DBDEE4;
    border-radius: 8px;
    border: 0px solid #000000;
  }

  -webkit-appearance: none;

  :focus {
    outline: none;
  }

  ::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497E3;
    height: 16px;
    width: 17px;
    border-radius: 16px;
    background-color: #005CAF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.5px;
  }

  ::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  ::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  &:disabled {
    opacity: 1;
    color: #90949A;
    -webkit-text-fill-color: #90949A;
  }
`

export const LinkBotaoVoltar = styled(Link)`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  box-sizing: border-box; 
  background: #005CAF;
  height: 32px;
  &:hover {
    background: #348DCF;
    cursor: pointer;
    border-radius: 8px;
  }
`
export const LabelBotaoVoltar = styled.p`
  font-style: normal;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;  
  color: #FEFEFE;
  padding-top: 5px;
  margin: 0 9px 0 8px;
`

export const DivReCaptcha = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
  align-items: initial;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    transform: scale(0.95);
    width: calc(100% - 32px);
    padding-left: 16px;
  }
`

export const DivCheckTermo = styled.div`
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-left: 0px;
  }
`

export const DivCheckTermoTitulo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
`

export const DivTituloCheckTermo = styled.span`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #636669;
`

export const DivSubTituloTermo = styled.div`
  font-size: 18px;
  color: #2D2F32;
  line-height: 24px;
`

export const Divteste = styled.div`
  display: flex;
`

export const LinkTituloCheckTermo = styled.span`
  color: #348DCF;
  margin: 0px;
  padding: 0px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`

export const InputCheckbox = styled.input`
  font-size: 16px;
  transform: scale(1.5);
`

export const DivConteudoTermo = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #2D2F32;
  padding: 16px;
  overflow: auto;
  height: 380px;
  overflow: auto;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding: 0px;
    height: 100%;
  }
`
export const BodySlider = styled.div`
  padding: 0 16px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const CorpoSelect = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.fullWidth ? '100%' : props.largura ? props.largura : '180px')};
  height: 54px;
  padding: 8px;
  background: ${(props) => (props.corFundo)};
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #005CAF;

  .MuiSelect-root {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => (props.label === '0' ? '#90949A' : '#2D2F32')};
    border: none;
    height: 14px;
    margin-top: -3px;
  }

  .MuiSelect-icon, .MuiSelect-icon.Mui-disabled {
    color: #636669;
    transform: ${(props) => (props.modalAberto && !props.telaGrande ? 'rotate(180deg)' : '')};
  }

  .MuiInput-underline:before {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
`

export const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontWeight: '400',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#C8EEFB',
    },
  },
  selected: {
    '&.Mui-selected': {
      backgroundColor: '#C8EEFB',
      '&:hover': {
        backgroundColor: '#C8EEFB',
      },
    },
  },
})(MenuItem)

export const OpcoesRadio = styled.div`
  display: flex;
  justify-content: space-around;

  .MuiRadio-colorSecondary.Mui-checked {
    color: #348DCF;

    :hover {
      background-color: rgba(52, 141, 207, 0.04);
    }
  }

  .MuiRadio-colorSecondary:hover {
    background-color: rgba(52, 141, 207, 0.04);
  }
`

export const DivBotaoResultados = styled.div`
  height: 100%;
  display: flex;
  margin-left: -16px;
  margin-top: 48px;
`
export const DivMensagemPoliticaPrivacidade = styled.div`
  width: 100%;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #636669;
  padding-left: 8px;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  padding-top: 8px;
   @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-top: 16px;
    padding-left: 0px;
    justify-content: center;
  }
`
export const LinkMensagemPoliticaPrivacidade = styled.a`
  text-decoration: underline !important;
  color: #348DCF;
  padding-left: 8px;
`
export const DivMensagemAlertaDados = styled.div`
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  background: #FEFEFE;
  border: 1px solid #5CB3E8;
  border-radius: 8px;
  margin-left: -10px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 284px;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: calc(100% - 20px);
    margin-left: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

export const ImgInformacaoDados = styled.img`
  margin-right: 4px;
`

export const DivChips = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Chips = styled.span`
  border: ${(props) => (props.ativo ? '1px solid #348DCF' : '1px solid #CFD3D8')};
  border-radius: 16px;
  padding: 8px;
  color: #636669;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-right: 8px;
  background-color: ${(props) => (props.ativo ? '#C8EEFB' : 'none')};
  margin-bottom: 4px;
  &:hover {
    cursor: pointer;
    background-color:  ${(props) => (props.ativo ? '#95D1F5' : '#EAEEF4')};
  }
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    flex: 0 0 auto;
    &:hover {
      background-color: ${(props) => (props.ativo ? '#C8EEFB' : 'transparent')};
    }
  }
`

export const DivSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const StyledSlider = withStyles({
  root: {
    color: '#348DCF',
    width: '90%',
  },
  rail: {
    opacity: 1,
    color: '#DBDEE4',
    height: '4px',
    borderRadius: '8px',
  },
  track: {
    height: '4px',
  },
  thumb: {
    height: '24px',
    width: '24px',
    '&.Mui-disabled': {
      height: '24px',
      width: '24px',
    },
  },
})(Slider)

export const InputSlider = styled.input`
  font-size: 16px;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
`

export const SpanTitulo = styled.span`
  padding-left: 3px;
`

export const DivValoresIntervalo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`

export const LabelIntervalo = styled.div`
  box-sizing: border-box;
  width: 106px;
  height: 54px;
  border-radius: 8px;
  background: #F5F6F9;
  border: 1px solid #EAEEF4;
  padding: 8px;
  font-size: 14px;
  color: #005CAF;
`

export const ValorIntervalo = styled.div`
  color: #2D2F32;
`

export const DivAutocomplete = styled.div`
  display: inline-block;
  width: 332px;
  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: calc(100% - 52px);
  }
`

export const OpcoesCidadePF = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  
  img {
    margin-right: 8px;
  }
`

export const SeparadorVertical = styled.div`
  height: ${(props) => (props.niveisCapacitacao ? '80%' : '100%')};
  width: 1px;
  background-color: #EAEEF4;
  display: inline-flex;
  position: ${(props) => (props.niveisCapacitacao ? 'initial' : 'absolute')};
  ${(props) => (props.possuiValor ? 'left: 374px;' : '')}
`

export const SeparadorHorizontal = styled.div`
  width: ${(props) => (props.voce ? 'calc(100% - 48px)' : 'calc(100% - 16px)')};
  height: 1px;
  background-color: #EAEEF4;
  position: absolute;
`

export const CardSelecionavel = styled.div`
  width: 260px;
  height: 136px;
  box-sizing: border-box;
  border: ${(props) => (props.ativo ? '1px solid #348DCF' : '1px solid #EAEEF4')};
  border-radius: 8px;
  margin-right: 16px;
  outline: none;
`

export const TituloCardSelecionavel = styled.div`
  color: #348DCF;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
`

export const TextoCardSelecionavel = styled.div`
  color: #90949A;
  font-size: 14px;
  line-height: 16px;
  margin: 0 12px;
`

export const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
        color: '#348DCF',
      },
    },
  },
  checked: {},
})(Checkbox)
export const DivCarregando = styled.div`
  min-height: calc(100vh - 81px);
  display: flex;
  align-items: center;
  justify-content: center;  

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    min-height: calc(100vh - 58px);
    display: block;
  }
`

export const DivSolucoes = styled.div`
  width:100%;
  margin: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`

export const ImgLocalizacao = styled.div`
  padding-right: 4px;
  padding-top: 6px;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-top: 0px;
  }
`

export const SolucoesModalidade = styled.h6`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #FEFEFE;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding: 0px;
    margin: 0px;
    padding-top: 14px;
  }
`

export const PaperSolucao = styled(Paper)`
  -ms-grid-row: 2; 
  -ms-grid-column: 1; 
  grid-row: 2; 
  grid-column: 1; 
  grid-area: conteudo; 
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0fr 0px 5.6fr 0fr 3.5fr 0px 0fr;
  grid-template-columns: 0fr 6fr 3.5fr 0fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  overflow: ${(props) => (props.modal ? 'hidden' : 'unset')};
  grid-template-areas: ". esquerda direita .";
  margin-bottom: 32px;
  box-shadow: none;
  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    display: unset;
    padding-bottom: 0px;
    margin-bottom: 0px;
    min-height: 300px;
  }
`

export const DivSolucaoHeaderAreaWeb = styled.div`
  min-width: 15%; 
  max-width: 75%;
  position: absolute;
  box-shadow: 0px 0px 5px rgba(40, 67, 91, 0.35);
  border-radius: 8px;
  background-color: white;
  padding-right: 20px;
  margin-top: -20px;
  margin-left: 16px;
`

export const DivTituloImagemBeneficio = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 1; 
  grid-row: 1; 
  grid-column: 1; 
  -ms-grid-row-span: 3; 
  grid-area: img; 
  width: 32px;
  height: 32px;
`

export const DivTituloBeneficio = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 40px 1fr;
  grid-template-columns: 40px 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr ;
  grid-template-areas: "img area" "img categoria";
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-top: 20px;
  }
`

export const DivTituloDescrBeneficio = styled.h2`
  margin:0;
  font-weight: 500;
  -ms-grid-row: 1; 
  -ms-grid-column: 2; 
  grid-row: 1; 
  grid-column: 2; 
  grid-area: area; 
  text-align: start;
  color: ${(props) => (props.cor)};
  font-size: ${(props) => (props.card ? '18px' : '20px')};
  letter-spacing: 0;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 18px;
  }
`

export const FooterTexto = styled.div`
  margin: 8px 0px 8px 16px;
  color: #2D2F32;
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
`

export const TituloSolucao = styled.p`
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  margin: 4px 4px 0px 4px;
  color: #FEFEFE;
  text-align: left;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin: 12px 16px 0px 16px;
    font-size: 20px;
    line-height: 24px;
  }
`

export const TituloModalidade = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin: 12px 0px 4px 4px;
  color: #FEFEFE;
  align-items: center;

  >img{
    position: relative;
    top: 3px;
  }

  svg {
    position: relative;
    top: 3px;
    padding-right: 8px;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin: 12px 0px 12px 16px;
    font-size: 14px;
    line-height: 16px;
  }
`

export const ImagemModalidade = styled.img`
  padding-right: 8px;
  pointer-events: none;
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const Beneficios = styled.div`
  margin-right: 10px;  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100vw;
    height: 100%;
  }
`

export const Empresa = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 1; 
  grid-row: 1; 
  grid-column: 1; 
  grid-area: empresa; 
  padding: 8px;
  padding-left: 0;
  text-align: left;
  color: #90949A;
  font-size: 16px;
  line-height: 21px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    border: unset;
    border-radius: unset;
    padding: 0px;
    margin: 0 16px;
    font-size: 14px;
  }
`

export const Requisitos = styled(Empresa)`
  margin-bottom: 0;
  padding-bottom: 32px;
`

export const Colaboradores = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 3; 
  grid-row: 1; 
  grid-column: 3; 
  grid-area: colaborador;
  padding: 8px;
  padding-left: 0;
  text-align: left;
  color: #90949A;
  font-size: 16px;
  line-height: 21px;
  padding-top: 26px;
  padding-bottom:20px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    -ms-grid-row: 2; 
    -ms-grid-column: 1; 
    grid-row: 2; 
    grid-column: 1; 
    border: unset;
    border-radius: unset;
    padding: 0px;
    margin: 16px;
    margin-bottom: 0px;
    padding-bottom: 40px;
    font-size: 14px;
  }
`

export const TextoBeneficios = styled.p`
  margin: 0px;
`

export const DivTexto = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 3; 
  grid-row: 1; 
  grid-column: 3; 
  grid-area: texto;
  
  ul {
    margin: 0;
  }
`

export const TextoUnidades = styled.p`
  color: #348DCF;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  display: inline;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 14px;
  }
`

export const DivQuantidadeUnidades = styled.div`
  width: 100%;
  display: flex;
  font-size: 16px;
  line-height: 18px;
  justify-content: center;
  
  img {
    visibility: hidden;

    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      visibility: visible;
    }
  }

  > div > img {
    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      margin-right: 8px;
      top: 4px;
      position: relative;
      margin-left: 16px;
    }
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    justify-content: space-between;
    margin-top: 20px;
    width: 100vw;
    border-bottom: 1px solid #EAEEF4;
    visibility: visible;
    padding-left: 16px;
    margin-left: unset;
    font-size: 14px;
    position: unset;
}
`

export const SetaImg = styled.img`
  margin-right: 16px;
  margin-bottom: 20px;
  margin-top: 6px;
  width: 8px;
  height: 14px;
`

export const Unidades = styled.div`
  background-color: #F5F6F9;
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px;
  margin-bottom: 16px;
  
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: calc(100vw - 48px);
    margin-left: 16px !important;
    margin-right: 16px !important;
    padding-top: 8px !important;
    padding-left: 8px !important;
    padding-bottom: 2px !important;
  }
`

export const ListaUnidadesWeb = styled(Unidades)`
  height: 51vh;
  overflow-y: auto;
`

export const UnidadesDetalhes = styled.div`
  overflow: auto;
  position: fixed; 
  z-index: 3; 
  bottom: 0;
  left: 0;
  width: 100vw;   
  background-color: #FEFEFE;
  box-shadow: 0 0 11px 0 #CFD3D8;
  border-radius: 8px 8px 0 0;
  transition: 0.3s; 
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  height: ${(props) => (props.aberto ? 'calc(100% - 80px)' : '0px')};
  visibility: ${(props) => (props.aberto ? 'visible' : 'hidden')};
`

export const DivTituloUnidades = styled.div`
  border-bottom: 1px solid #EAEEF4;
  padding: 21px 21px 12px 21px;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`

export const DivUnidadesDisponiveis = styled.div`
  margin: auto;
  color: #348DCF;
`

export const BodyUnidades = styled.div`
  height: calc(100% - 58px);
  overflow-y: auto;
`

export const DivUnidadesDetalhes = styled.div`
  margin-bottom: 72px;
`

export const BotaoDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  margin-right: 72px;

  > button {
    top: 25px;
    position: relative;
    margin-left: 0px;

    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      margin-right: 16px;
      margin-top: unset;
      position: unset;
    }
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-top: unset;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 44px;
    padding-top: 14px;
    padding-bottom: 10px;
    border-radius: 8px 8px 0 0;
    background-color: #FEFEFE;
    box-shadow: 0 0 11px 0 #CFD3D8;
    justify-content: space-between;
    z-index: 1;
  }
`

export const BotaoCotacao = styled(BotaoDiv)`
  > button {
    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
      margin: 0px;
      padding: 0px;
    }  
  }
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    justify-content: center;
    padding: 16px 16px 6px 16px;
    width: calc(100vw - 32px);
  }
`

export const TituloCidade = styled.h4`
  color: #999CA1;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 7px 0px 8px 16px;
`

export const TituloCidadeCotacao = styled(TituloCidade)`
  margin: 7px 0px 8px 8px;
`

export const DivUnidades = styled.div`
  margin-bottom: 13px;
  border-bottom: 1px solid #EAEEF4;
  width: calc(100% - 32px);
  margin-left: 16px;
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 0 0 4px 0 #CFD3D8;
  padding-top: 8px;
  padding: 8px 8px 8px 0px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: calc(100vw - 32px);
    background-color: unset;
    border-radius: unset;
    box-shadow: unset;
    padding-top: unset;
    padding-right: unset;
  }
`

export const DivUnidadesCotacao = styled(DivUnidades)`
  box-shadow: none;
  border: ${(props) => (props.selecionado ? '1px solid #5CB3E8' : '1px solid #EAEEF4')};

  &:hover{
    box-shadow: 0 0 11px 0 #CFD3D8;
  }
`

export const DivUnidadesSemBorda = styled(DivUnidades)`
  border-bottom: none;
`

export const DivUnidadesMobile = styled.div`
  width: unset;
  border-radius: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #EAEEF4;
  padding: 4px;
`

export const DivNomeUnidade = styled.div`
  align-items: center;
`

export const TituloUnidade = styled.label`
  color: #348DCF;
  font-size: 16px;
  line-height: 16px;
  margin-left: 4px;
  -ms-grid-row: 1; 
  -ms-grid-column: 3; 
  grid-row: 1; 
  grid-column: 3; 
  grid-area: titulo; 

  &:hover {
    cursor: pointer;
  }
`

export const DivDetalheUnidades = styled.div`
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1px 11fr;
  grid-template-columns: 1fr 11fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: "imagem texto";
  margin-left: 8px;
  margin-top: 8px;
  align-items: flex-start;
  padding-bottom: 0;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    -ms-grid-columns: 1fr 1px 8fr;
    grid-template-columns: 1fr 8fr;
    padding-bottom: 12px;
  }
`

export const DivBotaoFazerCotacao = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 4px;
  margin: ${(props) => (props.indisponivel ? '0' : '0 -8px')};
  width: 100%;
`

export const SeparadorUnidadeCotacao = styled.div`
  border-bottom: 1px solid #EAEEF4;
  margin: ${(props) => (props.telaGrande ? '0 8px 8px 16px' : '0px')};
  width: ${(props) => (props.telaGrande ? 'auto' : '100%')};
`

export const DivDetalheUnidadesTelefone = styled(DivDetalheUnidades)`
  align-items: center;
  height: 32px;
`

export const DivDetalheTelefone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DivBotaoFazerCotacaoWeb = styled.div`
  margin-right: 22px;
`

export const DivDetalheUnidadesUnica = styled(DivDetalheUnidades)`
  -ms-grid-columns: 1fr 1px 11fr;
  grid-template-columns: 1fr 11fr;
  margin-left: unset;
  margin-bottom: 18px;
  padding-right: 8px;
  align-items: center;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-left: 8px;
  }
`

export const ImagemUnidade = styled.img`
  -ms-grid-row: 1; 
  -ms-grid-column: 1; 
  grid-row: 1; 
  grid-column: 1; 
  grid-area: imagem;
  width: 18px;
  height: 18px;
`

export const LinkUnidade = styled(Link)`
  text-decoration: none;
`

export const TextoUnidadeLocalizacao = styled.h2`
  -ms-grid-row: 1; 
  -ms-grid-column: 3; 
  grid-row: 1; 
  grid-column: 3; 
  grid-area: texto; 
  margin: 0px;
  color: #348DCF;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 8px;
  min-height: 36px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 13px;
    min-height: 42px;
  }
`

export const TextoUnidade = styled.p`
  -ms-grid-row: 1; 
  -ms-grid-column: 3; 
  grid-row: 1; 
  grid-column: 3; 
  grid-area: texto; 
  margin: 0px;
  color: #636669;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 13px;
  }
`

export const FecharImg = styled.img`
  outline: none;
  pointer-events: none;
  width: 14px;
  height: 14px;
`

export const ListaUnidadesCotacao = styled.div`
  width: 1024px;
  align-self: center;
  position: relative;
  -webkit-align-self: initial !important;
`

export const BuscaCidadeCotacao = styled.div`
  padding: 0px 16px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(290px,1fr) 16px minmax(290px,1fr) 16px minmax(290px,1fr);
  grid-template-columns: repeat(3,minmax(290px,1fr));
  grid-gap: 16px;
  margin: -24px 16px 0px 16px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: 0px 48px 0px 16px;
  }
`

export const DivCidadeCotacao = styled.div`
  margin-top: 19px;
  padding: 16px 16px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(290px, 1fr) 16px minmax(290px, 1fr) 16px minmax(290px, 1fr);
  grid-template-columns: repeat(3, minmax(290px, 1fr));
  grid-gap: 16px;
`

export const TituloFormulario = styled.p`
  color: #348DCF;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 24px 0px 14px 0px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin: 0px 16px;
  }
`

export const TituloUnidadesFormulario = styled(TituloFormulario)`
  margin: 8px 16px 4px 16px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) { 
    margin-top: 0px;
    color: #348DCF;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }
`

export const DivConteudo = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;  
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 0px;
  background-color: #F5F6F9;
`

export const DivConteudoFormulario = styled(DivConteudo)`
  padding-bottom: unset;
  background-color: unset;
  padding-top: unset;
`

export const DivConteudoBotoes = styled(DivConteudoFormulario)`
  background-color: ${(props) => (props.esconder ? '#F5F6F9' : 'transparent')};

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    background-color: transparent;
  }
`

export const DivMainSolucao = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
`

export const DivConteudoSolucao = styled.div`
  width: 1024px;
  align-self: center;
  margin-top: 20px;
`

export const DivSolucoesInteresse = styled.div`
  background-color: #F5F6F9;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

export const DivTituloArea = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.cor)};
`

export const DivHeaderCategoria = styled.h1`
  min-height: 70px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  color: #FEFEFE;
  padding-left: 16px;
  margin-top: 14px;
  margin-bottom: 8px;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const HeaderSolucoes = styled.div`
  width: 100vw;
  background: ${(props) => (props.cor)};
  -ms-grid-row: 1; 
  -ms-grid-column: 1; 
  grid-row: 1; 
  grid-column: 1; 
  grid-area: cabecalho; 
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.5fr 0px 8fr 0px 4fr 0px 0.5fr;
  grid-template-columns: 0.5fr 8fr 4fr 0.5fr;
  -ms-grid-rows: auto 0px auto;
  grid-template-rows: auto auto;
  gap: 0px 0px;
  grid-template-areas: ". titulos titulos ." ". . botao .";

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    display: unset;
  }
`

export const LabelTooltip = styled.span`
  text-align: center;
  padding: 4px;
`

export const DivTooltip = styled.div`
  z-index: 3;
  .tooltiptext {
    width: ${(props) => (props.tamanho ? props.tamanho : '')};
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    z-index: 2;
  }
`

export const DivLoading = styled.div`
  width: 100%;
`

export const DivClicavel = styled.div`
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    position: relative;
    bottom: 1px;
  }

  &:hover{
    cursor: ${(props) => (props.clicavel ? 'pointer' : 'default')};
  }
`

export const DivBuscaNiveisCapacitacao = styled.div`
  display: flex;
  align-items: center;
  width: 286px;
  justify-content: space-between;
`

export const DivFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const OferecidoPor = styled.h2`
  margin-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  color: #FFFFFF;

  @media (min-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-top: -8px;
  }
`

export const StyledAppBar = styled(AppBar)`
  && {
    box-sizing: border-box;
    background: #FFFFFF;
    width: 1024px;
    margin: auto;
    box-shadow: none;

    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      padding-right: 0;
      width: 100%;
    }
  }
`

export const StyledTabs = withStyles({
  indicator: {
    background: '#005CAF',
  },
})(Tabs)

export const StyledTab = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    borderRadius: '8px 8px 0px 0px',
    padding: '6px 48px',
    width: 'auto',
    [`@media (max-width: ${RESOLUCAO_MAXIMA_MOBILE})`]: { // eslint-disable-line no-useless-computed-key
      padding: '6px 0px',
    },
  },
  textColorInherit: {
    color: '#5CB3E8',
    opacity: 1,
    '&.Mui-selected': {
      color: '#005CAF',
    },
  },

})(Tab)

export const TituloTableCell = styled(TableCell)`
  && {
    font-size: 18px;
    font-weight: 600;
    background: #fff;
    border-bottom: solid #EAEEF4 2px;
    color: ${(props) => (props.$cadastro && '#004796')};
    padding-right: 48px;

    &:hover {
      cursor: pointer;
      img {
        opacity: ${(props) => (props.selecionado === 'true' ? 1 : 0.5)};
      }
    }
  }
`

export const IconeOrdenacao = styled.img`
  transform: ${(props) => ((props.selecionado && !props.ascendente) ? 'rotate(-90deg)' : 'rotate(90deg)')};
  opacity: ${(props) => (props.selecionado ? 1 : 0)};
  position: absolute;
  margin-left: 8px;
  transition: 0.5s;
`

export const StyledTableRow = styled(TableRow)`
  && {
    :hover {
      background: #F5F6F9;
      cursor: ${(props) => (props.$cadastro ? 'default' : 'pointer')};
    }
  }
`

export const StyledTableCell = styled(TableCell)`
  && {
    font-family: 'Source Sans Pro';
    font-size: 18px;
    outline: ${(props) => (props.$cadastro ? 'none' : 'solid #EAEEF4 1px')};
    border-bottom: solid #EAEEF4 1px;
  }
`

export const ContainerTabela = styled.div`
  display: grid;
  background: #fff;
  padding: ${(props) => (props.$cadastro ? '0' : '0 16px')};
  border: ${(props) => (props.$cadastro ? 'none' : '1px solid #EAEEF4')};
  box-sizing: border-box;
  width: ${(props) => (props.$cadastro ? '100%' : 'calc(100% - 435px)')};
  height: 65vh;
`

export const TituloTabela = styled.p`
  margin: 8px 0 0 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2D2F32;
`

export const AreaCurso = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #90949A;
  margin: 0 16px;
`

export const DivSubtitulo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 332px;
`

export const SubtituloTabela = styled.p`
  margin: 0 0 0 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2D2F32;
`

export const DivTabela = styled.div`
  height: ${(props) => (props.cotacoes
    ? 'calc(65vh - 104px)'
    : props.cursos
      ? 'calc(65vh - 122px)'
      : 'calc(65vh - 56px)'
  )};
  overflow: auto;
`

export const DivInputEmail = styled.div`
  display: flex;
  margin-bottom: 32px;

  img {
    margin-right: 8px;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-bottom: 8px;

    img {
      margin-bottom: 8px;
    }
  }
`

export const StyledSwitch = withStyles({
  root: {
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#348DCF',
      opacity: 1,
    },
    '& .Mui-checked': {
      color: '#FFF',
      backgroundColor: 'transparent',
    },
  },
  track: {
    backgroundColor: '#DBDEE4',
    position: 'relative',
    top: '-8px',
    left: '-8px',
    height: '25px',
    borderRadius: '12px',
    width: '44px',
    marginRight: '-10px',
    opacity: '0.6',
  },
  thumb: {
    width: '15px',
    height: '15px',
  },
  switchBase: {
    color: '#90949A',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(Switch)

export const TextAreaPersonalizada = styled.textarea`
  width: ${(props) => (props.largura)};
  height: ${(props) => (props.altura)};
  margin: ${(props) => (props.margem)};
  outline: none;
  background: ${(props) => (props.corFundo || '#F5F6F9')};
  border: 1px solid #EAEEF4;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  padding: 8px;
  resize: none;

  ::placeholder {
    color: ${(props) => (props.disabled ? '#90949A' : '#999CA1')};
    opacity: 1;
  }
`

export const StyledTooltip = withStyles({
  root: {
    backgroundColor: 'red',
    color: 'blue',
    fontSize: '16px',
  },
})(Tooltip)

export const DivWhatsApp = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`
export const LinkWhatsApp = styled.button`
  height: 32px;
  width: 224px;
  border-radius: 8px;
  background-color: #23CD63;
  color: #FEFEFE;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border: none;
  outline: none;
  margin-right: ${(props) => (props.menu ? '16px' : '0px')};

  &:hover{
    cursor: pointer;
    background-color: #3EE57D;
  }
`
export const DivBotao = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  img {
    position: relative;
    top: 1px;
  }
`
export const ImgWhatsApp = styled.img`
  position: relative;
  right: 15%;
  top: 3px;
  width: 20px;
  height: 20px;
`

export default BotaoEstilizado

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import TituloAjudaDiv, {
  TextoAjudaDiv, LinkTelefone, ImgAjuda, GridBotoes, GridImg, DivBotao,
  DivPortalTransparencia, DivLinks, BalaoPortalTransparencia, Imagem, DivBalao, AnchorTelefone,
} from '../ContatosEstilo'
import {
  TELEFONE_AJUDA, LINK_PORTAL_TRANSPARENCIA_SESI, LINK_PORTAL_TRANSPARENCIA_SENAI,
} from '../../utils/Configuracoes'
import useWidthResize from '../../utils/useWidthResize'
import { LightBox } from '../../utils/UtilsEstilo'
import {
  IconeSetaBaixoPreenchidaSVG, IconePortalTransparenciaSVG,
} from '../../../assets'
// import BotaoWhatsApp from '../../utils/views/BotaoWhatsApp'

const TituloContatos = ({ menu, footer }) => {
  const { t } = useTranslation()
  const telaGrande = useWidthResize()

  if (footer && telaGrande) {
    return (
      <>
        <GridImg>
          <img src="/images/icone-head-phone-branco-fiemg.svg" alt={t('contatos.AJUDA')} width="18px" height="18px" />
        </GridImg>
        <TituloAjudaDiv footer>
          {t('contatos.VOCE_TEM')}
          <strong>{t('contatos.DUVIDA')}</strong>
        </TituloAjudaDiv>
      </>
    )
  }

  if (footer) {
    return (
      <TituloAjudaDiv footer>
        <img src="/images/icone-head-phone-branco-fiemg.svg" alt={t('contatos.AJUDA')} width="18px" height="18px" />
        {t('contatos.VOCE_TEM')}
        <strong>{t('contatos.DUVIDA')}</strong>
      </TituloAjudaDiv>
    )
  }

  if (!menu) {
    return (
      <TituloAjudaDiv>
        {t('contatos.VOCE_TEM')}
        <strong>{t('contatos.DUVIDA')}</strong>
      </TituloAjudaDiv>
    )
  }

  return null
}

TituloContatos.propTypes = {
  menu: propTypes.bool,
  footer: propTypes.bool,
}

TituloContatos.defaultProps = {
  menu: false,
  footer: false,
}

const ExibirLightBox = ({
  aberto, fecharPopUps,
}) => {
  if (aberto) {
    return (
      <LightBox
        ajuda
        onClick={() => fecharPopUps()}
      />
    )
  }
  return null
}

ExibirLightBox.propTypes = {
  aberto: propTypes.bool,
  fecharPopUps: propTypes.func,
}

ExibirLightBox.defaultProps = {
  aberto: false,
  fecharPopUps: undefined,
}

const ContatosAjuda = ({ menu, footer }) => {
  const { t } = useTranslation()
  const [abrirBalao, setAbrirBalao] = useState(false)

  function fecharBalao() {
    setAbrirBalao(false)
  }

  return (
    <div>
      <TituloContatos menu={menu} footer={footer} />
      <TextoAjudaDiv footer={footer} menu={menu}>
        {t('contatos.TEXTO')}
      </TextoAjudaDiv>
      <GridBotoes footer={footer}>
        {/* <BotaoWhatsApp /> */}
        <AnchorTelefone href={`tel:${TELEFONE_AJUDA}`}>
          <LinkTelefone menu={menu} footer={footer} tabIndex="-1">
            <DivBotao>
              <ImgAjuda src={footer ? '/images/icone-telefone-azul-fiemg.svg' : '/images/icone-telefone-branco-fiemg.svg'} alt={t('contatos.ALT_IMG_TELEFONE')} />
              <p>{t('contatos.TEL')}</p>
            </DivBotao>
          </LinkTelefone>
        </AnchorTelefone>
        {footer && (
          <DivPortalTransparencia
            footer={footer}
            tabIndex="0"
            onClick={() => setAbrirBalao(!abrirBalao)}
            onKeyPress={() => setAbrirBalao(!abrirBalao)}
          >
            <DivLinks footer={footer}>
              <IconePortalTransparenciaSVG />
              <Imagem>
                <p>{t('contatos.PORTAL_TRANSPARENCIA')}</p>
                <IconeSetaBaixoPreenchidaSVG />
              </Imagem>
            </DivLinks>
            <DivBalao>
              <BalaoPortalTransparencia aberto={abrirBalao}>
                <a
                  href={LINK_PORTAL_TRANSPARENCIA_SESI}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{t('contatos.SESI')}</p>
                </a>
                <a
                  href={LINK_PORTAL_TRANSPARENCIA_SENAI}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{t('contatos.SENAI')}</p>
                </a>
              </BalaoPortalTransparencia>
              <ExibirLightBox
                aberto={abrirBalao}
                fecharPopUps={fecharBalao}
              />
            </DivBalao>
          </DivPortalTransparencia>
        )}
      </GridBotoes>
    </div>
  )
}

ContatosAjuda.propTypes = {
  menu: propTypes.bool,
  footer: propTypes.bool,
}
ContatosAjuda.defaultProps = {
  menu: false,
  footer: false,
}

export default ContatosAjuda

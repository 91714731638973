const PtBr = {
  PESQUISE: 'Pesquise aqui uma solução',
  AREAS: 'Áreas',
  SOLUCOES: ' soluções ',
  ENCONTRADAS: ' encontradas',
  SOLUCAO: ' solução encontrada',
  LOCALIZACAO: 'Localizacao',
  DURACAO: 'Duracao',
  UNIDADES: ' unidades',
  DISPONIVEL: 'Disponível em ',
  FILTRO: 'Filtro',
  PARA: 'para',
  SUA_EMPRESA: 'Sua Empresa',
  PARA_SUA_EMPRESA: 'Para sua empresa',
  PRAZER_SOMOS_A: 'Muito prazer, somos a ',
  FIEMG: 'FIEMG',
  MUITO_PRAZER: 'muito prazer, ',
  SOMOS_A_FIEMG: 'somos a FIEMG',
  ALT_IMG_SOMOS_A_FIEMG: 'Imagem representativa para Somos a FIEMG',
  O_QUE_NOSSOS: 'O que nossos ',
  O_QUE_NOSSOS_MINUSCULO: 'o que nossos ',
  CLIENTES: ' clientes ',
  DIZEM: ' dizem... ',
  DESTAQUES: 'destaques',
  DEPOIMENTO_CLIENTE_1_SIAC: 'Utilizar o Sistema Fiemg em nossa operação na SIAC melhorou o atendimento ao nosso colaborador, seja na área de saúde, onde conseguimos atender a legislação e levar muita saúde ao funcionário, seja na área de treinamento com cursos e ferramentas que otimizam tempo e aceleram a aprendizagem. E se o atendimento ao nosso colaborador melhora, tudo na empresa melhora. O Sistema FIEMG nos ajudou a melhorar.',
  DEPOIMENTO_CLIENTE_2_WABTEC: 'Neste momento, contar com apoio de parceiros que possuem no DNA a Segurança e Saúde como valor prioritário é fundamental para fortalecer nossos mecanismos de defesas e mitigar ao máximo os impactos para as pessoas e negócios.',
  DEPOIMENTO_CLIENTE_3_VALE: 'Gostaria de agradecer a parceria estabelecida entre Vale e FIEMG. Temos somados esforços para alcançar um dos nossos objetivos, melhorar a qualidade de vida das pessoas por meio da Educação. Os retornos rápidos, as trocas de experiências e o pronto atendimento demonstrou a qualidade e competência que a instituição FIEMG preza. E vamos em frente!',
  TITLE_PAGINA: 'FIEMG - Para Sua Empresa',
  DESCRICAO_PAGINA: 'Encontre soluções, consultorias, treinamentos e serviços técnicos para sua empresa, pessoa juridica.',
  ATENDEMOS_TODO_ESTADO_MG: 'Atendemos todo o estado de Minas Gerais',
  ATENDEMOS_TODO_ESTADO_DE: 'Atendemos todo o estado de ',
  MINAS_GERAIS: 'Minas Gerais!',
}

export default PtBr

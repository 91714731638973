import { useEffect, useState } from 'react'
import { VALOR_RESOLUCAO_MAXIMA_MOBILE } from './Configuracoes'

const useWidthResize = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleWidthResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWidthResize)
    return () => {
      window.removeEventListener('resize', handleWidthResize)
    }
  }, [width])
  return width > VALOR_RESOLUCAO_MAXIMA_MOBILE
}

export default useWidthResize

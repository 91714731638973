// import PropTypes from 'prop-types'
import { t } from 'i18next'
import React from 'react'
import { useLocation } from 'react-router-dom'
import PaginasHeader from '../../paginas/views/PaginasHeader'
import { converteMaiusculaMinuscula } from '../../utils/Funcoes'
import Botao from '../../utils/views/Botao'
import MetaTagsComponente from '../../utils/views/MetaTagsComponente'
import {
  AreaAzulCotacao, CursoTitulo, DivContinuarInscricao,
  DivCotacaoConcluida, Divisor, TextoConcluirInscricao, TituloAzul,
  TituloPopover,
} from '../SolucoesPessoaFisicaEstilo'

const SolucoesPessoaFisicaCotacaoSucesso = () => {
  const { state: { solucao } } = useLocation()
  return (
    <DivCotacaoConcluida>
      <MetaTagsComponente
        titulo={t('solucoesPessoaFisica.TITLE_PAGINA_INSCRICAO_CONLCUIDA')}
        descricao={t('solucoesPessoaFisica.DESCRICAO_PAGINA_INSCRICAO')}
      />
      <PaginasHeader fundoBranco headerTransparente />
      <DivContinuarInscricao>
        <img src="/images/link-externo.svg" alt="" width="24" height="24" />
        <TituloPopover>
          {t('solucoesPessoaFisica.CONTINUE_INSCRICAO')}
        </TituloPopover>
        <TextoConcluirInscricao>
          {t('solucoesPessoaFisica.FALTA_POUCO')}
        </TextoConcluirInscricao>
        <AreaAzulCotacao>
          <TituloAzul semMargem>
            {t('solucoesPessoaFisica.CURSO')}
          </TituloAzul>
          <CursoTitulo>
            {converteMaiusculaMinuscula(solucao.nome)}
          </CursoTitulo>
        </AreaAzulCotacao>
        <Divisor />
        <TextoConcluirInscricao>
          {t('solucoesPessoaFisica.MAS_VOCE')}
        </TextoConcluirInscricao>
        <TextoConcluirInscricao>
          {t('solucoesPessoaFisica.AH_TAMBEM')}
        </TextoConcluirInscricao>
        <Botao
          action={() => open(solucao.ofertas[0].link, '_blank')}
          nome={t('solucoesPessoaFisica.BOTAO_REABRIR')}
          largura="100%"
          altura="40px"
          senha
        />
      </DivContinuarInscricao>
    </DivCotacaoConcluida>
  )
}

export default SolucoesPessoaFisicaCotacaoSucesso

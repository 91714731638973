import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Popover } from '@mui/material'
import { withStyles } from '@mui/styles'
import { RESOLUCAO_MAXIMA_MOBILE } from '../utils/Configuracoes'
import {
  BotaoDiv, DivConteudoSolucao, DivHeaderCategoria, DivTituloArea,
} from '../utils/UtilsEstilo'

export const DivHeaderSolucao = styled.div`
  width: 1024px;
  background:  ${(props) => (props.cor)};
  border-radius: 8px;
  align-self: center;
  margin-top: 62px;
`

export const DivImagemCategoria = styled.div`
  position: absolute;
  width: 240px;
  top: 84px;
  margin-left: 780px;
`

export const DivHeaderCategoriaFisica = styled(DivHeaderCategoria)`
  padding-top: 8px;
  width: 621px;
`
export const DivCargaHorario = styled.div`
  position: static;
  height: 20px;
  display: flex;
  margin-bottom: 8px;
  height: 35px;
  padding-left: 16px;
  -webkit-box-align: center;
  align-items: center;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-top: 80px;
    width: 290px;
  }
`

export const ItensGridModalidade = styled.div`
  display: flex;
`

export const BotaoHeader = styled.div`
  position: absolute;
  display: flex;
  width: inherit;
  justify-content: flex-end;
`

export const BotaoDivFisica = styled(BotaoDiv)`
  align-items: center;
  margin-right: 50px;
  margin-top: -48px;

  >button {
    margin-left: ${(props) => (props.indisponivel && '16px')};
  }
`

export const AlinhamentoBotaoFlex = styled.div`
  margin: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const DivConteudoSolucaoFisica = styled(DivConteudoSolucao)`
  padding-right: 32px;
  max-width: 1000px;
`

export const EsquerdaBody = styled.div`
  -ms-grid-row: 1; 
  -ms-grid-column: 3; 
  grid-row: 1; 
  grid-column: 3; 
  grid-area: esquerda; 
  width: 100%;
  justify-content: center;

  h1 {
    margin-top: 16px !important;
  }
`

export const ImagemHeaderArea = styled.img`
  margin-right: 4px;
  width: 20px;
  height: 20px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin-left: 4px;
    margin-right: 0;
  }
`

export const DivSolucaoHeaderArea = styled.div`
  height: 32px;
  width: 100%;
  margin-top: 4px;
  display: -ms-grid;
  display: grid;
  font-size: 14px;
  -ms-grid-columns: 40px 1fr;
  grid-template-areas: "img area" "img categoria";
  -ms-grid-rows: 1fr;
  color: #9053AA;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    border-radius: 8px;
    font-size: 12px;
    background: #FFFFFF; 
    box-shadow: 0px 0px 5px rgba(40, 67, 91, 0.35);
    width: max-content;
    padding-right: 8px;
    height: 32px;
    display: flex;
    margin-top: 10px;
    margin-left: 16px;
    align-items: center;
  }
`

export const DivTituloAreaFisica = styled(DivTituloArea)`
  padding-left: 8px;
  color: #9053AA;
`

export const TituloCategoria = styled(Link)`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #9053AA;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding-left: 8px;
  }
`

export const DescricaoSolucao = styled.div`
  margin: 16px 16px 20px 0px;
  color: #2D2F32;
  font-size: 16px;
  line-height: 26px;
  text-align: left;

  p {
    margin: 0;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    margin: 16px;
    font-size: 14px;
    line-height: 22px;
  }
`

export const DireitaBody = styled.div`
  padding-left: 24px !important;
  -ms-grid-row: 1; 
  -ms-grid-column: 5; 
  grid-row: 1; 
  grid-column: 5; 
  grid-area: direita; 
  width: 100%;
  margin-top: 20px;
`

export const CardInfoSolucao = styled.div`
  width: 296px;
  float: right;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(40, 67, 91, 0.35);
  border-radius: 8px;
  padding: 16px;
  position: relative;
  top: ${(props) => (props.ajustePosicao && '-36px')};
`

export const TituloCardSolucao = styled.h2`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #5CB3E8;
  margin: 0 0 8px;
  text-align: center;
`

export const DivSeparadorCardUnidade = styled.div`
  border-bottom: 1px solid #EAEEF4;
  margin: 0px 8px;
  margin: 0px;
  width: calc(100% - 4px);
`

export const DivDetalheUnidadesValor = styled.div`
  display: flex;
  justify-content: center;
  align-items: bottom;
  height: 35px;
  font-family: Source Sans Pro;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
`

export const TextoValor = styled.div`
  padding-top: 16px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #90949A;
`

export const TextoCifrao = styled.div`
  padding-top: 14px;
  padding-left: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #348DCF;
`

export const TextoValorNumero = styled.div`
  padding-top: 40px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #348DCF;
`

export const DivSeparadorCardMultiplasUnidades = styled.div`
  border-bottom: 1px solid #EAEEF4;
  margin: 0 4px 0 12px;
`

export const DivDetalheMultiplasUnidades = styled.div`
  display: flex;
  padding-left: 12px;
  justify-content: left;
  align-items: bottom;
  height: 50px;
  font-family: Source Sans Pro;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
`

const TextoValorUnidadesPai = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: left;
  text-align: left;

  color: #90949A;
`

export const TextoValorUnidades = styled(TextoValorUnidadesPai)`
  padding-top: 24px;
`

const TextoCifraoUnidadesPai = styled.div`  
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: left;
  text-align: left;
  color: #348DCF;
`

export const TextoCifraoUnidadesMobile = styled(TextoCifraoUnidadesPai)`
  position: absolute;
  bottom: 12px;
`

export const TextoCifraoUnidades = styled(TextoCifraoUnidadesPai)`
  padding-top: 22px;
  padding-left: 4px;
`

export const TextoValorNumeroUnidades = styled(TextoValorNumero)`
  padding-bottom: 4px;
  padding-left: 2px;
  padding-top: unset;
`

export const TextoValorNumeroUnidadesMobile = styled(TextoValorNumero)`
  position: absolute;
  bottom: 8px;
  padding-left: 20px;
  padding-top: unset;
`

export const TextoValorUnidadesMobile = styled(TextoValorUnidadesPai)`
  position: absolute;
  top: 10px;
`

export const ValorCardMobile = styled(TextoValorUnidadesPai)`
  position: relative;
  padding-top: 1px;
  left: 20px;
`

export const CifraoCardMobile = styled.div`
  position: relative;
  padding-top: 2px;
  left: 20px;
  padding-left: 2px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: left;
  text-align: left;
  color: #348DCF;
`

export const ValorNumeroCardMobile = styled.div`
  position: relative;
  padding-left: 20px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #348DCF;
`

const DivLocalizacaoCotacaoWeb = styled.div`
  position: relative;
  height: 100%;
`

export const ImagemUnidadePF = styled.img`
  grid-row: 1; 
  grid-column: 1; 
  grid-area: imagem; 
  -ms-grid-row: 1; 
  -ms-grid-column: 1;
  width: 18px;
  height: 18px;
`

export const DivBotaoFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -15px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding: 0 16px 8px 8px;
  }
`

export const DivInputEmail = styled.div`
  padding: 0 13px 0 4px;
`

export const DivIndisponivel = styled.div`
  display: flex;
  margin: 8px 0 0 8px;
`

export const CursoIndisponivel = styled.div`
  background-color: #EAEEF4;
  color: #636669;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  height: 32px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 14px;
`

export const PopoverDesktop = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 304px;
  height: 180px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(40, 67, 91, 0.4);
  border-radius: 8px;
  margin-top: 16px;
  padding: 16px;
`

export const DivMobile = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.aberto ? '24px 4px 4px 4px' : '0px')};
  height: ${(props) => (props.aberto ? '104px' : '0px')};
  transform: ${(props) => (props.aberto ? 'scaleY(1)' : 'scaleY(0)')};
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
`

export const SetaPopover = styled.div`
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #FFFFFF;
`

export const StyledPopover = withStyles({
  paper: {
    borderRadius: '0',
    width: '400px',
    height: '208px',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})(Popover)

export const TituloPopover = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #2D2F32;
`

export const Flex = styled.div`
  display: flex;
  width: 100%;
`

export const ImgEmail = styled.img`
  width: 18px;
  height: 14px;
  margin-right: 16px;
`

export const TextoPopover = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #636669;
  padding: 0 32px 16px;
`

export const DivCentralizada = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => (props.margem)};
`

export const Divisor = styled.div`
  height: 1px;
  width: 100%;
  background: #EAEEF4;
  margin: ${(props) => (props.semMargem ? 0 : '16px 0')};
`

export const TextoRequisito = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #90949A;
  margin: 0 8px;
`

export const DivRequisito = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
`

export const CardTurma = styled.div`
  width: ${(props) => (props.mobile ? '100%' : '322px')};
  margin-right: ${(props) => (props.mobile ? '0' : '16px')};
  background: #FFFFFF;
  border: 1px solid #EAEEF4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :nth-child(3n) { 
    margin-right: 0;
  }
`

export const GridCardsTurma = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 64px;
`

export const TituloCardTurma = styled.h2`
  color: #348DCF;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  ${(props) => (!props.mobile && 'min-height: 40px')};
`

export const PrecoAzul = styled.div`
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  color: #348DCF;
  font-size: 16px;
  font-weight: 600;
`

export const TextoParcelas = styled.span`
  color: #2D2F32;
  font-size: 16px;
`

export const DivValores = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const DivValoresMobile = styled.div`
  margin-left: 16px;
`

export const DivTurmasMobile = styled.div`
  box-sizing: border-box;
  padding: 0 16px;
`

export const NomeCidade = styled.div`
  color: #90949A;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 16px;
`

export const DivAreaAzul = styled.div`
  background: #F5F6F9;
  margin: 16px;
  padding: 8px;
  border-radius: 8px;
  h2 {
    padding: 8px;
  }
`

export const LinkCotacao = styled(Link)`
  top: -25px;
  margin-right: 50px;
  position: relative;
  text-decoration: none;
`

export const LinkCotacaoTurmas = styled(Link)`
  width: 100%;
  text-decoration: none;
`

export const LinkCotacaoMobile = styled(LinkCotacaoTurmas)`
  width: auto;
  display: block;
  margin-right: 16px;
`

export const ConteudoCotacao = styled.div`
  width: 1024px;
  margin: auto;
  margin-bottom: 32px;

  >div {
    display: flex;
  }
`

export const TituloCotacao = styled.h1`
  font-size: 24px;
  line-height: 30px;
  font-weight: normal;
  color: #000;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 18px;
    text-align: center;
  }
`

export const TituloAzul = styled.h2`
  color: #348DCF;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin: ${(props) => (props.semMargem && 0)};
`

export const DivInput = styled.div`
  width: 280px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
  }
`

export const DivRecaptcha = styled.div`
  margin-top: 40px;
  display: flex;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    flex-direction: column;
    margin-top: 8px;
  }
`

export const CotacaoEsquerda = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const CotacaoDireita = styled.div`
  width: 672px;
  height: fit-content;
  margin-left: 32px;
  margin-top: -46px;
  border: 1px solid #EAEEF4;
  border-radius: 8px;
  padding: 8px;
`

export const TituloCardCotacao = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #636669;
  margin-top: -16px;
  margin-bottom: 16px;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    font-size: 16px;
    color: #2D2F32;
  }
`

export const AreaAzulCotacao = styled.div`
  background: #F5F6F9;
  border-radius: 8px;
  padding: 8px !important;
  margin: ${(props) => (props.margin)};

  >p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: #90949A;
  }
`

export const TituloAreaAzul = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #348DCF;
  margin-top: 0;
`

export const InfoAreaAzulCotacao = styled.div`
  display: flex;
  margin-top: 8px;
  
  img {
    margin-right: 8px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: #90949A;

    span {
      color: #348DCF;
      font-weight: 600;
    }
  }
`

export const DivValor = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  p {
    margin: 0;
    color: #90949A;
    font-size: 16px;
    line-height: 18px;
  }
`

export const NumeroParcelas = styled.span`
  color: #2D2F32;
  font-size: 24px;
`

export const Cifrao = styled.span`
  color: ${(props) => (props.cinza ? '#90949A' : '#348DCF')};
  font-size: 16px;
`

export const Valor = styled.span`
  color: ${(props) => (props.cinza ? '#90949A' : '#348DCF')};
  font-size: 32px;
  font-weight: 600;
`

export const DivAlerta = styled.div`
  border: 1px solid #E0D642;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;

  div {
    display: flex;
    :nth-child(2n) {
      margin-top: 8px;
    }
  }

  img {
    margin-right: 8px;
  }

  p {
    margin: 0;
    color: #2D2F32;
    font-size: 14px;
    line-height: 18px;
  }
`

export const DivAlertaAzul = styled(DivAlerta)`
  border: 1px solid #5CB3E8;
`

export const DivBotaoAnterior = styled.div`
  margin: 32px 0 14px -15px;
`

export const DivStepper = styled.div`
  display: flex;
  justify-content: center;
`

export const DivCotacaoMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 132px);
  background: #fff;
`

export const DivBotoesMobile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-bottom: 96px;

  @supports (-webkit-touch-callout: none) {
    margin-bottom: 120px;
  }
`

export const DivResumoCotacao = styled.div`
  margin: 16px;
`

export const DivCotacaoConcluida = styled.div`
  background: #F5F6F9;
  height: 100vh;

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    background: #fefefe;
  }
`

export const DivContinuarInscricao = styled.div`
  width: 416px;
  min-height: 422px;
  background: #fefefe;
  margin: 40px auto;
  padding: 16px 67px;
  border: 1px solid #EAEEF4;
  box-sizing: border-box;
  border-radius: 8px;

  img {
    display: block;
    margin: 0 auto 16px;
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    width: 100%;
    height: auto;
    padding: 32px 16px;
    margin: 0;
    border: none;
    border-radius: 0;
  }
`

export const CursoTitulo = styled(TextoParcelas)`
  font-weight: 600;
`

export const TextoConcluirInscricao = styled(TextoPopover)`
  padding: 0;
`

export const TextoTurmasDisponiveis = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #2D2F32;
  text-align: center;
  margin: 16px;
`

export const DivQueroSerAvisado = styled.div`
  height: 100%;

  >span {
    height: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;

    @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
      height: 210px;
      margin: 0;
    }

    >p {
      color: #636669;
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      margin: 0 32px;
    }
  }

  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    height: auto;
  }
`

export const BotaoFechar = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 16px;
`

export const CabecalhoQueroSerAvisado = styled.div`
  display: flex;
  align-items: center;
  top: 10px;
  position: relative;
  justify-content: center;
`

export const EmailConfirmado = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #2D2F32;
`

export const FundoAzulSugestoes = styled.div`
  background: #F5F6F9;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  @media (max-width: ${RESOLUCAO_MAXIMA_MOBILE}) {
    padding: 24px 0 0;
  }
`

export default DivLocalizacaoCotacaoWeb

import React from 'react'
import propTypes from 'prop-types'

const UsuarioHeaderSVG = ({ selecionado }) => {
  if (selecionado) {
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="#5CB3E8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16 16C12.9318 16 10.4444 13.5127 10.4444 10.4445C10.4444 7.37622 12.9318 4.88892 16 4.88892C19.0682 4.88892 21.5556 7.37622 21.5556 10.4445C21.5556 13.5127 19.0682 16 16 16ZM26 26V23.7778C26 20.7096 23.5127 18.2222 20.4444 18.2222H11.5556C8.48731 18.2222 6 20.7096 6 23.7778V26C6 26.6137 6.49746 27.1111 7.11111 27.1111C7.72476 27.1111 8.22222 26.6137 8.22222 26V23.7778C8.22222 21.9369 9.71461 20.4445 11.5556 20.4445H20.4444C22.2854 20.4445 23.7778 21.9369 23.7778 23.7778V26C23.7778 26.6137 24.2752 27.1111 24.8889 27.1111C25.5025 27.1111 26 26.6137 26 26ZM19.3333 10.4445C19.3333 12.2854 17.8409 13.7778 16 13.7778C14.1591 13.7778 12.6667 12.2854 12.6667 10.4445C12.6667 8.60352 14.1591 7.11114 16 7.11114C17.8409 7.11114 19.3333 8.60352 19.3333 10.4445Z" fill="#FEFEFE" />
        <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="6" y="4" width="20" height="24">
          <path fillRule="evenodd" clipRule="evenodd" d="M16 16C12.9318 16 10.4444 13.5127 10.4444 10.4445C10.4444 7.37622 12.9318 4.88892 16 4.88892C19.0682 4.88892 21.5556 7.37622 21.5556 10.4445C21.5556 13.5127 19.0682 16 16 16ZM26 26V23.7778C26 20.7096 23.5127 18.2222 20.4444 18.2222H11.5556C8.48731 18.2222 6 20.7096 6 23.7778V26C6 26.6137 6.49746 27.1111 7.11111 27.1111C7.72476 27.1111 8.22222 26.6137 8.22222 26V23.7778C8.22222 21.9369 9.71461 20.4445 11.5556 20.4445H20.4444C22.2854 20.4445 23.7778 21.9369 23.7778 23.7778V26C23.7778 26.6137 24.2752 27.1111 24.8889 27.1111C25.5025 27.1111 26 26.6137 26 26ZM19.3333 10.4445C19.3333 12.2854 17.8409 13.7778 16 13.7778C14.1591 13.7778 12.6667 12.2854 12.6667 10.4445C12.6667 8.60352 14.1591 7.11114 16 7.11114C17.8409 7.11114 19.3333 8.60352 19.3333 10.4445Z" fill="white" />
        </mask>
        <g mask="url(#mask0)">
          <rect x="2.66675" y="2.66675" width="26.6667" height="26.6667" fill="#FEFEFE" />
        </g>
      </svg>
    )
  }
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 16C12.9318 16 10.4444 13.5127 10.4444 10.4445C10.4444 7.37622 12.9318 4.88892 16 4.88892C19.0682 4.88892 21.5556 7.37622 21.5556 10.4445C21.5556 13.5127 19.0682 16 16 16ZM26 26V23.7778C26 20.7096 23.5127 18.2222 20.4444 18.2222H11.5556C8.48731 18.2222 6 20.7096 6 23.7778V26C6 26.6137 6.49746 27.1111 7.11111 27.1111C7.72476 27.1111 8.22222 26.6137 8.22222 26V23.7778C8.22222 21.9369 9.71461 20.4445 11.5556 20.4445H20.4444C22.2854 20.4445 23.7778 21.9369 23.7778 23.7778V26C23.7778 26.6137 24.2752 27.1111 24.8889 27.1111C25.5025 27.1111 26 26.6137 26 26ZM19.3333 10.4445C19.3333 12.2854 17.8409 13.7778 16 13.7778C14.1591 13.7778 12.6667 12.2854 12.6667 10.4445C12.6667 8.60352 14.1591 7.11114 16 7.11114C17.8409 7.11114 19.3333 8.60352 19.3333 10.4445Z" fill="#5CB3E8" />
      <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="6" y="4" width="20" height="24">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 16C12.9318 16 10.4444 13.5127 10.4444 10.4445C10.4444 7.37622 12.9318 4.88892 16 4.88892C19.0682 4.88892 21.5556 7.37622 21.5556 10.4445C21.5556 13.5127 19.0682 16 16 16ZM26 26V23.7778C26 20.7096 23.5127 18.2222 20.4444 18.2222H11.5556C8.48731 18.2222 6 20.7096 6 23.7778V26C6 26.6137 6.49746 27.1111 7.11111 27.1111C7.72476 27.1111 8.22222 26.6137 8.22222 26V23.7778C8.22222 21.9369 9.71461 20.4445 11.5556 20.4445H20.4444C22.2854 20.4445 23.7778 21.9369 23.7778 23.7778V26C23.7778 26.6137 24.2752 27.1111 24.8889 27.1111C25.5025 27.1111 26 26.6137 26 26ZM19.3333 10.4445C19.3333 12.2854 17.8409 13.7778 16 13.7778C14.1591 13.7778 12.6667 12.2854 12.6667 10.4445C12.6667 8.60352 14.1591 7.11114 16 7.11114C17.8409 7.11114 19.3333 8.60352 19.3333 10.4445Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <rect x="2.66675" y="2.66675" width="26.6667" height="26.6667" fill="#5CB3E8" />
      </g>
    </svg>
  )
}

UsuarioHeaderSVG.propTypes = {
  selecionado: propTypes.bool,
}

UsuarioHeaderSVG.defaultProps = {
  selecionado: false,
}

export default UsuarioHeaderSVG

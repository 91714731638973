const PtBr = {
  FILTROS: 'Filtros',
  LIMPAR_FILTRO: 'Limpar Filtro',
  LIMPAR: 'Limpar',
  LOCALIZACAO: 'LOCALIZAÇÃO',
  SOMENTE: 'Somente ',
  SOMENTE_EM: 'Somente em ',
  RESULTADOS: 'Ver Resultados',
  INFORMACAO_FILTRO_NOTICIA: 'Para filtrar os resultados de notícia, selecione um tópico ou uma tag.',
  TIPO_CURSO: 'TIPO DE CURSO',
  TIPO_SOLUCAO: 'TIPO DE SOLUÇÃO',
}

export default PtBr

import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import Inicio, { Provider } from './features/paginas'
import './features/i18n'
import './index.scss'

const tagManagerArgs = {
  gtmId: 'GTM-5PZXKX6',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider>
    <SnackbarProvider maxSnack={3}>
      <Inicio />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
)

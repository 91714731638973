import { ENDPOINT_MENU_AREAS, ENDPOINT_BUSCAR_SOLUCOES, ENDPOINT_DESTAQUES } from '../utils/Configuracoes'
import ConectarApiPost, { ConectarApiGet } from '../utils/ConectarApi'
import { removerAcentos, removerMultiplosEspacos } from '../utils/Funcoes'

export default async function MenuAreasService(categoriasComSolucoes = false) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(`${ENDPOINT_MENU_AREAS}?categoriasComSolucoes=${categoriasComSolucoes}`)

    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function BuscarSolucoesService(campoBusca) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiPost(ENDPOINT_BUSCAR_SOLUCOES, {
      valor: removerMultiplosEspacos(removerAcentos(campoBusca)),
    })

    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function BuscarDestaquesService() {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(ENDPOINT_DESTAQUES)

    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import {
  LinkedinSVG, TwitterSVG, YoutubeSVG, InstagramSVG, FacebookSVG, ChatSVG,
} from '../../../assets'
import {
  ImgRedesSociais, TituloRedesSociais, RedesGrid, IconeRedeSocial, ImgRedeSocial, DivGeral,
  DivOuvidoria, RedesSociaisDiv, TituloRedesMobileDiv, FaleConoscoLink, DivIcone, DivFaleConosco,
} from '../ContatosEstilo'
import useWidthResize from '../../utils/useWidthResize'
import {
  FACEBOOK_FIEMG, INSTAGRAM_FIEMG, TWITTER_FIEMG, YOUTUBE_FIEMG, TELEFONE_GERAL, TELEFONE_OUVIDORIA,
  LINKEDIN_FIEMG,
} from '../../utils/Configuracoes'

const ObterImagemInstagram = ({ hover }) => {
  if (hover) {
    return (
      <ImgRedeSocial src="/images/instagramHover.svg" alt="instagram" />
    )
  }
  return (
    <InstagramSVG />
  )
}

ObterImagemInstagram.propTypes = {
  hover: propTypes.bool,
}

ObterImagemInstagram.defaultProps = {
  hover: false,
}

const ContatosRedesSociais = () => {
  const { t } = useTranslation()
  const telaGrande = useWidthResize()
  const [imgHover, setImgHover] = useState(false)

  return (
    <RedesSociaisDiv>
      {telaGrande ? (
        <>
          <ImgRedesSociais>
            <img src="/images/icone-add-contato-fiemg.svg" alt={t('contatos.ALT_IMG_ADD_CONTATO')} width="18px" height="16px" />
          </ImgRedesSociais>
          <TituloRedesSociais>{t('contatos.SIGA')}</TituloRedesSociais>
        </>
      ) : (
        <TituloRedesMobileDiv>
          <img src="/images/icone-add-contato-fiemg.svg" alt={t('contatos.ALT_IMG_ADD_CONTATO')} width="18px" height="16px" />
          <TituloRedesSociais>{t('contatos.SIGA')}</TituloRedesSociais>
        </TituloRedesMobileDiv>
      )}
      <RedesGrid>
        <a href={FACEBOOK_FIEMG} target="_blank" rel="noopener noreferrer">
          <IconeRedeSocial cor="#4267B2">
            <FacebookSVG />
          </IconeRedeSocial>
        </a>
        <a href={INSTAGRAM_FIEMG} target="_blank" rel="noopener noreferrer">
          <IconeRedeSocial
            onMouseOut={() => setImgHover(false)}
            onMouseOver={() => setImgHover(true)}
          >
            <ObterImagemInstagram hover={imgHover} />
          </IconeRedeSocial>
        </a>
        <a href={TWITTER_FIEMG} target="_blank" rel="noopener noreferrer">
          <IconeRedeSocial cor="#1DA1F2">
            <TwitterSVG />
          </IconeRedeSocial>
        </a>
        <a href={YOUTUBE_FIEMG} target="_blank" rel="noopener noreferrer">
          <IconeRedeSocial cor="#F80400">
            <YoutubeSVG />
          </IconeRedeSocial>
        </a>
        <a href={LINKEDIN_FIEMG} target="_blank" rel="noopener noreferrer">
          <IconeRedeSocial cor="#0173AF">
            <LinkedinSVG />
          </IconeRedeSocial>
        </a>
      </RedesGrid>
      <DivGeral href={t('geral.routes.URL_UNIDADES')}>
        <img src="/images/unidade.svg" alt={t('contatos.ALT_IMG_TELEFONE')} width="15px" height="15px" />
        {t('utils.CONHECA_NOSSAS_UNIDADES')}
      </DivGeral>
      <DivOuvidoria>
        <img src="/images/icone-telefone-branco-fiemg.svg" alt={t('contatos.ALT_IMG_TELEFONE')} width="20px" height="20px" />
        {t('contatos.GERAL') + TELEFONE_GERAL}
      </DivOuvidoria>
      <DivOuvidoria>
        <img src="/images/icone-telefone-branco-fiemg.svg" alt={t('contatos.ALT_IMG_TELEFONE')} width="20px" height="20px" />
        {t('contatos.OUVIDORIA') + TELEFONE_OUVIDORIA}
      </DivOuvidoria>
      <FaleConoscoLink to={`/${t('geral.routes.URL_FALE_CONOSCO')}`}>
        <DivFaleConosco>
          <DivIcone>
            <ChatSVG />
          </DivIcone>
          {t('contatos.FALE_CONOSCO')}
        </DivFaleConosco>
      </FaleConoscoLink>
    </RedesSociaisDiv>
  )
}

export default ContatosRedesSociais

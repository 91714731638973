const PtBr = {
  PESQUISE: 'Pesquise uma Solução nessa área',
  TITLE_PAGINA: 'FIEMG - ',
  DESCRICAO_PAGINA: 'Veja soluções consultorias, treinamentos e serviços técnicos SENAI, SESI, IEL, CIEMG. Para a categoria ',
  ALT_IMG_CATEGORIA: 'Imagem representativa da FIEMG para a categoria ',
  ATENDEMOS_TODO_ESTADO: 'Atendemos todo o estado ',
  DE_MINAS_GERAIS: ' de Minas Gerais ',
}

export default PtBr

import jwtDecode from 'jwt-decode'
import { Cookies } from 'react-cookie'
import CryptoJS from 'crypto-js'
import jwtEncode from 'jwt-encode'
import {
  PREFIXO_EXCECAO, CODIGO_EDUCACAO, CODIGO_INOVACAO, CODIGO_SAUDE,
  COR_FUNDO, COR_EDUCACAO, COR_INOVACAO, COR_SAUDE, CODIGO_GESTAO,
  COR_GESTAO, COR_GRADIENTE_EDUCACAO_PROFISSIONAL, COOKIE_DADOS, COR_PRIMARIA_EDUCACAO_PROFISSIONAL,
} from './Configuracoes'

export function dispararErro(mensagemErro) {
  throw new Error(`${PREFIXO_EXCECAO} ${mensagemErro}`)
}

export function telefoneEhValido(string) {
  const numsStr = string.replace(/[^0-9]/g, '')
  if (numsStr.length < 10) {
    return false
  }
  if (numsStr[2] === '9' && numsStr.length < 11) {
    return false
  }
  return true
}

export function converteMaiusculaMinuscula(texto, tamanhoCortePalvras) {
  if (!texto) {
    return ''
  }

  const loweredText = texto.toLowerCase().trim()
  const words = loweredText.split(/\s+/)

  for (let a = 0; a < words.length; a += 1) {
    let w = words[a]
    const firstLetter = w[0]
    if (w.length > 2) {
      w = firstLetter.toUpperCase() + w.slice(1)
    } else {
      w = firstLetter + w.slice(1)
    }
    if (w.includes('Nr-') || w === 'Nr11' || w === 'nr' || w === 'r' || w === 'av' || w === 'Cep:'
    || w === 'Lgpd' || w === 'Iot' || w === 'c') {
      w = w.toUpperCase()
    }
    words[a] = w === 'undefined' ? words[a] : w
  }
  let textoFormatado = words.join(' ')
  if (tamanhoCortePalvras && textoFormatado.length > tamanhoCortePalvras) {
    textoFormatado = `${textoFormatado.substring(0, tamanhoCortePalvras - 3)}...`
  }

  return textoFormatado
}

export function cortarTexto(texto, tamanhoCortePalvras) {
  let textoFormatado = texto
  if (texto && tamanhoCortePalvras && texto.length > tamanhoCortePalvras) {
    textoFormatado = `${texto.substring(0, tamanhoCortePalvras - 3)}...`
  }
  return textoFormatado
}

export function removerAcentos(string) {
  const map = {
    â: 'a', Â: 'A', à: 'a', À: 'A', á: 'a', Á: 'A', ã: 'a', Ã: 'A', ê: 'e', Ê: 'E', è: 'e', È: 'E', é: 'e', É: 'E', î: 'i', Î: 'I', ì: 'i', Ì: 'I', í: 'i', Í: 'I', õ: 'o', Õ: 'O', ô: 'o', Ô: 'O', ò: 'o', Ò: 'O', ó: 'o', Ó: 'O', ü: 'u', Ü: 'U', û: 'u', Û: 'U', ú: 'u', Ú: 'U', ù: 'u', Ù: 'U', ç: 'c', Ç: 'C',
  }
  return string.replace(/[\W[\] ]/g, (a) => map[a] || a)
}

export function removerMultiplosEspacos(string) {
  return string.replace(/\s\s+/g, ' ').trim()
}

export function obterCorLabelsPorArea(codigo) {
  if (codigo === CODIGO_EDUCACAO) {
    return COR_EDUCACAO
  }
  if (codigo === CODIGO_INOVACAO) {
    return COR_INOVACAO
  }
  if (codigo === CODIGO_SAUDE) {
    return COR_SAUDE
  }
  if (codigo === CODIGO_GESTAO) {
    return COR_GESTAO
  }
  return COR_FUNDO
}

export function obterIconePorArea(codigo, tamanho) {
  if (codigo === CODIGO_EDUCACAO) {
    return `/images/icone-educacao-e-capacitacao-fiemg-w${tamanho}.svg`
  }
  if (codigo === CODIGO_INOVACAO) {
    return `/images/icone-inovacao-e-tecnologia-fiemg-w${tamanho}.svg`
  }
  if (codigo === CODIGO_SAUDE) {
    return `/images/icone-saude-e-qualidade-de-vida-fiemg-w${tamanho}.svg`
  }
  if (codigo === CODIGO_GESTAO) {
    return `/images/icone-gestao-de-negocios-w${tamanho}.svg`
  }
  return null
}

export function obterCorPrimariaGradienteSolucoesPF() {
  return COR_GRADIENTE_EDUCACAO_PROFISSIONAL
}

export function obterCorPrimariaSolucoesPF() {
  return COR_PRIMARIA_EDUCACAO_PROFISSIONAL
}

export function obterIconeComSombraPorArea(codigo) {
  if (codigo === CODIGO_EDUCACAO) {
    return '/images/icone-educacao-e-capacitacao-fiemg-sombra.svg'
  }
  if (codigo === CODIGO_INOVACAO) {
    return '/images/icone-inovacao-e-tecnologia-fiemg-sombra.svg'
  }
  if (codigo === CODIGO_SAUDE) {
    return '/images/icone-saude-e-qualidade-de-vida-fiemg-sombra.svg'
  }
  if (codigo === CODIGO_GESTAO) {
    return '/images/icone-gestao-de-negocios-sombra.svg'
  }
  return null
}

export function obterIconeArea(codArea, nivelCapacitacao) {
  switch (codArea) {
    case 1:
      return `/images/alimentosbebidas-${nivelCapacitacao}.svg`
    case 2:
      return `/images/audiovisual-${nivelCapacitacao}.svg`
    case 3:
      return `/images/automacao-${nivelCapacitacao}.svg`
    case 4:
      return `/images/automotiva-${nivelCapacitacao}.svg`
    case 5:
      return `/images/biocombustiveis-${nivelCapacitacao}.svg`
    case 6:
      return `/images/construcaocivil-${nivelCapacitacao}.svg`
    case 7:
      return `/images/courocalc-${nivelCapacitacao}.svg`
    case 8:
      return `/images/eletronica-${nivelCapacitacao}.svg`
    case 9:
      return `/images/energia-${nivelCapacitacao}.svg`
    case 10:
      return `/images/joalheria-${nivelCapacitacao}.svg`
    case 11:
      return `/images/gestao-${nivelCapacitacao}.svg`
    case 12:
      return `/images/graficamidiasdig-${nivelCapacitacao}.svg`
    case 13:
      return `/images/logistica-${nivelCapacitacao}.svg`
    case 14:
      return `/images/madeiramob-${nivelCapacitacao}.svg`
    case 15:
      return `/images/meioambiente-${nivelCapacitacao}.svg`
    case 16:
      return `/images/metalmecanica-${nivelCapacitacao}.svg`
    case 17:
      return `/images/mineracao-${nivelCapacitacao}.svg`
    case 18:
      return `/images/polimeros-${nivelCapacitacao}.svg`
    case 19:
      return `/images/quimica-${nivelCapacitacao}.svg`
    case 20:
      return `/images/refrigclim-${nivelCapacitacao}.svg`
    case 21:
      return `/images/segurancatrab-${nivelCapacitacao}.svg`
    case 22:
      return `/images/tecinfo-${nivelCapacitacao}.svg`
    case 23:
      return `/images/telecom-${nivelCapacitacao}.svg`
    case 24:
      return `/images/transporteferrov-${nivelCapacitacao}.svg`
    case 25:
      return `/images/vestuario-${nivelCapacitacao}.svg`
    default: return null
  }
}

export function obterIconeTema(codTema) {
  if (codTema === 1) {
    return '/images/iconeTemaCultura.svg'
  }
  if (codTema === 2) {
    return '/images/iconeTemaEducacao.svg'
  }
  if (codTema === 3) {
    return '/images/iconeTemaSustentabilidae.svg'
  }
  if (codTema === 4) {
    return '/images/iconeTemaInovacao.svg'
  }
  if (codTema === 5) {
    return '/images/iconeTemaAssessoria.svg'
  }
  if (codTema === 6) {
    return '/images/iconeTemaCapacitacao.svg'
  }
  if (codTema === 7) {
    return '/images/iconeTemaDesenvolvimento.svg'
  }
  if (codTema === 8) {
    return '/images/iconeTemaEsporte.svg'
  }
  if (codTema === 9) {
    return '/images/iconeTemaEstudosEconomicos.svg'
  }
  if (codTema === 10) {
    return '/images/iconeTemaNegociosAtracao.svg'
  }
  if (codTema === 11) {
    return '/images/iconeTemaNecogiosInernacional.svg'
  }
  if (codTema === 12) {
    return '/images/iconeTemaSaude.svg'
  }
  return ''
}

export function ordenarArrayPorChave(array, chave) {
  if (array && array.length > 0) {
    return array.sort((a, b) => {
      if (a[chave] > b[chave] || a[chave] === undefined || a[chave] === null) {
        return 1
      }
      if (a[chave] < b[chave] || b[chave] === undefined || b[chave] === null) {
        return -1
      }
      return 0
    })
  } return null
}

export function ordenarArrayDeStringsNumericas(array, chave) {
  if (array && array.length > 0) {
    return array.sort((a, b) => {
      const aFormatado = parseInt(a[chave]?.replace('.', ''), 10)
      const bFormatado = parseInt(b[chave]?.replace('.', ''), 10)

      if (aFormatado > bFormatado || isNaN(aFormatado)) {
        return 1
      }
      if (aFormatado < bFormatado || isNaN(bFormatado)) {
        return -1
      }
      return 0
    })
  } return null
}

export function validarCNPJ(param) {
  if (!param) return false

  const cnpj = param.replace(/[^\d]+/g, '')

  if (!cnpj) return false

  if (cnpj.length !== 14) { return false }

  if (cnpj === '00000000000000'
    || cnpj === '11111111111111'
    || cnpj === '22222222222222'
    || cnpj === '33333333333333'
    || cnpj === '44444444444444'
    || cnpj === '55555555555555'
    || cnpj === '66666666666666'
    || cnpj === '77777777777777'
    || cnpj === '88888888888888'
    || cnpj === '99999999999999') { return false }

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos
    pos -= 1
    if (pos < 2) { pos = 9 }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(0), 10)) { return false }

  tamanho += 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos
    pos -= 1
    if (pos < 2) { pos = 9 }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(1), 10)) { return false }

  return true
}

export function validarCep(cep) {
  if (!cep) return false

  const objER = /^[0-9]{5}-[0-9]{3}$/

  const strCEP = cep.replace(/[^\d]+/g, '')

  if (parseInt(strCEP, 10) < 1000000) {
    return false
  }

  if (cep.length > 0 && objER.test(cep)) {
    return true
  }

  return false
}

export function validarEmail(email) {
  if (!email) return false

  const usuario = email.substring(0, email.indexOf('@'))
  const dominio = email.substring(email.indexOf('@') + 1, email.length)

  if ((usuario.length >= 1)
    && (dominio.length >= 3)
    && (usuario.search('@') === -1)
    && (dominio.search('@') === -1)
    && (usuario.search(' ') === -1)
    && (dominio.search(' ') === -1)
    && (dominio.search('.') !== -1)
    && (dominio.indexOf('.') >= 1)
    && (dominio.lastIndexOf('.') < dominio.length - 1)) {
    return true
  }
  return false
}

export function validarCampoObrigatorio(campo) {
  if (!campo) return false
  if (!campo.trim()) return false
  return true
}

export function valoresUnicos(dado, indice, objeto) {
  return objeto.indexOf(dado) === indice
}

export const ajusta = (v) => {
  const digitos = !v ? '' : v.replace(/[^\d]/g, '')
  if (!digitos || digitos.length < 10) return v
  const corte = digitos.length === 10 ? 6 : 7
  const max = digitos.length > 11 ? 11 : digitos.length
  return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`
}

export const maskBuilder = (v) => {
  if (!v || v.length === 0) return ''
  const a = ajusta(v)
  return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999'
}

export function apenasLetras(valor) {
  const letters = /^[A-Za-z]+$/
  if (valor.match(letters)) {
    return true
  }
  return false
}

export function apenasNumeros(valor) {
  const numberPattern = /^[0-9]+$/
  if (!valor || valor.match(numberPattern)) {
    return true
  }
  return false
}

export function validarUf(valor) {
  return validarCampoObrigatorio(valor) && apenasLetras(valor) && valor.length === 2
}

export function validarNumero(valor) {
  return valor === '0' || apenasNumeros(valor)
}

export function validaCnpj(valor, setErro, erro) {
  const cnpjValido = validarCNPJ(valor)
  if (cnpjValido) {
    setErro({ ...erro, cnpj: false })
  } else {
    setErro({ ...erro, cnpj: true })
  }
}

export function validaEmail(valor, setErro, erro) {
  const emailValido = validarEmail(valor)
  if (emailValido) {
    setErro({ ...erro, email: false })
  } else {
    setErro({ ...erro, email: true })
  }
}

export function validaResponsavel(valor, setErro, erro) {
  const responsavelValido = validarCampoObrigatorio(valor)
  if (responsavelValido) {
    setErro({ ...erro, responsavel: false })
  } else {
    setErro({ ...erro, responsavel: true })
  }
}

export function validaTelefone(valor, setErro, erro) {
  const telefoneValido = validarCampoObrigatorio(valor)
  if (telefoneValido && telefoneEhValido(valor)) {
    setErro({ ...erro, telefone: false })
  } else {
    setErro({ ...erro, telefone: true })
  }
}

export function validaEmpresa(valor, setErro, erro) {
  const empresaValido = validarCampoObrigatorio(valor)
  if (empresaValido) {
    setErro({ ...erro, empresa: false })
  } else {
    setErro({ ...erro, empresa: true })
  }
}

export function validaLogradouro(valor, setErro, erro) {
  const logradouroValido = validarCampoObrigatorio(valor)
  if (logradouroValido) {
    setErro({ ...erro, logradouro: false })
  } else {
    setErro({ ...erro, logradouro: true })
  }
}

export function validaNumero(valor, setErro, erro) {
  if ((valor === '0') || !apenasNumeros(valor)) {
    setErro({ ...erro, numero: true })
  } else {
    setErro({ ...erro, numero: false })
  }
}

export function validaBairro(valor, setErro, erro) {
  const bairroValido = validarCampoObrigatorio(valor)
  if (bairroValido) {
    setErro({ ...erro, bairro: false })
  } else {
    setErro({ ...erro, bairro: true })
  }
}

export function validaCidade(valor, setErro, erro) {
  const cidadeValido = validarCampoObrigatorio(valor)
  if (cidadeValido) {
    setErro({ ...erro, cidade: false })
  } else {
    setErro({ ...erro, cidade: true })
  }
}

export function validaUf(valor, setErro, erro) {
  const ufValido = validarUf(valor)
  if (!ufValido) {
    setErro({ ...erro, uf: true })
  } else {
    setErro({ ...erro, uf: false })
  }
}

export function validaTodosCampos(estado, setErro, erro) {
  const responsavelValido = validarCampoObrigatorio(estado.responsavel)
  const emailValido = validarEmail(estado.email)
  const telefoneValido = validarCampoObrigatorio(estado.telefone)
  const empresaValido = validarCampoObrigatorio(estado.empresa)
  const cnpjValido = validarCNPJ(estado.cnpj)
  const cepValido = validarCep(estado.cep)
  const logradouroValido = validarCampoObrigatorio(estado.logradouro)
  const numeroValido = validarCampoObrigatorio(estado.bairro)
  const bairroValido = validarNumero(estado.numero)
  const cidadeValido = validarCampoObrigatorio(estado.cidade)
  const ufValido = validarUf(estado.uf)
  setErro({
    ...erro,
    responsavel: !responsavelValido,
    telefone: !telefoneValido,
    email: !emailValido,
    empresa: !empresaValido,
    cnpj: !cnpjValido,
    cep: !cepValido,
    logradouro: !logradouroValido,
    bairro: !numeroValido,
    numero: !bairroValido,
    cidade: !cidadeValido,
    uf: !ufValido,
  })
}

export function validaCamposEndereco(estado, setErro, erro) {
  const cepValido = validarCampoObrigatorio(estado.cep)
  const logradouroValido = validarCampoObrigatorio(estado.logradouro)
  const numeroValido = validarCampoObrigatorio(estado.bairro)
  const bairroValido = validarCampoObrigatorio(estado.numero)
  const cidadeValido = validarCampoObrigatorio(estado.cidade)
  const ufValido = validarCampoObrigatorio(estado.uf)
  setErro({
    ...erro,
    cep: !cepValido,
    logradouro: !logradouroValido,
    bairro: !numeroValido,
    numero: !bairroValido,
    cidade: !cidadeValido,
    uf: !ufValido,
  })
}

export function validarCPF(valor) {
  if (!valor) { return false }
  let Soma
  let Resto
  Soma = 0
  const strCPF = valor.replace(/[^\d]+/g, '')
  if (strCPF === '00000000000') return false
  if (strCPF === '11111111111') return false
  if (strCPF === '22222222222') return false
  if (strCPF === '33333333333') return false
  if (strCPF === '44444444444') return false
  if (strCPF === '55555555555') return false
  if (strCPF === '66666666666') return false
  if (strCPF === '77777777777') return false
  if (strCPF === '88888888888') return false
  if (strCPF === '99999999999') return false

  for (let i = 1; i <= 9; i += 1) {
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i)
  }

  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) {
    Resto = 0
  }

  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) {
    return false
  }

  Soma = 0
  for (let i = 1; i <= 10; i += 1) {
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i)
  }

  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) {
    Resto = 0
  }

  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) {
    return false
  }

  return true
}

export function validaCPF(valor, setErro, erro) {
  const cpfValido = validarCPF(valor)
  if (cpfValido) {
    setErro({ ...erro, cpf: false })
  } else {
    setErro({ ...erro, cpf: true })
  }
}

export function obterTokenCookie() {
  const cookie = new Cookies()
  return cookie.get(COOKIE_DADOS)
}

export function removerToken() {
  const cookie = new Cookies()
  cookie.remove(COOKIE_DADOS, { path: '/' })
}

export function obterInformacoesToken() {
  const cookie = obterTokenCookie()
  if (cookie) {
    try {
      const cookieDecodificado = jwtDecode(cookie)
      const {
        admin, nome, usuarioPF, usuarioPJ,
      } = cookieDecodificado
      return {
        nome, admin, usuarioPF, usuarioPJ,
      }
    } catch {
      removerToken()
      return ''
    }
  }
  return ''
}

export function verificarTokenExpirado() {
  if (obterInformacoesToken().nome
    && (obterInformacoesToken().admin === false || obterInformacoesToken().admin === true)) {
    return false
  }
  return true
}

export function inserirToken(dados) {
  const cookie = new Cookies()
  const expires = new Date()
  expires.setHours(expires.getHours() + 5)
  cookie.set(COOKIE_DADOS, jwtEncode({
    nome: dados.nome,
    admin: dados.usuarioAdmin,
    usuarioPJ: dados.paraSuaEmpresa,
    usuarioPF: dados.paraVoce,
  }, 'FIEMG'), { path: '/', expires, secure: true })
}

export function formatarDataTimezone(data) {
  function pad(number) {
    if (number < 10) {
      return `0${number}`
    }
    return number
  }

  if (data) {
    const dataFormatada = `${data.getFullYear()}-${pad(data.getMonth() + 1)}-${pad(data.getDate())}T${pad(data.getHours())}:${pad(data.getMinutes())}`
    return dataFormatada
  }
  return ''
}

export function formatarDataSemHora(data) {
  function pad(number) {
    if (number < 10) {
      return `0${number}`
    }
    return number
  }

  if (data) {
    const novaData = new Date(data)
    const dataFormatada = `${novaData.getFullYear()}-${pad(novaData.getMonth() + 1)}-${pad(novaData.getDate())}`
    return dataFormatada
  }
  return ''
}

export function formatarDataT(data) {
  function pad(number) {
    if (number < 10) {
      return `0${number}`
    }
    return number
  }

  if (data) {
    const dataFormatada = `${data.getFullYear()}-${pad(data.getMonth() + 1)}-${pad(data.getDate())}`
    return dataFormatada
  }
  return ''
}

export function formatarTimezone(data) {
  function pad(number) {
    if (number < 10) {
      return `0${number}`
    }
    return number
  }

  if (data) {
    const dataFormatada = `${pad(data.getHours())}:${pad(data.getMinutes())}`
    return dataFormatada
  }
  return ''
}

export function formatarData(date) {
  if (date) {
    const data = new Date(date)
    const ano = data.getFullYear()
    const dia = data.getDate()
    const mesFormatado = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][data.getMonth()]
    return `${dia} de ${mesFormatado}, ${ano}`
  }
  return ''
}

export function formatarDataFormatoPadrao(date) {
  if (date) {
    const data = new Date(date.replace(' ', 'T'))
    return data.toLocaleDateString('pt-BR')
  }
  return ''
}

export function formatarHoraMinuto(date) {
  if (date) {
    const data = new Date(date)
    const hora = data.getHours()
    const minutosF = (`${data.getMinutes()}`.length === 1) ? `0${data.getMinutes()}` : data.getMinutes()
    return `${hora}:${minutosF}`
  }
  return ''
}

export function dataFormatadaComponente(data) {
  if (data) {
    return formatarDataTimezone(new Date(data))
  }
  return ''
}

export function formatarDataHora(data, hora) {
  if (data && hora && hora[0] !== 'N') {
    return `${data}T${hora}`
  }
  return ''
}

export function dataAtual() {
  return formatarDataSemHora(new Date())
}

export function dataMaiorAtual(data) {
  const dataParam = new Date(data)
  if (data) {
    return dataParam > new Date()
  }
  return false
}

export function somarData(data, dias) {
  if (data && dias) {
    const novaData = new Date(data)
    novaData.setDate(novaData.getDate() + dias)
    return formatarDataSemHora(novaData)
  }
  return ''
}

export function formatarMoeda(valor) {
  return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export function agruparUnidadesPorCidade(unidades) {
  const novo = unidades.map((i) => i.cidade).filter(valoresUnicos)
  let retorno = novo.map((n) => ({ cidade: n, unidades: [] }))
  for (let a = 0; a < unidades.length; a += 1) {
    retorno.map((r) => (r.cidade === unidades[a].cidade ? (r.unidades.push(unidades[a])) : null))
  }
  retorno = ordenarArrayPorChave(retorno, 'cidade')
  return retorno
}

export function validarSenha(valor) {
  if (!valor) {
    return false
  }
  const regex = /^(?=.*[<>\-_=?:;+.,@!#$%^&*()/\\])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[<>-_=?:;+.,@!#$%^&*()/\\a-zA-Z0-9]{10,20}$/
  const senhaValida = regex.test(valor)
  if (senhaValida) {
    return true
  }
  return false
}

export function validarTamanhoSenha(valor) {
  const senhaValida = valor.length >= 10 && valor.length <= 20
  return !senhaValida
}

export function validarLetraMaiusculaSenha(valor) {
  const regex = /(?=.*[A-Z])/
  const senhaValida = regex.test(valor)
  return !senhaValida
}

export function validarLetraMinusculaSenha(valor) {
  const regex = /(?=.*[a-z])/
  const senhaValida = regex.test(valor)
  return !senhaValida
}

export function validarNumeroSenha(valor) {
  const regex = /(?=.*[0-9])/
  const senhaValida = regex.test(valor)
  return !senhaValida
}

export function validarCaracterEspecialSenha(valor) {
  const regex = /(?=.*[<>\-_=?:;+.,@!#$%^&*()/\\])/
  const senhaValida = regex.test(valor)
  return !senhaValida
}

export function validarErrosSenha(valor, setErro, erro) {
  if (!valor) {
    setErro({
      quantidade: true,
      maiuscula: true,
      minuscula: true,
      numero: true,
      especial: true,
    })
    return
  }

  const quantidade = validarTamanhoSenha(valor)
  const maiuscula = validarLetraMaiusculaSenha(valor)
  const minuscula = validarLetraMinusculaSenha(valor)
  const numero = validarNumeroSenha(valor)
  const especial = validarCaracterEspecialSenha(valor)

  setErro({
    ...erro,
    quantidade,
    maiuscula,
    minuscula,
    numero,
    especial,
  })
}

export function criptografarTexto(texto) {
  const privateKey = 'g$T$cGJI^qmK9uPyC@VFHCOZ0KrsjIRl'
  const salt = CryptoJS.lib.WordArray.random(128 / 8)

  const key = CryptoJS.PBKDF2(
    privateKey,
    salt,
    { keySize: 256 / 32, iterations: 1000, hasher: CryptoJS.algo.SHA512 },
  )

  const iv = CryptoJS.lib.WordArray.random(128 / 8)
  const cipherText = CryptoJS.AES.encrypt(texto, key, { iv })

  const encryptedData = salt.clone().concat(iv).concat(cipherText.ciphertext)
  const encryptedDataB64 = encryptedData.toString(CryptoJS.enc.Base64)
  return encryptedDataB64
}

export function validaURL(texto) {
  const termosUrl = ['https', 'http', 'www', '.com']
  return termosUrl.some((termo) => texto.includes(termo)) && !texto.includes('@')
}

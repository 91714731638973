const PtBr = {
  NAO_INFORMADO: 'Não informado',
  SOBRE_UNIDADE: 'SOBRE A UNIDADE',
  SOBRE_MOBILE: 'SOBRE',
  CURSOS: 'CURSOS PARA VOCÊ',
  CURSOS_MOBILE: 'CURSOS',
  SOLUCOES: 'SOLUÇÕES PARA SUA EMPRESA',
  SOLUCOES_MOBILE: 'SOLUÇÕES PJ',
  LIGAR: 'Ligar',
  MAPA: 'Ver no mapa',
  PLACEHOLDER_BUSCA_PF: 'Pesquise cursos nessa unidade',
  PLACEHOLDER_BUSCA_PJ: 'Pesquise soluções nessa unidade',
  PLACEHOLDER_PESQUISA: 'Pesquise por cidade ou unidade',
  IMAGEM_UNIDADE: 'Imagem da unidade',
  INFRAESTRUTURA: 'Infraestrutura',
  TITULO: 'FIEMG - Conheça Nossas Unidades',
  DESCRICAO: 'Conheça todas as unidades existentes da FIEMG, SESI, SENAIL, IEL, CIEMG e encontre a mais próxima de você',
  TITULO_UNIDADES: 'FIEMG - ',
}

export default PtBr

const PtBr = {
  CURSO_DISPONIVEL: 'Curso disponível ',
  INSCREVER_AQUI: 'Inscrever aqui',
  INSCRICAO: 'Fazer inscrição',
  O_QUE_VOCE: 'O que você vai ',
  APRENDER: 'aprender',
  VALOR: 'Valor:',
  VALOR_CURSO: 'Valor do curso',
  CIFRAO: 'R$',
  QUANDO_ABRIR: 'Quando abrir, me avisa',
  ME_AVISAR: 'Me avisar',
  CONFIRMAR_EMAIL: 'Confirmar Email',
  EMAIL_CADASTRADO: 'E-mail cadastrado',
  TEXTO_EMAIL: 'Quando esse curso estiver disponível aqui, iremos te avisar',
  HORAS: 'horas',
  PUBLICO_ALVO: 'Público-alvo',
  DETALHES: 'DETALHES DO CURSO',
  DETALHES_MOBILE: 'DETALHES',
  VALORES: 'Valores',
  REQUISITOS: 'Requisitos',
  SELECIONAR_TURMA: 'Selecionar Turma',
  A_PARTIR: 'A partir de ',
  NAO_INFORMADO: 'Não informado',
  SELECIONE_UMA_TURMA: 'Selecione uma das turmas abaixo para se inscrever',
  VALOR_TOTAL: 'Valor Total',
  OU: 'ou',
  DE: 'de ',
  TURNO: 'Turno',
  PREENCHA: 'Preencha seus dados',
  DADOS_ALUNO: 'DADOS DO ALUNO',
  CURSO: 'CURSO',
  DATA_INICIO: 'Data de Início: ',
  CARGA_HORARIA: 'Carga Horária: ',
  PARCELAS: 'Parcelas',
  TEXTO_ALERTA1: 'Ao clicar em continuar, você será redirecionado para o Portal de Inscrições SENAI MG.',
  TEXTO_ALERTA2: 'O curso acontecerá caso o número mínimo de vagas seja atingido. Caso contrário, não se preocupe, devolveremos seu dinheiro.',
  TEXTO_ALERTA3: 'A 1ª parcela do curso deverá ser paga por boleto no ato da inscrição. Para pagamento das outras parcelas você receberá acesso ao sistema para pagamento com cartão de crédito ou boleto.',
  TEXTO_INFO_AZUL: 'Ao realizar o cadastro, você poderá receber informações relacionadas a produtos, serviços e ações do SENAI MG.',
  ANTERIOR: 'Anterior',
  PROXIMO: 'Próximo',
  CONTINUAR: 'Continuar',
  CONTINUAR_INSCRICAO: 'Continuar Inscrição',
  CONTINUE_INSCRICAO: 'Continue sua Inscrição',
  FALTA_POUCO: 'Falta pouco para finalizar sua inscrição. Abrimos uma nova aba para você continuar a se inscrever no curso:',
  MAS_VOCE: 'Mas você também pode clicar abaixo e será redirecionado para o Portal de Inscrições SENAI MG.',
  BOTAO_REABRIR: 'Abrir página de inscrição',
  TITLE_PAGINA: 'FIEMG - ',
  TITLE_PAGINA_INSCRICAO: 'FIEMG - Faça sua Inscrição',
  TITLE_PAGINA_INSCRICAO_CONLCUIDA: 'FIEMG - Inscrição Realizada Com Sucesso!',
  DESCRICAO_PAGINA_INSCRICAO: 'Faça sua inscrição nas soluções SENAI, SESI, IEL e CIEMG',
  AH_TAMBEM: 'Ah! Também enviamos o link de acesso para o Portal no seu e-mail.',
  SENTIMOS_MUITO: 'Sentimos muito. Este curso está com inscrições encerradas. Mas podemos te avisar assim que forem reabertas, basta selecionar uma turma.',
  TURMAS: 'TURMAS',
  INSCRICOES_ENCERRADAS: 'Inscrições Encerradas',
  SENTIMOS_MUITO_TURMAS: 'Sentimos muito. Este curso está com inscrições encerradas. Mas podemos te avisar assim que forem reabertas.',
  QUERO_SER_AVISADO: 'Quero ser avisado',
  SE_INTERESSOU: 'Se interessou?',
  PODEMOS_AVISAR: 'Podemos te avisar',
  PARCELAS_DISPONIVEIS: 'Parcelas disponíveis',
  VOCE_RECEBERA: 'Você receberá um e-mail do {{entidade}} quando este curso for reaberto.',
  CONCLUIR: 'Concluir',
  QUANDO_ESTIVER_DISPONIVEL: 'Quando esse curso estiver disponível, iremos te avisar',
  EMAIL_CONFIRMADO: 'Email confirmado',
  EMAIL_CADASTRADO_SUCESSO: 'Email cadastrado com sucesso!',
}

export default PtBr

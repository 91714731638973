const PtBr = {
  DESTAQUES: 'destaques',
  DESTAQUE: 'Destaque',
  SOLUCAO_DESTAQUE: 'SOLUÇÃO',
  CATEGORIA: 'CATEGORIA',
  EDITAR_DESTAQUES: 'Editar Destaques',
  INFO_DESTAQUES_ORDEM: 'Os destaques estão na ordem como vão aparecer na página.',
  SALVAR_ALTERACOES: 'Salvar Alterações',
  SOLUCAO: 'Solução',
  CATEGORIA_MIN: 'Categoria',
  INSIRA_SOLUCAO: 'Insira uma solução',
  INSIRA_CATEGORIA: 'Insira uma categoria',
  SUCESSO_SALVAR_DESTAQUES: 'Destaques salvos com sucesso!',
  DESEJA_SAIR_SEM_SALVAR: 'Deseja sair sem salvar alterações?',
  NAO_SALVAR: 'Não salvar',
  SALVAR_ALTERACOESS: 'Salvar alterações',
  TITULO_PAGINA: 'FIEMG - Backoffice de Destaque',
  DESCRICAO_PAGINA: 'Altere os destaques exibidos na tela de para sua empresa',
  ALT_IMG_DESTAQUES: 'Imagem representativa das soluções em destaque do SESI, SENAI, IEL, FIEMG.',
}

export default PtBr

import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Analytics from 'react-router-ga'
import { obterInformacoesToken } from '../utils/Funcoes'
import useWidthResize from '../utils/useWidthResize'
import SolucoesPessoaFisicaCotacaoSucesso from '../solucoesPessoaFisica/views/SolucoesPessoaFisicaCotacaoSucesso'

const Unidades = lazy(() => import('../unidades'))
const UnidadesDetalhes = lazy(() => import('../unidades/views/UnidadesDetalhes'))
const Home = lazy(() => import('../landingPage'))
const Empresa = lazy(() => import('../paraSuaEmpresa'))
const Voce = lazy(() => import('../paraVoce'))
const SolucoesCategoria = lazy(() => import('../solucoesCategoria'))
const Solucoes = lazy(() => import('../solucao'))
const Cotacao = lazy(() => import('../solucao/views/SolucaoCotacao'))
const FaleConosco = lazy(() => import('../faleConosco'))
const Sucesso = lazy(() => import('../solucao/views/SolucaoCotacaoSucesso'))
const Solicitacoes = lazy(() => import('../solicitacoes/views/SolicitacoesInicio'))
const DestaquesBackoffice = lazy(() => import('../destaques'))
const Erro = lazy(() => import('../utils/views/Erro'))
const ResetSenha = lazy(() => import('../redefinicaoSenha/views/RedefinicaoSenhaReset'))
const DadosCadastro = lazy(() => import('../dadosCadastro'))
const ConfirmacaoEmail = lazy(() => import('../cadastroUsuarios/views/CadastroUsuarioConfirmacaoEmail'))
const ParaVoceEducacaoProfissional = lazy(() => import('../paraVoce/views/ParaVoceEducacaoProfissional'))
const SolucoesPessoaFisica = lazy(() => import('../solucoesPessoaFisica'))
const CotacaoPessoaFisica = lazy(() => import('../solucoesPessoaFisica/views/SolucoesPessoaFisicaCotacao'))
const MeusCursos = lazy(() => import('../meusCursos/views/MeusCursosInicio'))
const PainelAdministrativo = lazy(() => import('../painelAdm'))
const DestaquesPFBackoffice = lazy(() => import('../destaquesPessoaFisica'))
const CadastroSolucoes = lazy(() => import('../cadastroSolucoes'))
const CadastroSolucoesInserirEditar = lazy(() => import('../cadastroSolucoes/views/CadastroSolucoesInserirEditar'))

const Routes = () => {
  const { t } = useTranslation()
  const telaGrande = useWidthResize()

  return (
    <BrowserRouter>
      <Analytics id={process.env.REACT_APP_ANALYTICS}>
        <Suspense fallback={<div />}>
          <Switch>
            <Route exact path={`/${t('geral.routes.URL_HOME')}`} render={() => <Home />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_SUA_EMPRESA')}`} render={() => <Empresa />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_SUA_EMPRESA_SOLUCOES_CATEGORIA')}/:slug`} render={() => <SolucoesCategoria />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_SUA_EMPRESA_SOLUCAO_COTACAO')}/:slugSolucao/:slugCidade?/:slugUnidade?`} render={() => <Cotacao />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_SUA_EMPRESA_SOLUCAO')}/:slugSolucao`} render={() => <Solucoes />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_SUA_EMPRESA_SUCESSO')}/:slugSolucao/:slugCidade?/:slugUnidade?`} render={() => <Sucesso />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_VOCE')}`} render={() => <Voce />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_VOCE_SOLUCOES_PESSOA_FISICA')}/:slugSolucao/:slugCidade?/:slugUnidade?`} render={() => <SolucoesPessoaFisica />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_VOCE_COTACAO')}/:slugSolucao/:slugCidade?/:slugUnidade?`} render={() => <CotacaoPessoaFisica />} />
            <Route exact path={`/${t('geral.routes.URL_PARA_VOCE_SUCESSO')}/:slugSolucao/:slugCidade?/:slugUnidade?`} render={() => <SolucoesPessoaFisicaCotacaoSucesso />} />
            <Route exact path={`/${t('geral.routes.URL_SOLICITACOES')}`} render={() => <Solicitacoes />} />
            <Route exact path={`/${t('geral.routes.URL_FALE_CONOSCO')}`} render={() => <FaleConosco />} />
            <Route exact path={`/${t('geral.routes.URL_RESET_SENHA')}/:hash`} render={() => <ResetSenha />} />
            <Route exact path={`/${t('geral.routes.URL_DADOS_CADASTRO')}`} render={() => <DadosCadastro />} />
            <Route exact path={`/${t('geral.routes.URL_VALIDAR_EMAIL')}/:hash`} render={() => <ConfirmacaoEmail />} />
            <Route exact path={`/${t('geral.routes.URL_UNIDADES')}`} render={() => <Unidades />} />
            <Route exact path={`/${t('geral.routes.URL_UNIDADES')}/:slugUnidade`} render={() => <UnidadesDetalhes />} />
            <Route exact path={`/${t('geral.routes.URL_EDUCACAO_PROFISSIONAL')}`} render={() => <ParaVoceEducacaoProfissional />} />
            <Route exact path={`/${t('geral.routes.URL_MEUS_CURSOS')}`} render={() => <MeusCursos />} />
            {obterInformacoesToken().admin && telaGrande ? <Route exact path={`/${t('geral.routes.URL_BACKOFFICE_DESTAQUES')}`} render={() => <DestaquesBackoffice />} /> : null}
            {obterInformacoesToken().admin && telaGrande ? <Route exact path={`/${t('geral.routes.URL_BACKOFFICE_DESTAQUES_PF')}`} render={() => <DestaquesPFBackoffice />} /> : null}
            {obterInformacoesToken().admin && telaGrande ? <Route exact path={`/${t('geral.routes.URL_PAINEL_ADMINISTRATIVO')}`} render={() => <PainelAdministrativo />} /> : null}
            {obterInformacoesToken().admin && telaGrande ? <Route exact path={`/${t('geral.routes.URL_CADASTRO_SOLUCOES')}`} render={() => <CadastroSolucoes />} /> : null}
            {obterInformacoesToken().admin && telaGrande ? <Route exact path={`/${t('geral.routes.URL_CADASTRO_SOLUCOES_NOVA')}`} render={() => <CadastroSolucoesInserirEditar />} /> : null}
            {obterInformacoesToken().admin && telaGrande ? <Route exact path={`/${t('geral.routes.URL_CADASTRO_SOLUCOES_NOVA')}/:slugSolucao`} render={() => <CadastroSolucoesInserirEditar />} /> : null}
            <Route path="*" render={() => <Erro paginaInvalida />} />
          </Switch>
        </Suspense>
      </Analytics>
    </BrowserRouter>
  )
}

export default Routes

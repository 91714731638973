const PtBr = {
  OLA: 'Olá!',
  CONTA_FIEMG: 'Entre com sua conta FIEMG.',
  ENTRAR: 'Entrar',
  ESQUECEU_SUA: 'Esqueceu sua ',
  SENHA_PERGUNTA: 'senha?',
  ESQUECEU_SENHA_PERGUNTA: 'Esqueceu sua senha?',
  NAO_POSSUI_CADASTRO: 'Não possui cadastro?',
  FAZER_CADASTRO: 'Fazer Cadastro',
  OLA_USUARIO_LOGADO: 'Olá, ',
}

export default PtBr

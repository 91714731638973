/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MetaTags from 'react-meta-tags'
import propTypes from 'prop-types'

const MetaTagsComponente = ({
  titulo, descricao, jsonLd,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const dadosEstruturados = document.getElementsByClassName('dadosEstruturados')
    for (const script of dadosEstruturados) {
      script.remove()
    }
  }, [])

  return (
    <MetaTags>
      <title>{titulo || t('utils.FIEMG')}</title>
      <meta
        name="description"
        content={descricao || t('utils.FIEMG_DESCRICAO')}
      />
      {jsonLd && jsonLd.length > 0 && (
        jsonLd.map((dado) => (
          <script
            key={JSON.stringify(dado)}
            className="dadosEstruturados"
            type="application/ld+json"
            // dangerouslySetInnerHTML={{ __html: JSON.stringify(dado) }}
          />
        ))
      )}
    </MetaTags>
  )
}

MetaTagsComponente.propTypes = {
  titulo: propTypes.string,
  descricao: propTypes.string,
  jsonLd: propTypes.arrayOf(propTypes.any),
}
MetaTagsComponente.defaultProps = {
  titulo: undefined,
  descricao: undefined,
  jsonLd: undefined,
}

export default MetaTagsComponente

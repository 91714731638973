import React from 'react'

const UsuarioLoginSVG = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0519 17.5V15.8333C17.0519 13.9924 15.5927 12.5 13.7927 12.5H7.27415C5.47411 12.5 4.01489 13.9924 4.01489 15.8333V17.5" stroke="#5CB3E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5334 9.16667C12.3335 9.16667 13.7927 7.67428 13.7927 5.83333C13.7927 3.99238 12.3335 2.5 10.5334 2.5C8.73339 2.5 7.27417 3.99238 7.27417 5.83333C7.27417 7.67428 8.73339 9.16667 10.5334 9.16667Z" stroke="#5CB3E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default UsuarioLoginSVG

import React from 'react'

const InstagramSVG = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>A3C5B640-E391-4B36-B1D1-E18DB33DA60E</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.996078 0 0 0 0 0.996078 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g id="Precisa-de-Ajuda?-+-Footer" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Ajuda-1-(1)" transform="translate(-555.000000, -903.000000)">
        <g id="Navigation/-Footer-WEB" transform="translate(176.000000, 784.000000)">
          <g id="instagram-logo" transform="translate(375.000000, 115.000000)" filter="url(#filter-1)">
            <g transform="translate(4.000000, 4.000000)">
              <path d="M23.1689676,0 L8.83065384,0 C3.96143427,0 0,3.96162355 0,8.83084312 L0,23.1691569 C0,28.0385657 3.96143427,32 8.83065384,32 L23.1689676,32 C28.0385657,32 32,28.0383765 32,23.1691569 L32,8.83084312 C32,3.96162355 28.0385657,0 23.1689676,0 Z M29.1609942,23.1691569 C29.1609942,26.4730336 26.4730336,29.1608049 23.1691569,29.1608049 L8.83065384,29.1608049 C5.52696644,29.1608049 2.83919509,26.4730336 2.83919509,23.1691569 L2.83919509,8.83084312 C2.83919509,5.52715572 5.52696644,2.83919509 8.83065384,2.83919509 L23.1689676,2.83919509 C26.4728443,2.83919509 29.1609942,5.52715572 29.1609942,8.83084312 L29.1609942,23.1691569 L29.1609942,23.1691569 Z" id="Shape" fill="#000000" fillRule="nonzero" />
              <path d="M16,8 C11.5887336,8 8,11.5888159 8,16.0001836 C8,20.4113677 11.5887336,24 16,24 C20.4112664,24 24,20.4113677 24,16.0001836 C24,11.5888159 20.4112664,8 16,8 Z M16,21.2451219 C13.1078209,21.2451219 10.7546312,18.8922455 10.7546312,16 C10.7546312,13.1075708 13.1076372,10.7545108 16,10.7545108 C18.8923628,10.7545108 21.2453688,13.1075708 21.2453688,16 C21.2453688,18.8922455 18.8921791,21.2451219 16,21.2451219 Z" id="Shape" fill="#000000" fillRule="nonzero" />
              <path d="M24.4444444,5.33333333 C23.8606061,5.33333333 23.2870707,5.56969697 22.8747475,5.98383838 C22.460404,6.3959596 22.2222222,6.96969697 22.2222222,7.55555556 C22.2222222,8.13959596 22.4606061,8.71313131 22.8747475,9.12727273 C23.2868687,9.53939394 23.8606061,9.77777778 24.4444444,9.77777778 C25.030303,9.77777778 25.6020202,9.53939394 26.0161616,9.12727273 C26.430303,8.71313131 26.6666667,8.13939394 26.6666667,7.55555556 C26.6666667,6.96969697 26.430303,6.3959596 26.0161616,5.98383838 C25.6040404,5.56969697 25.030303,5.33333333 24.4444444,5.33333333 Z" id="Path" fill="#000000" fillRule="nonzero" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default InstagramSVG

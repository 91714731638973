import React from 'react'

const HamburguerSVG = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="white" />
    <path d="M9.25 16H22.75" stroke="#2D2F32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.25 10H22.75" stroke="#2D2F32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.25 22H22.75" stroke="#2D2F32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default HamburguerSVG

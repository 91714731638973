const PtBr = {
  NENHUMA_INSCRICAO: 'Nenhuma inscrição aqui',
  QUANDO_SE_INSCREVER: 'Quando você se inscrever em cursos FIEMG, eles vão aparecer aqui',
  MEUS_CURSOS: 'Meus Cursos',
  TEXTO: 'Aqui estão todas as inscrições de cursos feitas no portal PARA VOCÊ.',
  INSCRITO_EM: 'Inscrito em ',
  TXT_INSCRICAO_FINALIZADA: 'Inscrição finalizada com sucesso. Obrigado pela confiança e bom aprendizado!',
  TXT_INSCRICAO_NAO_FINALIZADA: 'Inscrição não finalizada. Clique aqui para ser redirecionado para o Portal de Inscrição.',
  TITLE_PAGINA: 'FIEMG - Meus Cursos',
  DESCRICAO_PAGINA: 'Inscrições em cursos FIEMG realizadas pelo usuário',
}

export default PtBr

import utils from '../utils/locales/PtBr'
import paginas from '../paginas/locales/PtBr'
import landingPage from '../landingPage/locales/PtBr'
import paraSuaEmpresa from '../paraSuaEmpresa/locales/PtBr'
import paraVoce from '../paraVoce/locales/PtBr'
import solucoesCategoria from '../solucoesCategoria/locales/PtBr'
import solucao from '../solucao/locales/PtBr'
import filtro from '../filtro/locales/PtBr'
import contatos from '../contatos/locales/PtBr'
import cadastroUsuarios from '../cadastroUsuarios/locales/PtBr'
import login from '../login/locales/PtBr'
import solicitacoes from '../solicitacoes/locales/PtBr'
import destaques from '../destaques/locales/PtBr'
import destaquesPessoaFisica from '../destaquesPessoaFisica/locales/PtBr'
import redefinicaoSenha from '../redefinicaoSenha/locales/PtBr'
import solucoesPessoaFisica from '../solucoesPessoaFisica/locales/PtBr'
import dadosCadastro from '../dadosCadastro/locales/PtBr'
import unidades from '../unidades/locales/PtBr'
import unidadesBackoffice from '../unidadesBackoffice/locales/PtBr'
import meusCursos from '../meusCursos/locales/PtBr'
import painelAdministrativo from '../painelAdm/locales/PtBr'
import cookieBanner from '../cookieBanner/locales/PtBr'
import cadastroSolucoes from '../cadastroSolucoes/locales/PtBr'

const PtBr = {
  translation: {
    utils,
    paginas,
    landingPage,
    paraSuaEmpresa,
    paraVoce,
    solucoesCategoria,
    solucao,
    filtro,
    contatos,
    cadastroUsuarios,
    login,
    solicitacoes,
    destaques,
    destaquesPessoaFisica,
    redefinicaoSenha,
    solucoesPessoaFisica,
    dadosCadastro,
    unidades,
    unidadesBackoffice,
    meusCursos,
    painelAdministrativo,
    cookieBanner,
    cadastroSolucoes,
    geral: {
      routes: {
        URL_HOME: '',
        URL_PARA_SUA_EMPRESA: 'paraSuaEmpresa',
        URL_PARA_VOCE: 'paraVoce',
        URL_PARA_VOCE_COTACAO: 'paraVoce/solucao/cotacao',
        URL_PARA_VOCE_SUCESSO: 'paraVoce/sucesso',
        URL_PARA_VOCE_SOLUCOES_PESSOA_FISICA: 'paraVoce/solucao',
        URL_PARA_SUA_EMPRESA_SOLUCOES_CATEGORIA: 'paraSuaEmpresa/categoria',
        URL_PARA_SUA_EMPRESA_SOLUCAO: 'paraSuaEmpresa/solucao',
        URL_PARA_SUA_EMPRESA_SOLUCAO_COTACAO: 'paraSuaEmpresa/solucao/cotacao',
        URL_PARA_SUA_EMPRESA_SUCESSO: 'paraSuaEmpresa/sucesso',
        URL_NOTICIA: 'paraSuaEmpresa/noticias',
        URL_NOTICIA_DETALHE: 'noticia/detalhe',
        URL_BACKOFFICE_NOTICIA: 'backoffice/noticia',
        URL_BACKOFFICE_BANNER: 'backoffice/banner',
        URL_SOLICITACOES: 'solicitacoes',
        URL_FALE_CONOSCO: 'faleConosco',
        URL_BACKOFFICE_DESTAQUES: 'backoffice/destaques',
        URL_BACKOFFICE_DESTAQUES_PF: 'backoffice/destaquesPessoaFisica',
        URL_RESET_SENHA: 'redefinirSenha',
        URL_EDUCACAO_PROFISSIONAL: 'paraVoce/educacao-profissional',
        URL_DADOS_CADASTRO: 'minhaConta',
        URL_UNIDADES: 'unidades',
        URL_UNIDADES_BACKOFFICE: 'backoffice/unidades',
        URL_VALIDAR_EMAIL: 'validarEmail',
        URL_MEUS_CURSOS: 'meusCursos',
        URL_PAINEL_ADMINISTRATIVO: 'painelAdministrativo',
        URL_CADASTRO_SOLUCOES: 'cadastroSolucoes',
        URL_CADASTRO_SOLUCOES_NOVA: 'cadastroSolucoes/inserir',
      },
    },
  },
}

export default PtBr

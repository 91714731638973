import { Subject } from 'rxjs'
import { dispararErro } from './Funcoes'

export const subject = new Subject()
export const inscritos = []

export function inscrever(receberMensagem, id) {
  if (!id) {
    dispararErro('É necessário informar um identificador para a inscrição!')
  }
  if (!inscritos.includes(id)) {
    inscritos.push(id)
    subject.subscribe({ next: receberMensagem })
  }
}

export function exibirAlerta(mensagem) {
  subject.next(mensagem)
}

const FilaDeMensagens = { inscrever, exibirAlerta }

export default FilaDeMensagens

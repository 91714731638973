const PtBr = {
  PARA: 'para ',
  VOCE: 'Você',
  NIVEIS_CAPACITACAO: 'níveis de capacitação',
  EDUCACAO_PROFISSIONAL: 'Educação Profissional',
  VALOR_MEDIO: 'valor médio ',
  DISPONIVEL_EM: 'Disponível em ',
  UNIDADES: ' unidades',
  CURSO_INDISPONIVEL: 'Curso Indisponível',
  ME_AVISAR: 'Me avisar',
  PLACEHOLDER_BUSCA: 'Pesquise um curso desse nível',
  ORDENAR_POR: 'Ordenar por',
  SELECIONE: 'Selecione',
  PRECO: 'Preço',
  CARGA_HORARIA: 'Carga Horária',
  VER_RESULTADOS: 'Ver Resultados',
  PESQUISE_O_QUE_DESEJA_APRENDER: 'Pesquise o que deseja aprender',
  FILTROS: 'Filtros',
  FILTRO: 'Filtro',
  LIMPAR_FILTRO: 'Limpar Filtro',
  AREAS_CONHECIMENTO: 'Áreas de conhecimento',
  TIPO_CURSO: 'Tipos de curso',
  VALORES: 'Valores',
  AREA: 'Área',
  HORAS: 'Horas',
  REAIS: 'R$',
  LIMPAR: 'Limpar',
  CIDADE: 'Cidade',
  LOCALIZACAO: 'Localização',
  DE: ' de',
  DE_ESPACO: ' de ',
  TURMAS: ' Turmas',
  INSCRICOES_ATE: 'Inscrições até',
  DATA_INICIO: 'Data de início',
  FIM_CURSO: 'Fim do curso',
  ATE: 'até ',
  VALOR_TOTAL: 'valor total: ',
  TURNO: 'Turno',
  TITLE_PAGINA: 'FIEMG - Para Você',
  DESCRICAO_PAGINA: 'Encontre soluções, cursos e treinamentos do SENAI, SESI, IEL e CIEMG para você, pessoa física.',
  TITLE_EDUCACAO_PROFISSIONAL: 'FIEMG - Educação Profissional',
  SOMENTE: 'somente ',
  NAO_INFORMADO: 'Endereço não informado',
  OFERTADO_EM: '{{ofertas}} turmas',
  CONFIRA: 'confira as opções de ',
  PARCELAMENTO: 'parcelamento',
  INSCRICOES_ENCERRADAS: 'Inscrições Encerradas',
  TXT_INDISPONIVEL: 'No momento este curso está indisponível. Clique aqui para saber mais sobre o curso e te avisamos durante o período de matrícula!',
  APENAS_DISPONIVEIS: 'Apenas cursos disponíveis',
  ESTAMOS: 'Estamos construindo esse',
  ESPACO: 'espaço ',
  PARA_VOCE: 'para você!',
  CADASTRE: 'Cadastre seu e-mail e avisamos assim',
  ESTIVER_PRONTO: 'que estiver pronto.',
  QUER_AJUDAR: 'Quer nos ajudar? É bem rápido!',
  ACESSE_LINK: 'Acesse o link abaixo e deixe sua visão de',
  COMO_PODE: 'como pode ser o portal.',
  CADASTRAR: 'Cadastrar',
  INSIRA_EMAIL: 'Insira seu e-mail aqui',
  ACESSE_PESQUISA: 'Acesse nossa Pesquisa',
  EMAIL_INVALIDO: 'O e-mail informado é inválido.',
  EMAIL_CADASTRADO: 'E-mail cadastrado com sucesso.',
  EMAIL_NAO: 'E-mail não foi informado',
  SEU_CUSO_SENAI: 'Seu curso está no SENAI.',
  MATRICULAS_ABERTAS: 'Matrículas abertas para cursos técnicos presenciais e semipresenciais. Inscreva-se.',
  ALT_IMAGEM_CARD_NIVEL: 'Imagem descritiva dos cursos da FIEMG e SENAI referente ao nível de capacitação ',
  TURMA: ' Turma',
  SENTIMOS_MUITO: 'Sentimos muito. Este curso está com as inscrições encerradas. Clique para receber um aviso assim que forem reabertas.',
  CLIQUE_PARA_SABER: 'Clique para saber mais sobre a disponibilidade e data de início de cada turma deste curso!',
  CATEGORIA: 'Categoria',
  EDUCACAO_PROFISSIONAL_TXT: 'Os melhores cursos para garantir seu emprego na indústria.',
  EDUCACAO_PROFISSIONAL_DESCRICAO: 'Aqui você encontrará cursos que te darão suporte para iniciar numa nova carreira profissional, aperfeiçoar suas habilidades ou quem sabé até uma mudança de área. Não perca tempo e abra as portas para o mercado de trabalho.',
  VOLTAR: 'Voltar',
  OK: 'OK',
}

export default PtBr

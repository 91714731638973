import React from 'react'
import PropTypes from 'prop-types'

const UnidadeSVG = ({ cor, altura, largura }) => (
  <svg width={largura} height={altura} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33341 0H16.6667C17.5872 0 18.3334 0.746192 18.3334 1.66667V17.5C18.3334 18.4205 17.5872 19.1667 16.6667 19.1667H3.33341C2.41294 19.1667 1.66675 18.4205 1.66675 17.5V1.66667C1.66675 0.746192 2.41294 0 3.33341 0ZM16.6667 1.66667H3.33341V17.5H16.6667V1.66667Z" fill={cor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.50008 13.3333H12.5001C12.9603 13.3333 13.3334 13.7063 13.3334 14.1666V18.3333C13.3334 18.7935 12.9603 19.1666 12.5001 19.1666H7.50008C7.03984 19.1666 6.66675 18.7935 6.66675 18.3333V14.1666C6.66675 13.7063 7.03984 13.3333 7.50008 13.3333ZM11.6667 17.4999V14.9999H8.33341V17.4999H11.6667Z" fill={cor} />
    <rect x="5" y="8.33325" width="4.16667" height="3.33333" rx="1" fill={cor} />
    <rect x="5" y="3.33325" width="4.16667" height="3.33333" rx="1" fill={cor} />
    <rect x="10.8333" y="8.33325" width="4.16667" height="3.33333" rx="1" fill={cor} />
    <rect x="10.8333" y="3.33325" width="4.16667" height="3.33333" rx="1" fill={cor} />
  </svg>
)

UnidadeSVG.propTypes = {
  cor: PropTypes.string,
  altura: PropTypes.number,
  largura: PropTypes.number,
}

UnidadeSVG.defaultProps = {
  cor: undefined,
  altura: 20,
  largura: 20,
}

export default UnidadeSVG
